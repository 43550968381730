import { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import "./Modals.css"
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import Cookies from 'js-cookie'
import { loadingContext, messageContext } from '../../App';

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function ProjectModal(props) {

    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const today = new Date();
    const date = String(today.getFullYear()) + "-" + String(today.getMonth() + 1).padStart(2, '0')

    const [newProject, setNewProject] = useState({
        'Name': '',
        'University_Company': '',
        'from': '',
        'to': '',
        'is_active': false,
        'information': [''],
        'certificate': 'None',
    })
    const handleFromOnChange = (event) => {
        setNewProject({ ...newProject, 'from': event.target.value })
    }
    const handleToOnChange = (event) => {
        newProject.is_active ? setNewProject({ ...newProject, 'to': '' }) : setNewProject({ ...newProject, 'to': event.target.value })
    }
    const handleActiveOnChange = (event) => {
        if (event.target.checked) {
            setNewProject({ ...newProject, 'is_active': true, 'to': '' })
        } else {
            setNewProject({ ...newProject, 'is_active': false })
        }
    }

    const addNewProject = () => {
        loadingHandler.setIsLoading(true)
        if (newProject.Name.length > 250 || newProject.University_Company.length > 250 || newProject.information[0].length > 500 || newProject.Name.length < 1 || newProject.University_Company.length < 1 || newProject.from.length < 1 || (newProject.to !== '' && newProject.from > newProject.to)) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter valid input"
            })
            return;
        }
        if (newProject.information[0].length < 80) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter at least 80 characters of Responsibilities."
            })
            return;
        }
        props.setOpen(false)
        let projectData = new FormData()
        let d = JSON.stringify({ 'edit_type': 'add_project', 'projects_data': [...props.projects, newProject] })
        projectData.append('data', d)
        axios.post(`${ baseUrl }/candidate/update_and_load_resume/`,
            projectData,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                withCredentials: true
            }
        ).then(r => {
            props.setProjects([...props.projects, newProject])
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "success",
                displayText: "Your Project Added"
            })
            setNewProject({
                'Name': '',
                'University_Company': '',
                'from': '',
                'to': '',
                'is_active': false,
                'information': [''],
                'certificate': 'None',
            })
        }).catch(e => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Internal Server Error"
            })
        })
    }


    return (
        <div>
            <Modal show={props.open.projectModalOpen} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                <Modal.Body bsPrefix="modal-body profile-modal-content">
                    <button onClick={() => { props.setOpen({ ...props.open, 'projectModalOpen': false }) }} className="edit-modal-close"><i className="fas fa-times"></i></button>
                    <h4 className="text-center fs-18 fw-500 mt-5 mb-5 text-navy-blue">Add Project</h4>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="project-modal-title">Project Title</label>
                                <input value={newProject.Name} onChange={(event) => { setNewProject({ ...newProject, 'Name': event.target.value }) }} className="edit-modal-input-box" id="project-modal-title" type="text" placeholder="Covid Tracker" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="project-modal-company">Company/University</label>
                                <input value={newProject.University_Company} onChange={(event) => { setNewProject({ ...newProject, 'University_Company': event.target.value }) }} className="edit-modal-input-box" id="project-modal-company" type="text" placeholder="Apli" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="project-modal-issue-date">Start Date</label>
                                <input value={newProject.from} onChange={handleFromOnChange} max={date} className="edit-modal-input-box" id="project-modal-issue-date" type="month" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="project-modal-expire-date">End Date</label>
                                <input value={newProject.to} disabled={newProject.is_active} onChange={handleToOnChange} min={newProject.from} max={date} className="edit-modal-input-box mb-3" id="project-modal-expire-date" type="month" />
                                <Form.Check value={newProject.is_active} onChange={handleActiveOnChange} bsPrefix="profile-add-modal-check"
                                    type="switch"
                                    id="custom-switch"
                                    label="This Project is Not Expired"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="project-modal-responsibilities">Responsibilities</label>
                                <textarea value={newProject.information} onChange={(event) => { setNewProject({ ...newProject, 'information': [event.target.value] }) }} className="edit-modal-input-box" id="project-modal-responsibilities" rows="4" cols="10" placeholder="Describe about the project" />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center p-4">
                        <button onClick={addNewProject} className="apli-btn modal-save-button">Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProjectModal