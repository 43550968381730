import { React, useState } from 'react';
import { Modal, Accordion } from 'react-bootstrap';
import { TextField, Autocomplete } from '@mui/material';
import '../Opportunities.css';
import "./FilterModal.css"

function FilterModal(props) {
	const [tempAppliedFilters, setTempAppliedFilters] = useState({ ...props.appliedFilters })
	
	const handleFilters = (e, value, filterType, action) => {
		let currentFilters = tempAppliedFilters[filterType]
		if (value && value.length>0 && action === "add" && !currentFilters.includes(value)) {
			currentFilters.push(value)
		} else if(action === "remove") {
			let index = currentFilters.indexOf(value)
			currentFilters.splice(index, 1)
		}
		setTempAppliedFilters({ ...tempAppliedFilters })
	}

	const applyFilters = () => {
		props.setAppliedFilters({ ...tempAppliedFilters })
		props.setOpen(false)
	}

	return (
		<div>
			<Modal
				show={props.open}
				onHide={() => props.setOpen(false)}
				backdrop="static"
				keyboard={false}>

				<h5 className="text-center p-4 fw-600 fs-18 text-navy-blue">All Filters</h5>
				<button onClick={() => props.setOpen(false)} className="close-button-custom"><i className="fas fa-times"></i></button>

				<div className="text-navy-blue filter-modal-body">
					<Accordion>
						<Accordion.Item eventKey="0" style={{ "border": "none" }}>
							<Accordion.Header>Job Type</Accordion.Header>
							<Accordion.Body className="accordion-container-custom">
								{
									tempAppliedFilters['Job Type'] && tempAppliedFilters['Job Type'].includes('Job') ?
										<div className="selected-button-custom bg-navy-blue fw-500 fs-12">
											<button onClick={(e) => handleFilters(e, 'Job', "Job Type", "remove")} className="border-0 button-close-custom"><i className="fas fa-times"></i></button>
											Full Time
										</div>
										:
										<button
											className="fw-500 fs-12 unselected-button-custom text-navy-blue"
											onClick={(e) => handleFilters(e, 'Job', "Job Type", "add")}
											variant="contained"
										>
											Full Time
										</button>

								}
								{
									tempAppliedFilters['Job Type'] && tempAppliedFilters['Job Type'].includes('Internship') ?
										<div className="selected-button-custom bg-navy-blue fw-500 fs-12">
											<button onClick={(e) => handleFilters(e, 'Internship', "Job Type", "remove")} className="border-0 button-close-custom"><i className="fas fa-times"></i></button>
											Internship
										</div>
										:
										<button
											className="fw-500 fs-12 unselected-button-custom text-navy-blue"
											onClick={(e) => handleFilters(e, 'Internship', "Job Type", "add")}
											variant="contained"
										>
											Internship
										</button>

								}

								{
									tempAppliedFilters['Job Type'] && tempAppliedFilters['Job Type'].includes('Part-time') ?
										<div className="selected-button-custom bg-navy-blue fw-500 fs-12">
											<button onClick={(e) => handleFilters(e, 'Part-time', "Job Type", "remove")} className="border-0 button-close-custom"><i className="fas fa-times"></i></button>
											Part-time
										</div>
										:
										<button
											className="fw-500 fs-12 unselected-button-custom text-navy-blue"
											onClick={(e) => handleFilters(e, 'Part-time', "Job Type", "add")}
											variant="contained"
										>
											Part-time
										</button>

								}
							</Accordion.Body>
						</Accordion.Item>
						{/* <Accordion.Item eventKey="1" style={{ "border": "none" }}>
							<Accordion.Header>Field of Interest</Accordion.Header>
							<Accordion.Body>
								<p>Please select upto 3 areas of interest from below</p>
							</Accordion.Body>
						</Accordion.Item> */}
						<Accordion.Item eventKey="2" style={{ "border": "none" }}>
							<Accordion.Header>Location</Accordion.Header>
							<Accordion.Body>
								<Autocomplete
									size='small'
									freeSolo
									id="Location"
									onChange={(e, value) => handleFilters(e, value, "Location", "add")}
									options={props.filterData['Location'].map((option) => option)}
									renderInput={(params) => (
										<TextField
											{...params}
											label={<span><i className="fas fa-map-marker-alt ms-1"></i> Location</span>}
											InputProps={{
												...params.InputProps,
												type: 'search',
											}}
										/>
									)}
								/>
								<div className="d-flex flex-wrap">

									{
										props.appliedFilters['Location'].map(location => {
											return (
												<div key={location} className="selected-button-custom mt-2 bg-navy-blue fw-500 fs-12">
													<button onClick={(e) => handleFilters(e, location, "Location", "remove")} className="border-0 button-close-custom"><i className="fas fa-times"></i></button>
													{location}
												</div>
											)
										})
									}
								</div>
							</Accordion.Body>
						</Accordion.Item>
						{/* <Accordion.Item eventKey="3" style={{ "border": "none" }}>
							<Accordion.Header>Date Posted</Accordion.Header>
							<Accordion.Body>
								Lorem ipsum
							</Accordion.Body>
						</Accordion.Item> */}
						<Accordion.Item eventKey="4" style={{ "border": "none" }}>
							<Accordion.Header>Company</Accordion.Header>
							<Accordion.Body>
								<Autocomplete
									size='small'
									freeSolo
									id="Company"
									disableClearable
									onChange={(e, value) => handleFilters(e, value, "Company", "add")}
									options={props.filterData['Company'].map((option) => option)}
									renderInput={(params) => (
										<TextField
											{...params}
											label={<span><i className="fas fa-search ms-1"></i> Company</span>}
											InputProps={{
												...params.InputProps,
												type: 'search',
											}}
										/>
									)}
								/>
								<div className="d-flex flex-wrap">
									{
										props.appliedFilters['Company'].map(company => {
											return (
												<div className="selected-button-custom mt-2 bg-navy-blue fw-500 fs-12">
													<button onClick={(e) => handleFilters(e, company, "Company", "remove")} className="border-0 button-close-custom"><i className="fas fa-times"></i></button>
													{company}
												</div>
											)
										})
									}
								</div>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<div className="d-flex justify-content-center alihn-items-center">
						<button className="apli-btn mt-4 apply-button-custom" onClick={applyFilters}>Apply</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default FilterModal;
