import { useState, useContext } from 'react'
import './Signup.css'
import Signup_icon from './Media/Signup_icon.png'
import Logo_white from './Media/logo-white.png'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Success_img from '../opportunities/Media/success_img.png'
import { loadingContext, messageContext } from '../App';

function ForgetPassword() {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "#ffffff",
        boxShadow: 24,
        borderRadius: "10px",
        p: 4,
    };

    const loadingHandler = useContext(loadingContext)
	const messageHandler = useContext(messageContext)

    const [initialInput, setInitialInput] = useState(true)
    const [passwordShown, setPasswordShown] = useState(false);
    const [otpInput, setOtpInput] = useState(false)
    const [isPasswordChanged, setIsPasswordChanged] = useState(false)

    const [userData, setUserData] = useState({
        'mobile_number': ''
    })
    const [otp, setOtp] = useState('')
    const [newPasswordData, setNewPasswordData] = useState({
        'passwordData': '',
        'confirmPasswordData': ''
    })

    const changePassword = () => {
        setIsPasswordChanged(true)
    }

    return (

        <div className="text-navy-blue signup-container-parent position-relative">
            {initialInput ?
                <div className="row signup-container">
                    <div className="col-md-5 col-0 signup-container-left d-flex align-items-center justify-content-center signup-container-left-final">
                        <div className="logo-box">
                            <img className="logo-img" src={Logo_white} alt="Logo" />
                        </div>
                        <div>
                            <img className="signup-icon-img signup-container-left-final" src={Signup_icon} alt="Signup" />
                        </div>
                    </div>
                    <span role="button" className="back-button-container">
                        <i className="fas fa-arrow-left fa-lg"></i>
                    </span>
                    <div className="col-md-7 signup-container-right d-flex justify-content-center">
                        <div className="signup-form-container px-4">
                            <h3 className="signup-form-heading-custom">Forget Password</h3>
                            <div className="row">
                                <div className="col-8 d-flex flex-column mt-1">
                                    <label className="fw-500 fs-14 mb-2" htmlFor="forgetPassword-mobile">Mobile Number</label>
                                    <input value={userData.mobile_number} onChange={(e) => setUserData({ ...userData, 'mobile_number': e.target.value })} className="edit-modal-input-box mb-0" id="forgetPassword-mobile" type="number" />
                                </div>
                                {!otpInput &&
                                    <div className="col-4">
                                        <button className="apli-btn get-otp-button" onClick={() => setOtpInput(true)}>
                                            Get OTP
                                        </button>
                                    </div>
                                }
                            </div>
                            {otpInput &&
                                <div>
                                    <div className="row mb-5">
                                        <div className="col-6 d-flex flex-column mt-1">
                                            <label className="fw-500 fs-14 mb-2" htmlFor="forgetPassword-otp">Enter OTP</label>
                                            <input value={otp} onChange={(e) => setOtp(e.target.value)} className="edit-modal-input-box mb-0" id="forgetPassword-otp" type="number" />
                                        </div>
                                        <div className="col-6">
                                            <button className="apli-btn get-otp-button">
                                                Resend OTP
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <button disabled={!otpInput} className="apli-btn w-100 mt-5 submit-button-custom" onClick={() => setInitialInput(false)}>Verify</button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="row signup-container">
                    <div className="col-md-5 signup-container-left d-flex align-items-center justify-content-center signup-container-left-final">
                        <div className="logo-box">
                            <img className="logo-img" src={Logo_white} alt="Logo" />
                        </div>
                        <div>
                            <img className="signup-icon-img signup-container-left-final" src={Signup_icon} alt="Signup" />
                        </div>
                    </div>
                    <span role="button" onClick={() => setInitialInput(true)} className="back-button-container">
                        <i className="fas fa-arrow-left fa-lg"></i>
                    </span>
                    <div className="col-md-7 signup-container-right d-flex justify-content-center">
                        <div className="signup-form-container px-4">
                            <h3 className="signup-form-heading-custom">Create New Password</h3>
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-500 fs-14 mb-2" htmlFor="forgetPassword-password">New Password</label>
                                <div className="d-flex position-relative">
                                    <input style={{ width: "100%" }} value={newPasswordData.passwordData} onChange={(e) => setNewPasswordData({ ...newPasswordData, 'passwordData': e.target.value })} className="edit-modal-input-box mb-0" id="forgetPassword-password" type={passwordShown ? "text" : "password"} ></input>
                                    <button className="password-show-toggle-button" onClick={() => setPasswordShown(!passwordShown)}>
                                        {passwordShown ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-500 fs-14 mb-2" htmlFor="forgetPassword-password">Confirm Password</label>
                                <div className="d-flex position-relative">
                                    <input style={{ width: "100%" }} value={newPasswordData.confirmPasswordData} onChange={(e) => setNewPasswordData({ ...newPasswordData, 'confirmPasswordData': e.target.value })} className="edit-modal-input-box mb-0" id="forgetPassword-password" type={passwordShown ? "text" : "password"} ></input>
                                    <button className="password-show-toggle-button" onClick={() => setPasswordShown(!passwordShown)}>
                                        {passwordShown ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <button className="apli-btn w-100 mt-4 submit-button-custom" onClick={changePassword}>Reset Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Modal open={isPasswordChanged}>
                <Box sx={style} className="modal-container-forget-password">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <img className="success-img mb-4 mt-3" src={Success_img} alt="icon" />
                        <h5 className="text-navy-blue fs-18 fw-500 mt-5 text-center">Password Changed Successfully</h5>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                        <button className="fw-600 fs-17 border-0 text-white submit-large-blue mb-4" onClick={() => setIsPasswordChanged(false)}>Login Now</button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ForgetPassword