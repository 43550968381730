import { useEffect, useState, useContext } from 'react';
import Box from "@mui/material/Box";
import { loadingContext, messageContext } from '../../App';
import axios from 'axios';
import Cookies from 'js-cookie'
import "../../App.css"
import "./NotificationDropdown.css"
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import empty_notification from './empty-notifications.png'

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'


function NotificationDropdown({ data }) {
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />))(({ theme }) => ({
            width: 42,
            height: 24,
            padding: 0,
            '& .MuiSwitch-switchBase': {
                padding: 0,
                margin: 2,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                        opacity: 1,
                        border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                    },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color:
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[600],
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
                },
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 22,
                height: 20,
            },
            '& .MuiSwitch-track': {
                borderRadius: 38 / 2,
                backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                    duration: 500,
                }),
            },
        }));

    const [isNotificationsOpen, setIsNotificationsOpen] = useState(true)
    const [notificationPreferences, setNotificationPreferences] = useState({
        all: true,
        industry_sessions: true,
        job_application_updates: true,
        jobs_and_internships: true,
        personalized_jobs: true
    })

    useEffect(() => {
        if (data !== null) {
            setNotificationPreferences( data.notification_preferences)
        }
        
    }, [])
    
    const updateNotificationPreferences = () => {
        loadingHandler.setIsLoading(true)
        let navEle = document.getElementsByClassName('nav-notification-container')
        navEle[0].click()
        let notificationPreferenceForm = new FormData()
        notificationPreferenceForm.append('data', JSON.stringify(notificationPreferences))
        axios.post(`${baseUrl}/candidate/unsubscribe_notifications/`,
        notificationPreferenceForm,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                withCredentials: true
            }
        ).then(r => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: 'success',
                displayText: 'Updated Notification Preferences'
            })
        }).catch(e => {
 
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Internal Server Error"
            })
        })
    }

    const changeAllNotificationPreferences = (checked) => {
        setNotificationPreferences({
            'all': checked,
            'industry_sessions': checked,
            'job_application_updates': checked,
            'jobs_and_internships': checked,
            'personalized_jobs': checked
        })
    }

    return (
        <>
            {isNotificationsOpen && data ?
                <Box className="notification-dropdown-container">
                    <p className="text-center fw-600 mt-3">Notifications</p>
                    <button onClick={() => { setIsNotificationsOpen(false) }} className="bg-white border-0 position-absolute notification-setting-button">
                        <i className="fas fa-cog fa-lg"></i>
                    </button>
                    <div className="p-3">
                        {Object.keys(data.notifications).length === 0 ?
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <img className="p-5" src={empty_notification} alt="no-notifications" />
                                <p className="fw-500 fs-14 p-4 pt-0">You have no new notifications</p>
                            </div>
                            :
                            data.notifications.map((notification) => {
                                return (
                                    notification.hasOwnProperty('job_id') ?
                                        <div onClick={() => window.open(`${baseUrl}/job/${notification.job_id}`, '_blank')} role="button" className="row d-flex justify-content-start align-items-center px-2 notification-menu-custom mb-3" key={notification.id}>
                                            <div className="col-2 p-3 pt-0 pr-0"><i className="fas fa-user-circle fa-2x"></i></div>
                                            <div className="col-7 fs-14 text-navy-blue"><p><b>{notification.name}</b> {notification.message}</p></div>
                                            <div className="col-3 fs-12 fw-400 light-text"><p>{notification.time.split("T")[0]}</p></div>
                                        </div>
                                        :

                                        <div className="row d-flex justify-content-start align-items-center px-2 notification-menu-custom mb-3" key={notification.id}>
                                            <div className="col-2 p-3 pt-0 pr-0"><i className="fas fa-user-circle fa-2x"></i></div>
                                            <div className="col-7 fs-14 text-navy-blue"><p><b>{notification.name}</b> {notification.message}</p></div>
                                            <div className="col-3 fs-12 fw-400 light-text"><p>{notification.time.split("T")[0]}</p></div>
                                        </div>
                                )
                            }
                            )}
                    </div>
                </Box>
                :
                <Box className="notification-dropdown-container">
                    <p className="text-center fw-600 mb-2 mt-3">Settings</p>
                    <button onClick={() => { setIsNotificationsOpen(true) }} className="bg-white border-0 position-absolute notification-back-button">
                        <i className="fas fa-arrow-left fa-lg"></i>
                    </button>
                    <div className="p-3">
                        <div className="d-flex flex-column justify-content-between align-items-start px-4">
                            <div className="d-flex justify-content-between w-100 p-3 pt-0">
                                <p className="text-navy-blue">Receive All Notifications</p>
                                <FormControlLabel control={<IOSSwitch sx={{ ml: 5, mb: 2 }} onChange={(e) => changeAllNotificationPreferences(e.target.checked)} checked={notificationPreferences.all} />} label="" />
                            </div>
                            <div className="d-flex justify-content-between w-100 p-3">
                                <p className="text-navy-blue">Jobs Promotions</p>
                                <FormControlLabel control={<IOSSwitch sx={{ ml: 5, mb: 2 }} onChange={(e) => setNotificationPreferences({...notificationPreferences, jobs_and_internships: e.target.checked})} checked={notificationPreferences.jobs_and_internships} />} label="" />
                            </div>
                            <div className="d-flex justify-content-between w-100 p-3">
                                <p className="text-navy-blue">Job Application Updates</p>
                                <FormControlLabel control={<IOSSwitch sx={{ ml: 5, mb: 2 }} onChange={(e) => setNotificationPreferences({...notificationPreferences, job_application_updates: e.target.checked})} checked={notificationPreferences.job_application_updates} />} label="" />
                            </div>
                            <div className="d-flex justify-content-between w-100 p-3">
                                <p className="text-navy-blue">Personalised Jobs Interest’s</p>
                                <FormControlLabel control={<IOSSwitch sx={{ ml: 5, mb: 2 }} onChange={(e) => setNotificationPreferences({...notificationPreferences, personalized_jobs: e.target.checked})} checked={notificationPreferences.personalized_jobs} />} label="" />
                            </div>
                            <div className="d-flex justify-content-between w-100 p-3 pb-3">
                                <p className="text-navy-blue">Sessions by industry Experts</p>
                                <FormControlLabel control={<IOSSwitch sx={{ ml: 5, mb: 2 }} onChange={(e) => setNotificationPreferences({...notificationPreferences, industry_sessions: e.target.checked})} checked={notificationPreferences.industry_sessions} />} label="" />
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <button onClick={updateNotificationPreferences} className="apli-btn ">Update</button>
                    </div>
                </Box>
            }
        </>
    );
}

export default NotificationDropdown;
