import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios'
import Cookies from 'js-cookie'
import './chat.css'
import ChatBox from './ChatBox';
import taskSuccessImg from "../../../opportunities/Media/success_img.png";


let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function MainChat(props) {
    const [totalWindowProgress, setTotalWindowProgress] = useState({ completedWindows: 0, percentProgress: 0 })
    const [currentChatObject, setCurrentChatObject] = useState(props.task.task_details)
    const [waitForReply, setWaitForReply] = useState(false)
    const [liveChat, setLiveChat] = useState([])
    const [currentMessageIndex, setCurrentMessageIndex] = useState({ pageNumber: 0, chatNumber: 0 })
    const [showTaskCompleted, setShowTaskCompleted] = useState(false)
    const [currentWindowStatus, setCurrentWindowStatus] = useState("IN_PROGRESS")
    let params = useParams()
    let navigate = useNavigate();

    const updateLiveChat = () => {
        let nextChatObject = currentChatObject[currentMessageIndex.pageNumber]['user_conversation'][currentMessageIndex.chatNumber]
        let mainChatContainer = document.getElementsByClassName('main-chat-container')[0]
        mainChatContainer.scrollTop = mainChatContainer.scrollHeight;
        if (!waitForReply) {
            setLiveChat((chats) => [...chats, nextChatObject])
            setCurrentMessageIndex((currentMessageIndex) => ({ ...currentMessageIndex, chatNumber: currentMessageIndex.chatNumber + 1 }))
            if (nextChatObject.response_required && nextChatObject.status === "NOT_REPLIED") {
                setWaitForReply(true)
            }
        }
        mainChatContainer.scrollTop = mainChatContainer.scrollHeight;
    }

    const updateCurrentChatObject = () => {
        let tempCurrentChat = currentChatObject
        let noOfCompletedWindows = 0
        tempCurrentChat.forEach(windowChats => {
            let tempUserConversation = []
            windowChats.user_conversation.forEach(chat => {
                tempUserConversation.push(chat)
                if ( chat.system_message && chat.status === "REPLIED") {
                    let tempChat = {
                        is_reply: true,
                        system_message: chat.user_response,
                        message_type: "TEXT"
                    }
                    tempUserConversation.push(tempChat)
                }
            })
            if (windowChats.order === 1 && windowChats.status === "COMPLETED") {
                setCurrentWindowStatus("COMPLETED")
                setCurrentMessageIndex((currentMessageIndex) => ({ ...currentMessageIndex, chatNumber: tempUserConversation.length }))
                setLiveChat(tempUserConversation)
            }
            if (windowChats.status === "COMPLETED") {
                noOfCompletedWindows += 1
            }
            windowChats.user_conversation = tempUserConversation
        });
        let percentProgress = (noOfCompletedWindows / currentChatObject.length) * 100
        setTotalWindowProgress({ completedWindows: noOfCompletedWindows, percentProgress: percentProgress })
        if (props.task.is_submitted)
            tempCurrentChat.pop()
        setCurrentChatObject(tempCurrentChat)
    }

    const loadNewWindow = (type) => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (type === "next" && currentMessageIndex.pageNumber === currentChatObject.length - 1 && currentChatObject[currentMessageIndex.pageNumber]['status'] === "COMPLETED") {
            setShowTaskCompleted(true)
        } else if (type === "next" && currentWindowStatus === "COMPLETED" && currentMessageIndex.pageNumber < currentChatObject.length - 1) {
            if (currentChatObject[currentMessageIndex.pageNumber + 1]['status'] === "COMPLETED") {
                setLiveChat(currentChatObject[currentMessageIndex.pageNumber + 1]['user_conversation'])
                setCurrentMessageIndex(currentMessageIndex => ({ pageNumber: currentMessageIndex.pageNumber + 1, chatNumber: currentChatObject[currentMessageIndex.pageNumber + 1]['user_conversation'].length }))
            } else if (currentChatObject[currentMessageIndex.pageNumber + 1]['status'] === "IN_PROGRESS") {
                setLiveChat([])
                setCurrentMessageIndex(currentMessageIndex => ({ pageNumber: currentMessageIndex.pageNumber + 1, chatNumber: 0 }))
            }
            setCurrentWindowStatus(currentChatObject[currentMessageIndex.pageNumber + 1]['status'])
        } else if (type === "prev" && currentMessageIndex.pageNumber > 0) {
            if (currentChatObject[currentMessageIndex.pageNumber - 1]['status'] === "COMPLETED") {
                setLiveChat(currentChatObject[currentMessageIndex.pageNumber - 1]['user_conversation'])
                setCurrentMessageIndex(currentMessageIndex => ({ pageNumber: currentMessageIndex.pageNumber - 1, chatNumber: currentChatObject[currentMessageIndex.pageNumber - 1]['user_conversation'].length }))
            } else if (currentChatObject[currentMessageIndex.pageNumber - 1]['status'] === "IN_PROGRESS") {
                setLiveChat([])
                setCurrentMessageIndex(currentMessageIndex => ({ pageNumber: currentMessageIndex.pageNumber - 1, chatNumber: 0 }))
            }
            setCurrentWindowStatus(currentChatObject[currentMessageIndex.pageNumber - 1]['status'])
        } else if (type === "prev" && currentMessageIndex.pageNumber === 0) {
            navigate(`/candidate/enrolled-virtual-experiences/${params.virtualExpId}`)
        }
    }

    const updateWindowStatus = () => {
        if(currentChatObject[currentMessageIndex.pageNumber]['window_type'] !== "DOC_SUBMISSION") {
            let taskData = new FormData()
            let task_data = {
                taskId: params.taskId,
                windowNumber: currentMessageIndex.pageNumber
            }
            taskData.append('task_data', JSON.stringify(task_data))
            axios.post(`${baseUrl}/candidate/update_chat_window_status/`,
                taskData,
                {
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },
                    withCredentials: true
                }
            )
                .then(response => {
                    let tempCurrentChatObject = currentChatObject
                    tempCurrentChatObject[currentMessageIndex.pageNumber]['status'] = "COMPLETED"
                    setCurrentWindowStatus("COMPLETED")
                    let percentProgress = (totalWindowProgress.completedWindows + 1 / currentChatObject.length) * 100
                    setTotalWindowProgress({ completedWindows: totalWindowProgress.completedWindows + 1, percentProgress: percentProgress })

                })
                .catch(e => {

                })
        }
    }

    useEffect(() => {
        updateCurrentChatObject()
    }, [])

    useEffect(() => {
        let interval = setInterval(() => {
            updateLiveChat()
        }
            , 1000
        )

        if ((currentChatObject.length === currentMessageIndex.pageNumber) || (currentChatObject[currentMessageIndex.pageNumber]['user_conversation'].length === currentMessageIndex.chatNumber)) {
            clearInterval(interval);
        }

        if (currentChatObject[currentMessageIndex.pageNumber]['user_conversation'].length === currentMessageIndex.chatNumber && currentChatObject[currentMessageIndex.pageNumber]['status'] === "IN_PROGRESS" && currentMessageIndex.pageNumber < currentChatObject.length) {
            updateWindowStatus()
        }

        return () => {
            clearInterval(interval);
        };
    }, [currentMessageIndex, waitForReply])

    return (
        <>
            {
                !showTaskCompleted ?
                    <>
                        <div className="window-heading-container">
                            <h4>{currentChatObject[currentMessageIndex.pageNumber]['display_name']}</h4>
                            <div className='progress-bar'>
                                <span style={{ backgroundColor: '#3BDB96', height: '100%', width: `${totalWindowProgress.percentProgress}%` }}></span>
                            </div>
                        </div>
                        <div className="main-chat-container">
                            {
                                liveChat.map((chatObject, index) => {
                                    if (index + 1 === liveChat.length)
                                        return (<ChatBox key={index} setCurrentWindowStatus={setCurrentWindowStatus}  currentWindowObject={currentChatObject[currentMessageIndex.pageNumber]} setShowTaskCompleted={setShowTaskCompleted} chatObject={chatObject} liveChat={liveChat} currentMessageIndex={currentMessageIndex} setLiveChat={setLiveChat} setWaitForReply={setWaitForReply} showLoader={currentWindowStatus === "IN_PROGRESS"} />)
                                    return (<ChatBox key={index} setCurrentWindowStatus={setCurrentWindowStatus} currentWindowObject={currentChatObject[currentMessageIndex.pageNumber]} setShowTaskCompleted={setShowTaskCompleted} chatObject={chatObject} liveChat={liveChat} currentMessageIndex={currentMessageIndex} setLiveChat={setLiveChat} setWaitForReply={setWaitForReply} showLoader={false} />)
                                })
                            }
                        </div>
                        <div className="window-switching-container">
                            <button className="btn-prev" onClick={() => loadNewWindow("prev")}><i className="fas fa-arrow-left"></i> &nbsp; Previous</button>
                            <button className="btn-next" onClick={() => loadNewWindow("next")}>Next &nbsp;<i className="fas fa-arrow-right"></i></button>
                        </div>
                    </>
                    :
                    <>
                        <div className="task-success-container">
                            <img src={taskSuccessImg} alt="success" />
                            <h4 className="mt-5">Mission Completed Successfully</h4>
                            <div className="task-success-btn-container">
                                <button className="apli-btn back-ve-btn" onClick={() => navigate(`/candidate/enrolled-virtual-experiences/${params.virtualExpId}/`)}>Back to VE's</button>
                                {
                                    props.nextTaskId &&
                                    <button className="apli-btn next-mission-btn" onClick={() => window.location = (`/candidate/enrolled-virtual-experiences/${params.virtualExpId}/${props.nextTaskId}`)}>Next Mission</button>
                                }
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default MainChat