import { React, useEffect, useMemo, useState, useContext } from 'react';
import axios from 'axios'
import Cookies from 'js-cookie'
import {

	FormControl,
	Select,
	MenuItem,
	InputLabel,
	ListSubheader,
	TextField,
	InputAdornment,
	Autocomplete
} from "@mui/material";
import { DropdownButton, Form } from 'react-bootstrap';
import EnrolledVirtualExperiences from './EnrolledVirtualExperiences/EnrolledVirtualExperiences'
import NonEnrolledVirtualExperiences from './NonEnrolledVirtualExperiences/NonEnrolledVirtualExperiences'
import VirtualExperienceDetails from './VirtualExperienceDetails/VirtualExperienceDetails';
import './VirtualExperiences.css'
import communityIcon from './Media/banner-community-icon.png'
import taskIcon from './Media/banner-task-icon.png'
import jobIcon from './Media/banner-job-icon.png'
import { loadingContext, messageContext, activeLinkContext } from '../App';
import Footer from '../footer/Footer'

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
	baseUrl = 'http://localhost:8000'

const requestObject = axios.create({
	baseURL: baseUrl,
	headers: { 'X-CSRFToken': Cookies.get('csrftoken') },
	withCredentials: true,
});

let filtersData = { 'No-filter': [] }
let searchData = []

function VirtualExperiencesMain() {
	const loadingHandler = useContext(loadingContext)
	const messageHandler = useContext(messageContext)
	const activeLinkHandler = useContext(activeLinkContext)
	const [registeredVirtualExperiences, setRegisteredVirtualExperiences] = useState({})
	const [industryFilter, setIndustryFilter] = useState("No-filter")
	const [roleFilter, setRoleFilter] = useState("No-filter")
	const [showFooter, setShowFooter] = useState(false)
	const [searchText, setSearchText] = useState("");
	const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
	let displayedIndustries = useMemo(
		() => Object.keys(filtersData).filter((option) => containsText(option, searchText)),
		[searchText, filtersData]
	);

	let displayedRoles = useMemo(
		() => filtersData[industryFilter].filter((option) => containsText(option, searchText)),
		[searchText, filtersData, roleFilter, industryFilter]
	);


	//this use effect is to fetch regivirtual experiences
	useEffect(() => {
		activeLinkHandler.updateActiveLink({ type: 'virtualExperience' })
		loadingHandler.setIsLoading(true)
		requestObject.get('candidate/fetch_registered_virtual_experiences')
			.then((response) => {
				Object.values(response.data.industry_type_roles_dict).forEach((roles) => {
					searchData = [...searchData, ...roles]
				})
				filtersData = { "No-filter": searchData, ...response.data.industry_type_roles_dict }
				setRegisteredVirtualExperiences(response.data.registered_virtual_experience)
				loadingHandler.setIsLoading(false)
				setShowFooter(true)
			})
			.catch((e) => {
				loadingHandler.setIsLoading(false)
				messageHandler.setMessage({
					showMessage: false,
					type: "warning",
					displayText: "Internal Server Error"
				})
			})
	}, []);

	const handleVirtualExpFilters = (e, type) => {
		if (type === 'industryType') {
			setIndustryFilter(e.target.value)
			setRoleFilter('No-filter')
		} else {
			setRoleFilter(e.target.value)
		}
	}

	const handleSearch = (e, value) => {
		for (let industryName in filtersData) {
			if (filtersData[industryName].includes(value)) {
				setIndustryFilter(industryName)
				setRoleFilter(value)
				break;
			}
		}
	}

	return (
		<>
			<div className="virtual-exp-banner-container position-relative">
				<div className="virtual-exp-banner-heading-container">
					<h5 className="mx-auto ps-2 pe-2" style={{ maxWidth: "550px" }}>"Explore the day to day work done in different roles to understand the one that suits you the best"</h5>
				</div>
				<div className="row virtual-exp-banner-content">
					<div className="col-md-4 p-3 d-flex align-items-center">
						<img src={taskIcon} alt="task" style={{ width: "30px", height: "30px" }} />
						<p className="m-0 fs-14 ms-2">Tasks will be shared directly with companies, get noticed by recruiters for your work.</p>
					</div>
					<div className="col-md-4 p-3 d-flex align-items-center">
						<img src={communityIcon} alt="community" style={{ width: "30px", height: "30px" }} />
						<p className="m-0 fs-14 ms-2">Join communities to network with peers</p>
					</div>
					<div className="col-md-4 p-3 d-flex align-items-center">
						<img src={jobIcon} alt="job" style={{ width: "30px", height: "30px" }} />
						<p className="m-0 fs-14 ms-2">Priority applications to all companies with the particular role open.</p>
					</div>
				</div>
			</div>
			<div className="ve-main-container">
				<EnrolledVirtualExperiences virtualExps={registeredVirtualExperiences} />
				{
					Object.keys(filtersData).length > 0 &&
					<div className="row justify-content-center filter-container">
						<div className="col-md-4 col-sm-12">
							<h6 className="fw-600 text-navy-blue pt-3">Search for courses as per Interest</h6>
						</div>
						<div className="col-xl-4 col-lg-6 col-sm-6 col-12 p-2 ">
							<Autocomplete
								sx={{ maxWidth: 250, borderRadius: 150 }}
								size='small'
								freeSolo
								id="Company"
								disableClearable
								onChange={(e, value) => handleSearch(e, value)}
								options={searchData.map((option) => option)}
								renderInput={(params) => (
									<TextField
										className="bg-light"
										{...params}
										label={<span><i className="fas fa-search ms-1"></i> Search for all Skills</span>}
										InputProps={{
											...params.InputProps,
											type: 'search',
										}}
									/>
								)}
							/>
						</div>
						<div className="col-xl-4 col-lg-6 col-sm-12 row p-2 pt-0">
							<div className="col-md-6 col-12 p-0 m-0 mt-2">
								<FormControl sx={{ mx: 1, width: 180 }} size="small">
									<InputLabel id="search-select-label">Industry</InputLabel>
									<Select
										// Disables auto focus on MenuItems and allows TextField to be in focus
										MenuProps={{ autoFocus: false }}
										labelId="search-select-label"
										id="search-select"
										value={industryFilter}
										label="Industry"
										onChange={(e) => handleVirtualExpFilters(e, 'industryType')}
										onClose={() => setSearchText("")}
										renderValue={() => industryFilter}
									>
										<ListSubheader>
											<TextField
												size="small"
												autoFocus
												placeholder="Type to search..."
												fullWidth
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															{/* <SearchIcon /> */}
														</InputAdornment>
													)
												}}
												onChange={(e) => setSearchText(e.target.value)}
												onKeyDown={(e) => {
													if (e.key !== "Escape") {
														// Prevents autoselecting item while typing (default Select behaviour)
														e.stopPropagation();
													}
												}}
											/>
										</ListSubheader>
										{displayedIndustries.map((option, i) => (
											<MenuItem key={i} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
							<div className="col-md-6 col-12 p-0 m-0 mt-2">
								<FormControl sx={{ mx: 1, width: 180 }} size="small">
									<InputLabel id="search-select-label">Role</InputLabel>
									<Select
										// Disables auto focus on MenuItems and allows TextField to be in focus
										MenuProps={{ autoFocus: false }}
										labelId="search-select-label"
										id="search-select"
										value={roleFilter}
										label="Role"
										onChange={(e) => handleVirtualExpFilters(e, 'role')}
										onClose={() => setSearchText("")}
										renderValue={() => roleFilter}
									>
										<ListSubheader>
											<TextField
												size="small"
												// Autofocus on textfield
												autoFocus
												placeholder="Type to search..."
												fullWidth
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															{/* <SearchIcon /> */}
														</InputAdornment>
													)
												}}
												onChange={(e) => setSearchText(e.target.value)}
												onKeyDown={(e) => {
													if (e.key !== "Escape") {
														// Prevents autoselecting item while typing (default Select behaviour)
														e.stopPropagation();
													}
												}}
											/>
										</ListSubheader>
										<MenuItem key='No-filter' value='No-filter'>
												No-filter
                                        </MenuItem>
										{displayedRoles.map((option, i) => (
											<MenuItem key={i} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						</div>
					</div>
				}
				<NonEnrolledVirtualExperiences industryFilter={industryFilter} roleFilter={roleFilter} />
			</div>
			{showFooter && <Footer />}
		</>
	);
}

export default VirtualExperiencesMain;
