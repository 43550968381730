import React, { useState } from 'react'
import EditExtraCurricular from './EditExtraCurricular'

function ExtraCurricular({ extraCurricular, isPublicProfile, extraCurriculars, setExtraCurriculars }) {
	const [editExtraCurricular, setEditExtraCurricular] = useState(false)
	return (
		<>
			<div className="row m-1 p-4 mb-4 border rounded-3 position-relative">
				{
					!isPublicProfile &&
					<span role="button" onClick={() => setEditExtraCurricular(true)}>
						<i className="fas fa-pen pen-edit-icon"></i>
					</span>
				}
				<div className="col-3">
					<i className="fas fa-shield-alt fa-4x"></i>
				</div>
				<div className="fs-14 col-9">
					<p className="text-navy-blue fw-500 mb-2">{extraCurricular.role}</p>
					<p className="text-navy-blue mb-2">{extraCurricular.organisation}</p>
					<span className="light-text fs-13">{extraCurricular.start} -  { (extraCurricular.end === null || extraCurricular.end.length === 0) ? "Present" : String(extraCurricular.end) }</span>
				</div>
			</div>
			<EditExtraCurricular open={editExtraCurricular} setOpen={setEditExtraCurricular} extraCurricular={extraCurricular} extraCurriculars={extraCurriculars} setExtraCurriculars={setExtraCurriculars} />
		</>
	)
}

export default ExtraCurricular