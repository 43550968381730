import { React, useState, useContext } from 'react'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { loadingContext, messageContext } from '../../App';
import axios from 'axios'
import Cookies from 'js-cookie'

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'


function CollegeDetailsModal(props) {
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)

    const [selectedCollegeIds, setSelectedCollegeIds] = useState({
        'college': '',
        'passingYear': 'selectYear',
        'course': 'selectCourse',
        'specialization': 'selectSpecialization',
        'batch': 'selectBatch'
    })

    const [currentCollegeDetails, setCurrentCollegeDetails] = useState(null)

    const [nonRegisteredCollegeDetails, setNonRegisteredCollegeDetails] = useState({
        'enrollmentYear': '',
        'passingYear': '',
        'semesters': '',
        'board': '',
        'courseType': 'undergraduate',
        'gradingType': '10',
        'course': 'selectCourse',
        'specialization': 'selectSpecialization',
    })

    if (props.collegeDetails && currentCollegeDetails === null) {
        setCurrentCollegeDetails({ ...props.collegeDetails, batches: {}, branches: {} })
    }

    const setSpecializations = (e) => {
        setSelectedCollegeIds({ ...selectedCollegeIds, course: e.target.value, specialization: "selectSpecialization", batch: "selectBatch" })
        let filteredSpecilizations = {}
        let passingYearCourseKey = (Object.values(currentCollegeDetails.colleges)[0]).concat(currentCollegeDetails.batch_years[selectedCollegeIds.passingYear].id).concat(currentCollegeDetails.courses[e.target.value].id)
        for (let branch in props.collegeDetails.branches) {
            let data = props.collegeDetails.branches[branch]['data'].split(' ')
            if (data.includes(passingYearCourseKey))
                filteredSpecilizations[branch] = props.collegeDetails.branches[branch]
        }
        setCurrentCollegeDetails({ ...currentCollegeDetails, branches: filteredSpecilizations })
    }

    const setBatches = (e) => {
        setSelectedCollegeIds({ ...selectedCollegeIds, specialization: e.target.value, batch: "selectBatch" })
        let passinYearCourseBranchKey = ((Object.values(currentCollegeDetails.colleges)[0]).concat(currentCollegeDetails.batch_years[selectedCollegeIds.passingYear].id).concat(currentCollegeDetails.courses[selectedCollegeIds.course].id)).concat(currentCollegeDetails.branches[e.target.value].id)
        let filteredBatches = {}
        for (let batch in props.collegeDetails.batches) {
            let data = props.collegeDetails.batches[batch]['data'].split(' ')
            if (data.includes(passinYearCourseBranchKey))
                filteredBatches[batch] = props.collegeDetails.batches[batch]
        }
        setCurrentCollegeDetails({ ...currentCollegeDetails, batches: filteredBatches })
    }

    const updateCollegeDetails = (type) => {
        let collegeDetails = {}
        let isDataValid = true
        if (type === 'registered') {
            if (selectedCollegeIds.specialization !== 'selectSpecialization') {
                let batchId = currentCollegeDetails.batches[selectedCollegeIds.batch].id
                collegeDetails = {
                    batch_id: batchId,
                    is_college_registered: props.collegeDetails.is_college_registered,
                }
            } else
                isDataValid = false

        } else {
            isDataValid = (nonRegisteredCollegeDetails.specialization !== 'specialization') && (nonRegisteredCollegeDetails.passingYear > nonRegisteredCollegeDetails.enrollmentYear && nonRegisteredCollegeDetails.enrollmentYear > 999 && nonRegisteredCollegeDetails.enrollmentYear < 10000 && nonRegisteredCollegeDetails.passingYear > 999 && nonRegisteredCollegeDetails.passingYear < 10000) && (nonRegisteredCollegeDetails.semesters > 0 && nonRegisteredCollegeDetails.board.length > 0)
            collegeDetails = nonRegisteredCollegeDetails
        }
        if (isDataValid) {
            loadingHandler.setIsLoading(true)
            props.setOpen({ ...props.setOpen, collegeDetailsModalOpen: false })
            let collegeDetailsForm = new FormData()
            let data = JSON.stringify({ 'edit_type': 'update_college_details', 'college_details': collegeDetails })
            collegeDetailsForm.append('data', data)
            axios.post(`${baseUrl}/candidate/update_and_load_resume/`,
                collegeDetailsForm,
                {
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },
                    withCredentials: true
                }
            ).then(r => {
                loadingHandler.setIsLoading(false)
                messageHandler.setMessage({
                    showMessage: true,
                    type: "success",
                    displayText: "Your College Details are Updated!"
                })
                window.location.reload()
            }).catch(e => {
                loadingHandler.setIsLoading(false)
                messageHandler.setMessage({
                    showMessage: true,
                    type: "warning",
                    displayText: "Internal Server Error"
                })
            })
        }
        else {
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Please fill all fields with valid data"
            })
        }
    }


    return (
        <>
            {
                props.collegeDetails &&

                < Modal show={props.open.collegeDetailsModalOpen} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                    <Modal.Body bsPrefix="modal-body profile-modal-content">
                        <h4 className="text-center fs-18 fw-500 mt-2 mb-3 text-navy-blue">Update College Details</h4>
                        {
                            props.collegeDetails.is_college_registered && props.collegeDetails.batches && Object.keys(props.collegeDetails.batches).length > 0 ?
                                currentCollegeDetails && currentCollegeDetails.batch_years && currentCollegeDetails.courses && currentCollegeDetails.branches && currentCollegeDetails.batches &&
                                <>

                                    <div className="row">
                                        <div className="col-md-6 mt-2">
                                            <div className="d-flex flex-column mb-3">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="passingYear">Passing Year</label>
                                                <Form.Select size='sm' bsPrefix="edit-modal-input-box" id="passingYear" value={selectedCollegeIds.passingYear} onChange={(e) => setSelectedCollegeIds({ ...selectedCollegeIds, passingYear: e.target.value, course: "selectCourse", specialization: "selectSpecialization", batch: "selectBatch" })}>
                                                    <option value="selectYear" disabled>Select Year</option>
                                                    {
                                                        Object.keys(currentCollegeDetails.batch_years).map(year => {
                                                            return <option key={year} value={year}>{year}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <div className="d-flex flex-column mb-3">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="course">Course</label>
                                                <Form.Select size='sm' bsPrefix="edit-modal-input-box" id="course" value={selectedCollegeIds.course} onChange={(e) => setSpecializations(e)}>
                                                    <option value="selectCourse" disabled>Select Course</option>
                                                    {selectedCollegeIds.passingYear.length > 0 &&
                                                        Object.keys(currentCollegeDetails.courses).map(course => {
                                                            return <option key={course} value={course}>{course}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <div className="d-flex flex-column mb-3">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="specialization">Specialization</label>
                                                <Form.Select size='sm' bsPrefix="edit-modal-input-box" id="specialization" value={selectedCollegeIds.specialization} onChange={(e) => setBatches(e)}>
                                                    <option disabled value="selectSpecialization">Select Specialization</option>
                                                    {
                                                        Object.keys(currentCollegeDetails.branches).map(branch => {
                                                            return <option key={branch} value={branch}>{branch}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <div className="d-flex flex-column mb-3">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="batch">Batch</label>
                                                <Form.Select size='sm' bsPrefix="edit-modal-input-box" id="batch" value={selectedCollegeIds.batch} onChange={e => setSelectedCollegeIds({ ...selectedCollegeIds, batch: e.target.value })}>
                                                    <option value="selectBatch">Select Batch</option>
                                                    {
                                                        Object.keys(currentCollegeDetails.batches).map(batch => {
                                                            return <option key={batch} value={batch}>{batch}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center p-4">
                                        <button onClick={() => updateCollegeDetails('registered')} className="apli-btn modal-save-button">Save</button>
                                    </div>
                                </> :
                                <>
                                    <div className="row">
                                        <div className="col-md-6 col-12 edit-modal-child-container p-3">
                                            <div className="d-flex flex-column">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="enrollmentYear">Enrollment Year</label>
                                                <input value={nonRegisteredCollegeDetails.enrollmentYear} onChange={(e) => setNonRegisteredCollegeDetails({ ...nonRegisteredCollegeDetails, enrollmentYear: e.target.value })} className="edit-modal-input-box mb-0" type="number" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 edit-modal-child-container p-3">
                                            <div className="d-flex flex-column">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="passingYear">Passing Year</label>
                                                <input value={nonRegisteredCollegeDetails.passingYear} onChange={(e) => setNonRegisteredCollegeDetails({ ...nonRegisteredCollegeDetails, passingYear: e.target.value })} className="edit-modal-input-box mb-0" type="number" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-12 edit-modal-child-container p-3">
                                            <div className="d-flex flex-column mb-3">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="passingYear">Semesters</label>
                                                <input value={nonRegisteredCollegeDetails.semesters} onChange={(e) => setNonRegisteredCollegeDetails({ ...nonRegisteredCollegeDetails, semesters: e.target.value })} className="edit-modal-input-box mb-0" type="number" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 edit-modal-child-container p-3">
                                            <div className="d-flex flex-column mb-3">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="passingYear">Board</label>
                                                <input value={nonRegisteredCollegeDetails.board} onChange={(e) => setNonRegisteredCollegeDetails({ ...nonRegisteredCollegeDetails, board: e.target.value })} className="edit-modal-input-box mb-0" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 edit-modal-child-container p-3">
                                            <div className="d-flex flex-column mb-3">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="specialization">What is your course type?</label>
                                                <Form.Select size='sm' bsPrefix="edit-modal-input-box" value={nonRegisteredCollegeDetails.courseType} onChange={(e) => setNonRegisteredCollegeDetails({ ...nonRegisteredCollegeDetails, courseType: e.target.value })}>
                                                    <option value="undergraduate">Undergraduate</option>
                                                    <option value="postgraduate">Postgraduate</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 edit-modal-child-container p-3">
                                            <div className="d-flex flex-column mb-3">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="batch">Grading Type</label>
                                                <Form.Select size='sm' bsPrefix="edit-modal-input-box" value={nonRegisteredCollegeDetails.gradingType} onChange={(e) => setNonRegisteredCollegeDetails({ ...nonRegisteredCollegeDetails, gradingType: e.target.value })}>
                                                    <option value="10">CGPA(/10)</option>
                                                    <option value="4">GPA(/4</option>
                                                    <option value="100">Percentage(100%)</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 edit-modal-child-container p-3">
                                            <div className="d-flex flex-column mb-3">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" >Course</label>
                                                <Form.Select size='sm' bsPrefix="edit-modal-input-box" value={nonRegisteredCollegeDetails.course} onChange={(e) => setNonRegisteredCollegeDetails({ ...nonRegisteredCollegeDetails, course: e.target.value })}>
                                                    <option disabled value="selectCourse">Select Course</option>
                                                    {
                                                        Object.keys(props.collegeDetails.degrees).map(degree => {
                                                            return <option key={degree} value={degree}>{degree}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 edit-modal-child-container p-3">
                                            <div className="d-flex flex-column mb-3">
                                                <label className="fw-600 fs-14 py-1 text-navy-blue" >Specialization</label>
                                                <Form.Select size='sm' bsPrefix="edit-modal-input-box" value={nonRegisteredCollegeDetails.specialization} onChange={e => setNonRegisteredCollegeDetails({ ...nonRegisteredCollegeDetails, specialization: e.target.value })}>
                                                    <option value="selectSpecialization">Select Sepcialization</option>
                                                    {
                                                        nonRegisteredCollegeDetails.course !== 'selectCourse' &&
                                                        props.collegeDetails.degrees[nonRegisteredCollegeDetails.course].map(spe => {
                                                            return <option key={spe} value={spe}>{spe}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center p-4">
                                        <button onClick={() => updateCollegeDetails('nonRegistered')} className="apli-btn modal-save-button">Save</button>
                                    </div>
                                </>
                        }
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export default CollegeDetailsModal