import React from 'react'
import Modal from 'react-bootstrap/Modal'
import "./Modals.css"

function AboutMeModal(props) {
    const updateAboutMe = () => {
        props.setOpen({ ...props.open, 'aboutMeModalOpen': false })
        props.updateBasicInfo()
    }
    return (
        <div>
            <Modal show={props.open.aboutMeModalOpen} centered fullscreen="md-down" size="md" fullscreen="sm-down" contentClassName="p-3 about-me-modal-body">
                <Modal.Body bsPrefix="modal-body profile-modal-content">
                    <button onClick={() => { props.setOpen({ ...props.open, 'aboutMeModalOpen': false }) }} className="edit-modal-close"><i className="fas fa-times"></i></button>
                    <h4 className="text-center fs-18 fw-500 mt-5 mb-5 text-navy-blue">About Me</h4>
                    <div className="row justify-content-center">
                        <div className="w-75 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="about-me-modal-desc">Description</label>
                                <textarea onChange={(e) => props.setEditedBasicInfo({ ...props.editedBasicInfo, 'about_me': e.target.value })} className="edit-modal-input-box" id="about-me-modal-skill-desc" rows="4" type="text" placeholder="Heyy! I am a self taught programmer" />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center p-4 mt-2">
                        <button className="apli-btn modal-save-button" onClick={updateAboutMe}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AboutMeModal