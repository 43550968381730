import { useContext, useEffect, useState } from 'react'
import {Modal, Form} from 'react-bootstrap'
import './VirtualExperienceDetails.css'
import '../VirtualExperiences.css'
import description_img from '../Media/description_img.png'
import success_virtual_exp from '../../opportunities/Media/success_img.png'
import incomplete_prfile from '../Media/profile_incomplete.png'
import Task from '../Task/Task'
import axios from 'axios'
import { loadingContext, messageContext, userContext } from '../../App'
import Cookies from 'js-cookie'
import { useNavigate, useParams } from "react-router-dom";
import Footer from '../../footer/Footer'

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
	baseUrl = 'http://localhost:8000'

function VirtualExperienceDetails(props) {

	let params = useParams()
	const [veTaskLength, setVeTaskLength] = useState(0)
	const loadingHandler = useContext(loadingContext)
	const messageHandler = useContext(messageContext)
	const user = useContext(userContext)
	const [virtualExperienceData, setVirtualExperienceData] = useState(null)
	const [viewFooter, setViewFooter] = useState(false)

    const [handleJobApplicationModal, setHandleJobApplicationModal] = useState({
        showJobApplicationModal: false,
        hasCompletedProfile: false,
		associated_job_id: ''
    })
    const [wantToApplyForJob, setWantToApplyForJob] = useState(true)

	let navigate = useNavigate();

	const enrollCourse = (event) => {
		event.preventDefault()
		loadingHandler.setIsLoading(true)
		let enrolledData = new FormData()

		enrolledData.append('virtual_experience_id', params.virtualExpId)
		axios.post(`${baseUrl}/candidate/enroll_virtual_experience`,
			enrolledData,
			{
				headers: {
					'X-CSRFToken': Cookies.get('csrftoken'),
				},
				withCredentials: true
			}
		)
			.then(response => {
				loadingHandler.setIsLoading(false)
				navigate(`/candidate/enrolled-virtual-experiences/${params.virtualExpId}/${response.data.first_task_id}`)
				messageHandler.setMessage({
					showMessage: true,
					type: "success",
					displayText: "Successfully Enrolled !"
				})
			})
			.catch(e => {
				loadingHandler.setIsLoading(false)
				messageHandler.setMessage({
					showMessage: true,
					type: "warning",
					displayText: "Internal Server Error"
				})
			})
	}

	useEffect(() => {
		if (props.enrolled) {
			if (user.user_email === null) {
				// window.location.replace(`${baseUrl}/candidate/virtual-experiences/${params.virtualExpId}`)
			}
			loadingHandler.setIsLoading(true)
			axios.get(`${baseUrl}/candidate/fetch_virtual_experience_progress`, {
				params: { virtual_experience_id: params.virtualExpId },
				withCredentials: true
			})
				.then((res) => {
					setVirtualExperienceData(res.data.virtual_experience)
					setVeTaskLength(res.data.virtual_experience.tasks.length)
					if(!res.data.applied_to_associated_job && res.data.virtual_experience['completed_ve']) {
						setHandleJobApplicationModal({showJobApplicationModal: true, hasCompletedProfile: res.data.completed_profile, associated_job_id: res.data.associated_job_id })
					}
					loadingHandler.setIsLoading(false)
					setViewFooter(true)
				})
				.catch(e => {
					loadingHandler.setIsLoading(false)
					messageHandler.setMessage({
						showMessage: true,
						type: "warning",
						displayText: "Internal Server Error"
					})
				})

		} else {
			loadingHandler.setIsLoading(true)
			axios.get(`${baseUrl}/candidate/fetch_virtual_experience_details`, {
				params: { virtual_experience_id: params.virtualExpId },
				withCredentials: true
			})
				.then((res) => {
					setVirtualExperienceData(res.data.virtual_experience)
					setVeTaskLength(res.data.virtual_experience.tasks.length)
					loadingHandler.setIsLoading(false)
					setViewFooter(true)
				})
				.catch(e => {
					loadingHandler.setIsLoading(false)
					messageHandler.setMessage({
						showMessage: true,
						type: "warning",
						displayText: "Internal Server Error"
					})
				})
		}
	}, [])

	const handleJobApplicationRadio = (e) => {
        setWantToApplyForJob(e.target.value === "yes")
    }

    const applyToJob = (event) => {
		event.preventDefault()
        if(wantToApplyForJob) {
            loadingHandler.setIsLoading(true)
            let finalApplicationData = new FormData()
            
            finalApplicationData.append('job', handleJobApplicationModal.associated_job_id)
            finalApplicationData.append('resume', `{"name": "Resume.pdf" , "url": "" , "custom_resume" : ${false}}`)
            axios.post(`${baseUrl}/candidate/add_new_application`,
                finalApplicationData,
                {
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },
                    withCredentials: true
                }
            )
                .then(r => {
					setHandleJobApplicationModal({...handleJobApplicationModal, showJobApplicationModal: false})
                    loadingHandler.setIsLoading(false);
                    messageHandler.setMessage({
                        showMessage: true,
                        type: "success",
                        displayText: "Successfully Applied to Job"
                    })
                })
                .catch(e => {
					setHandleJobApplicationModal({...handleJobApplicationModal, showJobApplicationModal: false})
                    loadingHandler.setIsLoading(false)
                    messageHandler.setMessage({
                        showMessage: true,
                        type: "warning",
                        displayText: "Internal Server Error"
                    })
                })
        } else {
			setHandleJobApplicationModal({...handleJobApplicationModal, showJobApplicationModal: false})
		}
    }


	return (
		<>
			<Modal
				show={handleJobApplicationModal.showJobApplicationModal}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				contentClassName="p-2"
			>

				<Modal.Body className="p-2">
					<div className="position-absolute end-0 me-3"  role="button" onClick={() => setHandleJobApplicationModal({...handleJobApplicationModal, showJobApplicationModal: false})} ><i className="fas fa-times"></i></div>

					{
						handleJobApplicationModal.hasCompletedProfile ?
							<div className="text-center">
								<img style={{ "transform": "scale(0.7)" }} src={success_virtual_exp} alt="success" />
								<h6>Congratulations!! for completing this Virtual Experience</h6>
								<p className="mt-4 fs-14 mb-2"> Do you want to apply for this particular job?</p>
								<div className="d-flex justify-content-center mb-4">
									<Form.Check
										type="radio"
										className="text-navy-blue fs-14 me-4"
										name="appliedToJob"
										value="yes"
										label="Yes"
										onChange={handleJobApplicationRadio}
										defaultChecked
									/>
									<Form.Check
										type="radio"
										name="appliedToJob"
										className="text-navy-blue fs-14 ms-4"
										value="no"
										label="No"
										onChange={handleJobApplicationRadio}
									/>
								</div>
								<button className="apli-btn btn-x-large" onClick={(e) => applyToJob(e)}> Submit </button>
							</div>
							:
							<div className="text-center p-3">
								<img style={{"transform": "scale(0.7"}} src={incomplete_prfile} alt="profile-incomplete" />
								<h6>Just one step away!!</h6>
								<p className="fs-14">Complete your profile and then apply for this job.</p>
								<button className="apli-btn btn-x-large mt-4" onClick={() => window.location.href = "/candidate/profile"}>Go to Profile</button>
							</div>
					}
				</Modal.Body>
			</Modal>

			{virtualExperienceData !== null &&
				<div className="ve-details-main-container">
					<div className="ve-details-bg-container text-light mb-5">
						<div className="row ve-details-upper-container">
							<div className="col-md-6 col-12 mt-5">
								<h1 className="fw-600 fs-36 lh-60">{virtualExperienceData.role} Virtual Experience</h1>
								<h4 className="fs-14 fw-400 lh-33 text-justify">{virtualExperienceData.short_description}</h4>
								<div className="d-flex mt-4">
									<p className="fs-14 fw-400 lh-33">{virtualExperienceData.company_name}</p>
									<p className="fs-14 fw-400 lh-33 ml-5 px-4"><i className="far fa-clock"></i>&nbsp;{virtualExperienceData.duration}</p>
								</div>
							</div>
							<div className="col-md-5 col-12 d-flex flex-column justify-content-center align-items-center pt-4 mt-4">

								{props.enrolled ?
									<>
										<button className="fw-600 ve-details-enroll-button px-5"><i className="fas fa-check px-2"></i>Enrolled</button>
									</>
									: (user.user_email ? <><button onClick={enrollCourse} className="fw-600 ve-details-enroll-button px-5"><span className="">Enroll Now<i className="fas fa-long-arrow-alt-right px-2"></i></span><br /></button> </>
										:
										<>
											<button onClick={() => window.location.replace(`${baseUrl}/accounts/signup/candidate/email/verify`)} className="fw-600 ve-details-enroll-button px-5">Enroll Now<i className="fas fa-long-arrow-alt-right px-2"></i></button>
										</>
									)
								}
								<p className="fs-12">To view the mission</p>

							</div>
						</div>
						<div className="p-3 pt-4 pb-4">
							<div className="row">
								<div className="col-md-3 col-6 d-flex justify-content-center align-items-center fw-500 fs-14 mb-4"><i className="fas fa-book"></i>&nbsp;&nbsp;Get Certificate</div>
								<div className="col-md-3 col-6 d-flex justify-content-center align-items-center fw-500 fs-14 mb-4"><i className="fas fa-briefcase"></i>&nbsp;&nbsp;Job Opportunity</div>
								<div className="col-md-3 col-6 d-flex justify-content-center align-items-center fw-500 fs-14 mb-4"><i className="fas fa-book"></i>&nbsp;&nbsp;Recognition</div>
								<div className="col-md-3 col-6 d-flex justify-content-center align-items-center fw-500 fs-14 mb-4"><i className="fas fa-star"></i>&nbsp;&nbsp;Skill Building</div>
							</div>
						</div>
					</div>

					{/* 					<div className="ve-individual-container"> */}
					{/* 						<h4 className="fs-18 fw-600 mb-5 ve-details-desc-heading text-justify">Companies with vacant positions for this role</h4> */}
					{/* 						<div className="row ve-details-org-img-container justify-content-center"> */}
					{/* 							<div className="col-md-2 col-4 d-flex justify-content-center align-items-center ve-details-org-img-div"><img className="ve-details-org-img" src={iit_logo} alt="organization" /></div> */}
					{/* 							<div className="col-md-2 col-4 d-flex justify-content-center align-items-center ve-details-org-img-div"><img className="ve-details-org-img" src={jpmorgan_logo} alt="organization" /></div> */}
					{/* 							<div className="col-md-2 col-4 d-flex justify-content-center align-items-center ve-details-org-img-div"><img className="ve-details-org-img" src={nmims_logo} alt="organization" /></div> */}
					{/* 							<div className="col-md-2 col-4 d-flex justify-content-center align-items-center ve-details-org-img-div"><img className="ve-details-org-img" src={samsung_logo} alt="organization" /></div> */}
					{/* 							<div className="col-md-2 col-4 d-flex justify-content-center align-items-center ve-details-org-img-div"><img className="ve-details-org-img" src={iitb_logo} alt="organization" /></div> */}
					{/* 							<div className="col-md-2 col-4 d-flex justify-content-center align-items-center ve-details-org-img-div"><img className="ve-details-org-img" src={amazon_logo} alt="organization" /></div> */}
					{/* 						</div> */}
					{/* 					</div> */}

					<div className="row ve-individual-container mt-5">
						<p className="fw-400 text-justify ve-details-desc-para" dangerouslySetInnerHTML={{ __html: virtualExperienceData.description }} />
					</div>
					<div className="row ve-individual-container mt-5">
						<h4 className="fw-600 ve-details-desc-heading text-justify">Why to do this course?</h4>
						<div className="col-md-8 order-md-1 order-2 col-12 ve-details-desc-para">
							<p className="fw-400 text-justify" dangerouslySetInnerHTML={{ __html: virtualExperienceData.why_to_do }} />
						</div>
						<div className="col-md-4 order-md-2 order-1 col-12 d-flex justify-content-center align-items-center">
							<img className="desc-img" src={description_img} alt="description_image" />
						</div>
					</div>

					{/* 					<div className="position-relative ve-individual-container"> */}
					{/* 						<h4 className="fw-600 ve-details-desc-heading text-justify">Featured Reviews</h4> */}
					{/* 						<Carousel > */}
					{/* 							<Carousel.Item> */}
					{/* 								<FeedbackCarouselItem /> */}
					{/* 							</Carousel.Item> */}
					{/* 							<Carousel.Item> */}
					{/* 								<FeedbackCarouselItem /> */}
					{/* 							</Carousel.Item> */}
					{/* 							<Carousel.Item> */}
					{/* 								<FeedbackCarouselItem /> */}
					{/* 							</Carousel.Item> */}
					{/* 						</Carousel> */}
					{/* 					</div> */}

					<div className="ve-individual-container">
						<h4 className="fw-600 mb-5 ve-details-desc-heading text-justify">Modules</h4>
						<div className="ve-details-modules-container">
							{virtualExperienceData.tasks.map((task, index) => {
								return (
									<div key={index}>
										<Task isEnrolled={props.enrolled} taskNo={index+1} taskId={task.id} taskName={task.task_name} taskDesc={task.task_description} hashTags={task.hashtags} isSubmitted={task.is_task_completed} img={task.task_image_url} />
										{index < veTaskLength - 1 &&
											<hr className="light-text" />}
									</div>)
							})}
						</div>
					</div>

					<div className="d-flex justify-content-center p-5 pt-2">
						{!props.enrolled ?
							<button onClick={enrollCourse} className="fw-600 apli-btn px-5"><span className="">Enroll Now<i className="fas fa-long-arrow-alt-right px-2"></i></span></button>
							: (
								virtualExperienceData.completed_ve && virtualExperienceData.show_certificate === 0 ?
									<h6 className="text-center">Your submission is under review. Once completed, you can download the certificate.</h6>
									: virtualExperienceData.completed_ve && virtualExperienceData.show_certificate === 1 ?
										<button className="apli-btn bg-navy-blue"><a style={{ textDecoration: "none", color: "white" }} href={virtualExperienceData.certificate_url} target="_blank">Get Certificate</a></button>
										:
										<button className="apli-btn"><i className="fas fa-lock"></i> Get Certificate</button>
							)
						}
					</div>
					{viewFooter && <Footer />}
				</div>}
		</>
	)
}


export default VirtualExperienceDetails