import React, { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import "./Modals.css"
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import Cookies from 'js-cookie'
import { loadingContext, messageContext } from '../../App';

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function EducationModal(props) {
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    let currentEducation = props.education[0]
    // State to store whether the current degree is active or not
    const [isActive, setIsActive] = useState(false)

    const [eduData, setEduData] = useState({
        'degrees': {
            
        },
        'XII': {
            'institute': '',
            'specialization': 'science',
            'board': 'cbse',
            'score': '',
            'start': '',
            'end': ''
        },
        'X': {
            'institute': '',
            'specialization': 'all_subjects',
            'board': 'cbse',
            'score': '',
            'start': '',
            'end': ''
        },
        'diploma': {

        }
    })

    const addEducation = () => {
        loadingHandler.setIsLoading(true)
        props.setOpen({ ...props.open, 'educationModalOpen': false })
        eduData.degrees[currentEducation.type] = {
            'institute': currentEducation.institute,
            'specialization': currentEducation.specialization,
            'board': currentEducation.board,
            'score': currentEducation.score,
            'start': currentEducation.start,
            'end': currentEducation.end
        }
        let EducationData = new FormData()
        let d = JSON.stringify({ 'edit_type': 'add_edu', 'all_edu_details': eduData })
        EducationData.append('data', d)
        axios.post(`${ baseUrl }/candidate/update_and_load_resume/`,
            EducationData,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                withCredentials: true
            }
        ).then(r => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "success",
                displayText: "Your Education is Updated!"
            })
            window.location.reload()
        }).catch(e => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Internal Server Error"
            })
        })
    }

    return (
        <div>
            <Modal show={props.open.educationModalOpen} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                <Modal.Body bsPrefix="modal-body profile-modal-content">
                    <button onClick={() => { props.setOpen({ ...props.open, 'educationModalOpen': false }) }} className="edit-modal-close"><i className="fas fa-times"></i></button>
                    <h4 className="text-center fs-18 fw-500 mt-4 mb-5 text-navy-blue">Add Education</h4>

                    {/* <h5 className="edit-modal-social-link-label mt-3 text-navy-blue">Degree Details</h5>
                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-type">Degree Type</label>
                                <input value={degType} onChange={(event) => setDegType(event.target.value)} className="edit-modal-input-box mb-0" id="education-modal-degree-type" type="text" />
                            </div>
                        </div>
                    </div>
                    {eduData.degrees.degree &&
                        <>
                            <div className="row">
                                <div className="col-md-6 col-12 edit-modal-child-container">
                                    <div className="d-flex flex-column mb-3">
                                        <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-institute">Institute Name</label>
                                        <input value={eduData.degrees.degree.institute} onChange={(event) => setEduData({ ...eduData, 'degrees': { ...eduData.degrees, 'degree': { ...eduData.degrees.degree, 'institute': event.target.value } } })} className="edit-modal-input-box mb-0" id="education-modal-degree-institute" type="text" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 edit-modal-child-container">
                                    <div className="d-flex flex-column mb-3">
                                        <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-field">Field of Study</label>
                                        <select value={eduData.degrees.degree.specialization} onChange={(event) => setEduData({ ...eduData, 'degrees': { ...eduData.degrees, 'degree': { ...eduData.degrees.degree, 'specialization': event.target.value } } })} className="edit-modal-input-box mb-0" id="education-modal-degree-field">
                                            <option value="computer_science">Computer Science</option>
                                            <option value="information_technology">Information Technology</option>
                                            <option value="mechanical">Mechanical</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12 edit-modal-child-container">
                                    <div className="d-flex flex-column mb-3">
                                        <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-board">Board</label>
                                        <input value={eduData.degrees.degree.board} onChange={(event) => setEduData({ ...eduData, 'degrees': { ...eduData.degrees, 'degree': { ...eduData.degrees.degree, 'board': event.target.value } } })} className="edit-modal-input-box mb-0" id="education-modal-degree-board" type="text" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 edit-modal-child-container">
                                    <div className="d-flex flex-column mb-3">
                                        <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-score">Score/CGPA</label>
                                        <input value={eduData.degrees.degree.score} onChange={(event) => setEduData({ ...eduData, 'degrees': { ...eduData.degrees, 'degree': { ...eduData.degrees.degree, 'score': event.target.value } } })} className="edit-modal-input-box mb-0" id="education-modal-degree-score" type="number" placeholder="9.2" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12 edit-modal-child-container">
                                    <div className="d-flex flex-column mb-3">
                                        <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-from">From</label>
                                        <input value={eduData.degrees.degree.start} onChange={(event) => setEduData({ ...eduData, 'degrees': { ...eduData.degrees, 'degree': { ...eduData.degrees.degree, 'start': event.target.value } } })} className="edit-modal-input-box mb-0" id="education-modal-degree-from" type="month" placeholder="01/01/1997" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 edit-modal-child-container">
                                    <div className="d-flex flex-column mb-3">
                                        <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-to">To</label>
                                        <input disabled={isActive} value={eduData.degrees.degree.end} onChange={(event) => setEduData({ ...eduData, 'degrees': { ...eduData.degrees, 'degree': { ...eduData.degrees.degree, 'end': event.target.value } } })} min={eduData.degrees.degree.start} className="edit-modal-input-box mb-3" id="education-modal-degree-to" type="month" placeholder="01/01/1997" />
                                        <Form.Check checked={isActive} onChange={(event) => setIsActive(event.target.checked)} bsPrefix="profile-add-modal-check"
                                            type="switch"
                                            id="custom-switch"
                                            label="I Currently Study Here"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    } */}

                    <h5 className="edit-modal-social-link-label mt-3 text-navy-blue">12th Standard</h5>
                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-12-institute">Institute Name</label>
                                <input value={eduData.XII.institute} onChange={(event) => setEduData({ ...eduData, 'XII': { ...eduData.XII, 'institute': event.target.value } })} className="edit-modal-input-box mb-0" id="education-modal-12-institute" type="text" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-12-field">Field of Study</label>
                                <select value={eduData.XII.specialization} onChange={(event) => setEduData({ ...eduData, 'XII': { ...eduData.XII, 'specialization': event.target.value } })} className="edit-modal-input-box mb-0" id="education-modal-degree-field">
                                    <option value="science">Science</option>
                                    <option value="commerce">Commerce</option>
                                    <option value="arts">Arts</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-12-board">Board</label>
                                <select value={eduData.XII.board} onChange={(event) => setEduData({ ...eduData, 'XII': { ...eduData.XII, 'board': event.target.value } })} className="edit-modal-input-box mb-0" id="education-modal-12-board">
                                    <option value="cbse">CBSE Board</option>
                                    <option value="icse">ICSE Board</option>
                                    <option value="state">State Board</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-12-score">Score/CGPA</label>
                                <input value={eduData.XII.score} onChange={(event) => setEduData({ ...eduData, 'XII': { ...eduData.XII, 'score': event.target.value } })} className="edit-modal-input-box mb-0" id="education-modal-12-score" type="number" placeholder="9.2" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-12-from">From</label>
                                <input value={eduData.XII.start} onChange={(event) => setEduData({ ...eduData, 'XII': { ...eduData.XII, 'start': event.target.value } })} className="edit-modal-input-box mb-0" id="education-modal-12-from" type="month" placeholder="01/01/1997" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-12-to">To</label>
                                <input value={eduData.XII.end} onChange={(event) => setEduData({ ...eduData, 'XII': { ...eduData.XII, 'end': event.target.value } })} min={eduData.XII.start} className="edit-modal-input-box mb-3" id="education-modal-12-to" type="month" placeholder="01/01/1997" />
                            </div>
                        </div>
                    </div>

                    <h5 className="edit-modal-social-link-label mt-3 text-navy-blue">10th Standard</h5>
                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-10-institute">Institute Name</label>
                                <input value={eduData.X.institute} onChange={(event) => setEduData({ ...eduData, 'X': { ...eduData.X, 'institute': event.target.value } })} className="edit-modal-input-box mb-0" id="education-modal-10-institute" type="text" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-10-field">Field of Study</label>
                                <select value={eduData.X.specialization} onChange={(event) => setEduData({ ...eduData, 'X': { ...eduData.X, 'specialization': event.target.value } })} className="edit-modal-input-box mb-0" id="education-modal-degree-field">
                                    <option value="all_subjects">All Subjects</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-10-board">Board</label>
                                <select value={eduData.X.board} onChange={(event) => setEduData({ ...eduData, 'X': { ...eduData.X, 'board': event.target.value } })} className="edit-modal-input-box mb-0" id="education-modal-10-board">
                                    <option value="cbse">CBSE Board</option>
                                    <option value="icse">ICSE Board</option>
                                    <option value="state">State Board</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-10-score">Score/CGPA</label>
                                <input value={eduData.X.score} onChange={(event) => setEduData({ ...eduData, 'X': { ...eduData.X, 'score': event.target.value } })} className="edit-modal-input-box mb-0" id="education-modal-10-score" type="number" placeholder="9.2" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-10-from">From</label>
                                <input value={eduData.X.start} onChange={(event) => setEduData({ ...eduData, 'X': { ...eduData.X, 'start': event.target.value } })} className="edit-modal-input-box mb-0" id="education-modal-10-from" type="month" placeholder="01/01/1997" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-10-to">To</label>
                                <input value={eduData.X.end} onChange={(event) => setEduData({ ...eduData, 'X': { ...eduData.X, 'end': event.target.value } })} min={eduData.X.start} className="edit-modal-input-box mb-3" id="education-modal-10-to" type="month" placeholder="01/01/1997" />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center p-4">
                        <button onClick={addEducation} className="apli-btn edit-modal-save-button">Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EducationModal