import { useEffect, useContext, useState } from 'react';
import community_front from '../Media/Community-front.png'
import { activeLinkContext } from '../App';
import '../App.css'
import './Community.css'
import Footer from '../footer/Footer'

function Community() {
    const activeLinkHandler = useContext(activeLinkContext)
    const [showFooter, setShowFooter] = useState(false)

    useEffect(() => {
        activeLinkHandler.updateActiveLink({ type: 'community' })
        setShowFooter(true)
    }, [])

    return (
        <>
            <center className="mt-5 mx-auto text-navy-blue px-4 community-access-container" style={{ maxWidth: "480px" }}>
                <img src={community_front} className="w-100" alt="Community" />
                <p className="fw-500 fs-18 mt-4 community-access-desc">Our Developers are still buliding this part, hold on to get view of community clubs.</p>
                <p className="fw-700 fs-22 mt-5 community-access-link-desc"><span><a className="community-access-link" href="https://discord.com/channels/879951970658582548/885145545855860756" target="_blank">Click Here </a></span> to access our beta community</p>
            </center>;
            {showFooter && <Footer />}
        </>
    )
}

export default Community;
