import { useState } from 'react'
import './Skills.css'
import SkillGroup from './SkillGroup'

function Skills({ skills, setSkills, isPublicProfile }) {
    const [showAll, setShowAll] = useState(false)

    return (
        <>
            <div>
                {showAll ?
                    <>
                        {
                            skills.map((skillGroup, index) => {
                                return (
                                    <div className="mt-3 border position-relative">
                                        <SkillGroup skillGroup={skillGroup} skills={skills} index={index} setSkills={setSkills} isPublicProfile={isPublicProfile} />
                                    </div>
                                )
                            })
                        }
                        <div className="d-flex justify-content-center mt-3" role="button" onClick={() => { setShowAll(false) }}><i className="fas fa-angle-up fa-lg"></i></div>
                    </>
                    :
                    <>
                        <div className="mt-3 border position-relative">
                            <SkillGroup skillGroup={skills[0]} skills={skills} index={0} setSkills={setSkills} isPublicProfile={isPublicProfile} />
                        </div>
                        <div className="d-flex justify-content-center mt-3" role="button" onClick={() => { setShowAll(true) }}><i className="fas fa-angle-down fa-lg"></i></div>
                    </>
                }
            </div>

        </>
    )
}
export default Skills