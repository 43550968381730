import { useEffect, useState, useReducer, useContext } from 'react';
import React from 'react';
import TabsAndFilter from './TabsAndFilter/TabsAndFilter';
import './Opportunities.css';
import oppBanner from './Media/opportunities_banner.png'
import { loadingContext, activeLinkContext } from '../App'
import Modal from 'react-bootstrap/Modal'

//this is to make sure that base url remains same when front is on different port
let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
	baseUrl = 'http://localhost:8000'


// This is for percentage Resume Completed data
export const percentResumeCompletedContext = React.createContext()

//this is for maintaining global state of allJobs
export const allJobsContext = React.createContext()
const jobsReducer = (state, action) => {
	let openJobs = state.openJobs
	let savedJobs = state.savedJobs
	let appliedJobs = state.appliedJobs
	let newJobState;
	switch (action.type) {
		case 'initialise':
			return action.newJobState
		case 'save':
			//remove from open jobs and add to saved jobs
			savedJobs[action.key] = openJobs[action.key]
			delete openJobs[action.key]
			newJobState = {
				openJobs: openJobs,
				savedJobs: savedJobs,
				appliedJobs: state.appliedJobs
			}
			return newJobState
		case 'unsave':
			//remove from saved jobs and add to open jobs
			openJobs[action.key] = savedJobs[action.key]
			delete savedJobs[action.key]
			newJobState = {
				openJobs: openJobs,
				savedJobs: savedJobs,
				appliedJobs: state.appliedJobs
			}
			return newJobState

		case 'apply':
			if (openJobs[action.key]) {
				appliedJobs[action.key] = openJobs[action.key]
				delete openJobs[action.key]
				newJobState = {
					openJobs: openJobs,
					savedJobs: savedJobs,
					appliedJobs: appliedJobs
				}
			} else if (savedJobs[action.key]) {
				appliedJobs[action.key] = savedJobs[action.key]
				delete savedJobs[action.key]
				newJobState = {
					openJobs: openJobs,
					savedJobs: savedJobs,
					appliedJobs: appliedJobs
				}
			}
			return newJobState

		default:
			return state
	}
}

function Opportunities() {
	let [allJobs, setAllJobs] = useReducer(jobsReducer, {
		openJobs: {},
		savedJobs: {},
		appliedJobs: {}
	})

	const [percentResumeCompleted, setPercentResumeCompleted] = useState(null)
	const handleLoading = useContext(loadingContext)
	const activeLinkHandler = useContext(activeLinkContext)

	// this is the static data which is loaded in filters, i.e list of locations, companies etc
	const [filterData, setFilterData] = useState({
		'Job Type': ['Job', 'Internship', 'Part-time'],
		'Field': [],
		'Location': [],
		'Company': [],
	})

	const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(false)

	const createFilterData = (allJobsData) => {
		let tempAllJobs = {
			openJobs: allJobsData.jobsss,
			savedJobs: allJobsData.saved,
			appliedJobs: allJobsData.comp_apps
		}
		let locations = new Set()
		let companies = new Set()

		for (const jobType in tempAllJobs) {
			for (const jobId in tempAllJobs[jobType]) {
				const tempJobDetails = tempAllJobs[jobType][jobId]
				tempJobDetails.place.split(', ').forEach(location => locations.add(location));
				companies.add(tempJobDetails.company_name)
			}
		}
		locations.delete('-')
		setFilterData({ ...filterData, 'Location': [...locations], 'Company': [...companies] })
	}

	//need to modify bcoz saved jobs are part of open jobs as well
	const modifyJobs = (allJobsData) => {
		let openJobs = allJobsData.jobsss
		let savedJobs = allJobsData.saved
		for (let key in savedJobs) {
			delete openJobs[key]
		}
		setAllJobs({
			type: 'initialise', newJobState: {
				openJobs: openJobs,
				savedJobs: allJobsData.saved,
				appliedJobs: allJobsData.comp_apps
			}
		})
	}

	// this useEffect is specifically for fetching jobs
	useEffect(() => {
		activeLinkHandler.updateActiveLink({ type: 'opportunities' })
		handleLoading.setIsLoading(true)
		fetch(`${baseUrl}/candidate/all_jobs`, {
			credentials: 'include',
		})
			.then(response => response.json())
			.then((allJobsData) => {
				setShowCompleteProfileModal(!allJobsData.access_to_jobs)
				setPercentResumeCompleted(allJobsData.resume_percent_completed)
				modifyJobs(allJobsData)
				createFilterData(allJobsData)
				handleLoading.setIsLoading(false)
			})
			.catch(e => {
				handleLoading.setIsLoading(false)
			})

	}, []);

	return (
		<>
			<Modal
				show={showCompleteProfileModal}
				size="md"
			>
				<Modal.Header >
					<Modal.Title >
						Jobs / Internships
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<h6 className="mt-3 px-3 text-navy-blue">To Access The Jobs Please Update Your Profile</h6>
					<ul>
						<li>Basic Info (along with your profile picture)</li>
						<li>Skills</li>
						<li>Education</li>
					</ul>
					<center>
						<button className="apli-btn mt-4" onClick={() => window.location.href = '/candidate/profile'}>Complete Profile  </button>
					</center>
				</Modal.Body>
			</Modal>

			<allJobsContext.Provider value={{ allJobs: allJobs, updateJobs: setAllJobs }}>
				<percentResumeCompletedContext.Provider value={{ percentResumeCompleted: percentResumeCompleted, isProfileCompleted: !showCompleteProfileModal }}>

					<div className="opportunities-banner-container">
						<img src={oppBanner} className="w-100" alt="opportunities-banner" />
						<div className="opportunities-banner-text">Opportunities</div>
					</div>
					<div className="opportunities-content-container">
						<TabsAndFilter allJobs={allJobs} filterData={filterData} />
					</div>
				</percentResumeCompletedContext.Provider>
			</allJobsContext.Provider>
		</>

	)
}

export default Opportunities;
