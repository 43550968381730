import React from 'react'
import './Task.css'
import { Link } from 'react-router-dom'

function Task(props) {
    return (
        <>
            <div className="row mb-5 mt-5">
                <div className="col-md-3 col-4">
                    <img className="task-module-img" src={props.img} alt="task image" />
                </div>
                <div className="col-md-9 col-8 row task-details-container">
                    <div className="col-md-9 col-12">
                        <h3 className="fw-500 task-item-heading">Mission {props.taskNo} : {props.taskName}</h3>
                        <p className="light-text task-item-para fw-400">{props.taskDesc}</p>
                        <div className="tags-container">
                            {props.hashTags.map((tag, index) => {
                                return (
                                    <span key={index} className="tags-items text-purple">#{Object.values(tag)}</span>
                                )
                            })}
                        </div>
                    </div>
                    {props.isEnrolled &&
                        <div className="col-md-3 col-12 mt-3">
                            {props.isSubmitted && <p className="task-completed-tag px-2"><i className="fas fa-check-circle"></i> Completed</p>}
                            <Link to={`${ props.taskId }`}>
                                {props.isSubmitted ?
                                    <button className="fw-600 apli-btn btn-large">View Task <i className="fas fa-long-arrow-alt-right"></i></button>
                                    :
                                    <button className="fw-600 apli-btn btn-large mt-3">Start Task <i className="fas fa-long-arrow-alt-right"></i></button>
                                }
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Task