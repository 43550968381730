import { useReducer, useEffect, useState, createContext } from 'react';
import axios from 'axios';
import { Backdrop, CircularProgress, Snackbar, Alert } from '@mui/material';
import './App.css';
import NavB from './nav/Nav';
import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Profile from './profile/Profile';
import Opportunities from './opportunities/Opportunities';
import VirtualExperiencesMain from './virtualExperiences/VirtualExperiencesMain';
import Community from './community/Community';
import Signin from './Signin';
import PublicProfile from './profile/PublicProfile';
import NotFound from './NotFound';
import VirtualExperienceDetails from './virtualExperiences/VirtualExperienceDetails/VirtualExperienceDetails';
import TaskDetails from './virtualExperiences/TaskDetails/TaskDetails'
import CandidateSignup from './accounts/CandidateSignup/CandidateSignup'
import Login from './accounts/Login'
import ForgetPassword from './accounts/ForgetPassword'


let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

// this is for maintaining global state of loader
export const loadingContext = React.createContext()
const loadingReducer = (state, action) => {
    return action;
}

// this is for showing message i.e success or error
export const messageContext = createContext()
const messageReducer = (state, action) => {
    if (action.showMessage) {
        return { showMessage: action.showMessage, type: action.type, displayText: action.displayText }
    } else {
        return { ...state, showMessage: false }
    }
}

export const userContext = createContext()
const userReducer = (state, action) => {
    return action;
}

// this is for setting color of active icon in navbar
export const activeLinkContext = createContext()
const activeLinkReducer = (state, action) => {
    let newActiveLinkState = state
    switch (action.type) {
        case 'virtualExperience':
            newActiveLinkState = {
                virtualExperience: true,
                opportunities: false,
                community: false,
                notification: false,
                profile: false
            }
            return newActiveLinkState
        case 'opportunities':
            newActiveLinkState = {
                virtualExperience: false,
                opportunities: true,
                community: false,
                notification: false,
                profile: false
            }
            return newActiveLinkState
        case 'community':
            newActiveLinkState = {
                virtualExperience: false,
                opportunities: false,
                community: true,
                notification: false,
                profile: false
            }
            return newActiveLinkState
        case 'profile':
            newActiveLinkState = {
                virtualExperience: false,
                opportunities: false,
                community: false,
                notification: false,
                profile: true
            }
            return newActiveLinkState
        default:
            return state
    }
}

function App() {
    const [loading, setLoading] = useReducer(loadingReducer, false)
    const [message, setMessage] = useReducer(messageReducer, {
        showMessage: false,
        type: "success",
        displayText: ""
    })
    const [user, setUser] = useReducer(userReducer, { 'user_email': null })

    let [activeLink, setActiveLink] = useReducer(activeLinkReducer, {
        virtualExperience: false,
        opportunities: false,
        community: false,
        notification: false,
        profile: false
    })

    useEffect(() => {
        axios.get(`${baseUrl}/fetch_user`, {
            withCredentials: true
        })
            .then(r => {
                setUser(r.data)
            })
            .catch(e => {

            })

    }, [])

    return (
        <>{
            user &&
            <activeLinkContext.Provider value={{ activeLink: activeLink, updateActiveLink: setActiveLink }}>
                <userContext.Provider value={{ user_email: user['user_email'] }}>
                    <loadingContext.Provider value={{ isLoading: loading, setIsLoading: setLoading }}  >
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200000 }}
                            open={loading}
                            transitionDuration={200}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <messageContext.Provider value={{ message: message, setMessage: setMessage }} >
                            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{ marginTop: "60px" }} open={message.showMessage} autoHideDuration={5000} onClose={() => setMessage({ showMessage: false })}>
                                <Alert severity={message.type} >
                                    {
                                        message.displayText
                                    }
                                </Alert>
                            </Snackbar>
                            <BrowserRouter>
                                {
                                    user.user_email && <NavB userDetails={user} />
                                }
                                <Routes>
                                    <Route path="candidate/virtual-experiences" exact element={<VirtualExperiencesMain />} />
                                    <Route path="candidate/virtual-experiences/:virtualExpId" element={<VirtualExperienceDetails enrolled={false} />} />
                                    <Route path="candidate/enrolled-virtual-experiences/:virtualExpId" element={<VirtualExperienceDetails enrolled={true} />} />
                                    <Route path="candidate/enrolled-virtual-experiences/:virtualExpId/:taskId" element={<TaskDetails />} />
                                    <Route path="candidate/signin" element={<Signin />} />
                                    <Route path="candidate/opportunities" element={<Opportunities />} />
                                    <Route path="candidate/community" element={<Community />} />
                                    <Route path="candidate/profile" element={<Profile />} />
                                    <Route path="candidate/profile/:encodedId" element={<PublicProfile />} />
                                    <Route path="accounts/signup/candidate/email/verify" element={<CandidateSignup />} />
                                    <Route path="accounts/login" element={<Login />} />
                                    <Route path="/forget-password" element={<ForgetPassword />} />
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </BrowserRouter>
                        </messageContext.Provider>
                    </loadingContext.Provider>
                </userContext.Provider>
            </activeLinkContext.Provider>
        }
        </>
    );
}

export default App;
