import React, { useState } from 'react'
import EditAwardModal from './EditAwardModal'

function Award({ award, isPublicProfile, awards, setAwards }) {
    const [editAward, setEditAward] = useState(false)
    return (
        <>
            <div className="row m-1 mb-4 p-4 border rounded-3 position-relative">
                {
                    !isPublicProfile &&
                    <span role="button" onClick={() => setEditAward(true)}>
                        <i className="fas fa-pen pen-edit-icon"></i>
                    </span>
                }
                <div className="col-3">
                    <i className="fas fa-award fa-3x"></i>
                </div>
                <div className="fs-14 col-9">
                    <p className="text-navy-blue fw-500 mb-2">{award.description}</p>
                    <span className="light-text fs-13">{award.date}</span>
                </div>
            </div>
            <EditAwardModal open={editAward} setOpen={setEditAward} award={award} awards={awards} setAwards={setAwards} />
        </>
    )
}

export default Award