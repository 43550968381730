import React, { useState } from 'react'
import EditExperienceModal from './EditExperienceModal'

function Experience({ experience, isPublicProfile, experiences, setExperiences }) {

    const [editExperience, setEditExperience] = useState(false)
    return (
        <>
            <div className="row m-1 mb-4 p-4 border rounded-3 position-relative">
                {
                    !isPublicProfile &&
                    <span role="button" onClick={() => setEditExperience(true)}>
                        <i className="fas fa-pen pen-edit-icon"></i>
                    </span>
                }
                <div className="col-3">
                    <i className="far fa-building fa-4x"></i>
                </div>
                <div className="fs-14 col-9">
                    <p className="text-navy-blue fw-500 mb-2">{experience.designation}</p>
                    <p className="text-navy-blue mb-2">{experience.company}</p>
                    <span className="light-text fs-13">{experience.from} - { (experience.to === null || experience.to.length === 0) ? "Present" : String(experience.to) }</span>
                    {/* <span className="text-navy-blue fs-13 ms-4">6 months</span> */}
                </div>
            </div>
            <EditExperienceModal open={editExperience} setOpen={setEditExperience} experience={experience} experiences={experiences} setExperiences={setExperiences} />
        </>
    )
}

export default Experience