import React, {useState} from 'react';

function Signin() {
    const [signInCredentials, setSignInCredentails] = useState({'inputEmail': '', 'inputPassword': ''})

    async function POST(endpoint, formdata) {
        const response = await fetch(`http://localhost:8000/${endpoint}/`,{
            headers: {
                'X-CSRFToken': await getCSRF(),
            },
            credentials: 'include',
            method: 'POST',
            body : formdata
        })
        const data = await response.json()
        return data
    }

    const getCSRF = async () => {
        const response = await fetch(`http://localhost:8000/csrf/`, {
            credentials: 'include',
        });
        const data = await response.json();
        let csrftoken = data.csrfToken;
        return csrftoken
    }

    const handleSubmit = async(e) => {
        const formData = new FormData();
        formData.append('inputEmail', signInCredentials.inputEmail)
        formData.append('inputPassword', signInCredentials.inputPassword)
        e.preventDefault()
        await POST('accounts/login', formData)
    }
    return <>
        <input type="text" placeholder="Username" value={signInCredentials.inputEmail} onChange={e => setSignInCredentails({...signInCredentials, 'inputEmail': e.target.value})} />
        <input type="password" placeholder="Password" value={signInCredentials.inputPassword} onChange={e => setSignInCredentails({...signInCredentials, 'inputPassword': e.target.value})} />
        <button onClick={handleSubmit}>SignIn</button>
    </>;
}

export default Signin;
