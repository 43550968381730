import React from 'react'
import './VirtualExperience.css';

function VirtualExperience(props) {
    return (
        <>
            <div className="virtual-experience-container">
                <div className="col-md-6">
                    <img src={props.virtualExpDetails.banner_img} className="w-100 h-100 rounded" alt="ve-image" style={{ objectFit: "cover" }} />
                </div>
                <div className="col-md-6 pt-4 ps-3 p-2">
                    <p className="text-navy-blue fs-14 fw-500">{props.virtualExpDetails.role}</p>
                    <p className="light-text fs-13 mb-2">{props.virtualExpDetails.company_name}</p>
                    <p className="text-navy-blue fs-13"><i className="far fa-clock"></i> {props.virtualExpDetails.duration}</p>
                </div>
            </div>
        </>
    )
}

export default VirtualExperience