import { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import "../Modals/Modals.css"
import axios from 'axios'
import Cookies from 'js-cookie'
import { loadingContext, messageContext } from '../../App';

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function EditAwardModal(props) {

    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const [editedAward, setEditedAward] = useState(props.award)
    const today = new Date();
    const date = String(today.getFullYear()) + "-" + String(today.getMonth() + 1).padStart(2, '0')
    const handleDateOnChange = (event) => {
        setEditedAward({ ...editedAward, 'date': event.target.value })
    }

    const updateAward = () => {
        if (editedAward.description.length > 500 || editedAward.description.length < 1 || editedAward.date.length < 1) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter valid input"
            })
            return;
        }
        props.setOpen(false)
        let awardData = props.award
        for (let key in editedAward) {
            awardData[key] = editedAward[key]
        }
        loadingHandler.setIsLoading(true)
        let AwardData = new FormData()
        let d = JSON.stringify({ 'edit_type': 'add_award', 'awards_data': props.awards })
        AwardData.append('data', d)
        axios.post(`${ baseUrl }/candidate/update_and_load_resume/`,
            AwardData,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                withCredentials: true
            }
        ).then(r => {
            props.setAwards(props.awards)
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "success",
                displayText: "Your Award is Edited"
            })
        }).catch(e => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Internal Server Error"
            })
        })
    }

    return (
        <div>
            <Modal show={props.open} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                <Modal.Body bsPrefix="modal-body profile-modal-content">
                    <button onClick={() => props.setOpen(false)} className="edit-modal-close"><i className="fas fa-times"></i></button>
                    <h4 className="text-center fs-18 fw-500 mt-5 mb-5 text-navy-blue">Edit Awards</h4>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="award-modal-desc">Description</label>
                                <input value={editedAward.description} onChange={(event) => { setEditedAward({ ...editedAward, 'description': event.target.value }) }} className="edit-modal-input-box" id="award-modal-skill-desc" type="text" />
                            </div>
                        </div>

                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="award-modal-issue-date">Start Date</label>
                                <input value={editedAward.date} max={date} onChange={handleDateOnChange} className="edit-modal-input-box" id="award-modal-issue-date" type="month" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center p-4 mt-2">
                        <button onClick={updateAward} className="apli-btn modal-save-button">Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditAwardModal