import React, { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import "../Modals/Modals.css"
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import Cookies from 'js-cookie'
import { loadingContext, messageContext } from '../../App';

function EditEducation(props) {
    const [editedEducation, setEditedEducation] = useState(props.education)
    const [isActive, setIsActive] = useState((!editedEducation.end))
    const [educationData, setEducationData] = useState({
        'degrees': {},
        'XII': props.educations[props.educations.length - 2],
        'X': props.educations[props.educations.length - 1],
        'diploma': {}
    })

    const updateEducation = () => {
        props.setOpen(false)
    }

    return (
        <div>
            {editedEducation &&
                <Modal show={props.open} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                    <Modal.Body bsPrefix="modal-body profile-modal-content">
                        <button onClick={() => { props.setOpen(false) }} className="edit-modal-close"><i className="fas fa-times"></i></button>
                        <h4 className="text-center fs-18 fw-500 mt-4 mb-5 text-navy-blue">Edit Education Details</h4>

                        <div className="row">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-institute">Institute Name</label>
                                    <input value={editedEducation.institute} onChange={(event) => setEditedEducation({ ...editedEducation, 'institute': event.target.value })} className="edit-modal-input-box mb-0" id="education-modal-degree-institute" type="text" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-field">Field of Study</label>
                                    <select value={editedEducation.specialization} onChange={(event) => setEditedEducation({ ...editedEducation, 'specialization': event.target.value })} className="edit-modal-input-box mb-0" id="education-modal-degree-field">
                                        <option value="computer_science">Computer Science</option>
                                        <option value="information_technology">Information Technology</option>
                                        <option value="mechanical">Mechanical</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-board">Board</label>
                                    <input value={editedEducation.board} onChange={(event) => setEditedEducation({ ...editedEducation, 'board': event.target.value })} className="edit-modal-input-box mb-0" id="education-modal-degree-board" type="text" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-score">Score/CGPA</label>
                                    <input value={editedEducation.score} onChange={(event) => setEditedEducation({ ...editedEducation, 'score': event.target.value })} className="edit-modal-input-box mb-0" id="education-modal-degree-score" type="number" placeholder="9.2" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-from">From</label>
                                    {
                                        typeof (editedEducation.start) === 'number' ?
                                            <input value={editedEducation.start} onChange={(event) => setEditedEducation({ ...editedEducation, 'start': event.target.value })} className="edit-modal-input-box mb-0" id="education-modal-degree-from" type="number" />
                                            :
                                            <input value={editedEducation.start.split("-", 2).join("-")} onChange={(event) => setEditedEducation({ ...editedEducation, 'start': event.target.value })} className="edit-modal-input-box mb-0" id="education-modal-degree-from" type="month" />
                                    }

                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-to">To</label>
                                    {
                                        typeof (editedEducation.end) === 'number' ?
                                            <input disabled={isActive} value={editedEducation.end && editedEducation.end} onChange={(event) => setEditedEducation({ ...editedEducation, 'end': event.target.value })} min={editedEducation.start} className="edit-modal-input-box mb-3" id="education-modal-degree-to" type="number" />
                                            :
                                            <input disabled={isActive} value={editedEducation.end && editedEducation.end.split("-", 2).join("-")} onChange={(event) => setEditedEducation({ ...editedEducation, 'end': event.target.value })} min={editedEducation.start} className="edit-modal-input-box mb-3" id="education-modal-degree-to" type="month" />
                                    }

{/*                                     <Form.Check checked={isActive} onChange={(event) => setIsActive(event.target.checked)} bsPrefix="profile-add-modal-check" */}
{/*                                         type="switch" */}
{/*                                         id="custom-switch" */}
{/*                                         label="I Currently Study Here" */}
{/*                                     /> */}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center p-4">
                            <button onClick={updateEducation} className="apli-btn edit-modal-save-button">Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </div>
    )
}

export default EditEducation