import { React, useEffect, useState, useContext, createContext } from 'react';
import { Badge, Drawer, List, ListItem, ListItemText } from '@mui/material';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Link } from "react-router-dom";
import navApliBrand from './apli_new_logo.png'
import virtual_experience from './virtual-experience.png'
import virtual_experience_active from './virtual-experience-active.png'
import opportunities from './opportunities.png'
import opportunities_active from './opportunities-active.png'
import navCommunity from './nav_community.png'
import navCommunity_active from './community-active.png'
import notification from './Notification.png'
import profile_img from './nav-profile.png'
import { Dropdown } from 'react-bootstrap';
import './nav.css';
import NotificationDropdown from '../opportunities/NotificationDropdown/NotificationDropdown';
import Cookies from 'js-cookie'
import axios from 'axios'
import { loadingContext, messageContext, activeLinkContext } from '../App'

const searchData = [
	{ community: 'community' }
]

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
	baseUrl = 'http://localhost:8000'

function Nav({ userDetails }) {
	const loadingHandler = useContext(loadingContext)
	const messageHandler = useContext(messageContext)
	const activeLinkHandler = useContext(activeLinkContext)
	const [isPhoneMenuOpen, setPhoneMenu] = useState(false)
	const [notificationsData, setNotificationsData] = useState(null)
	const [showNavBar, setShowNavBar] = useState(true)

	useEffect(() => {
		fetch(`${baseUrl}/candidate/candidate_notifications`, { credentials: 'include', })
			.then(response => response.json())
			.then((data) => {
				setNotificationsData(data)
			})

	}, [])


	useEffect(() => {
		let currentPath = window.location.pathname.split('/')
		if (currentPath.length == 5 && currentPath[2] === 'enrolled-virtual-experiences' && currentPath[4].length > 0)
			setShowNavBar(false)
		else
			setShowNavBar(true)
	}, [window.location.pathname])

	const logout = () => {
		loadingHandler.setIsLoading(true)
		axios.get(`${baseUrl}/accounts/logout/`,
			{
				headers: {
					'X-CSRFToken': Cookies.get('csrftoken'),
				},
				withCredentials: true
			}
		).then(r => {
			window.location.replace(baseUrl)
		})
			.catch(error => {
				messageHandler.setMessage({
					showMessage: true,
					type: "warning",
					displayText: "Internal Server Error"
				})
			})
	}
	const links = {
		'': " ",
		'Virtual Experience': "/candidate/virtual-experiences",
		'Opportunities': "/candidate/opportunities",
		'Community': "/candidate/community",
		'Notifications': ''
	}

	const copyToClipboard = () => {
		window.navigator.clipboard.writeText(userDetails.public_profile_url)
		messageHandler.setMessage({
			showMessage: true,
			type: "success",
			displayText: "You profile link is copied to clipboard!"
		})
	}

	const mobileMenu = (
		<List>
			{['', 'Virtual Experience', 'Opportunities', 'Community', 'Log out'].map((text, index) => (
				text === 'Log out' ?
					<ListItem button className="nav-text-container" key={index} onClick={logout}>
						<ListItemText primary={text} />
					</ListItem>
					:
					<Link key={index} className="nav-text-container" to={links[text]}>
						<ListItem button>
							<ListItemText primary={text} />
						</ListItem>
					</Link>
			))}
		</List>
	)
	return (
		<>
		{
			showNavBar &&

			
			<div className="navbar-main bg-white">
				<div className="nav-burger">
					<button className="burger-btn" sx={{ p: 0 }} onClick={() => setPhoneMenu(!isPhoneMenuOpen)}>
						<i className="fas fa-bars nav-profile"></i>
						<Drawer
							anchor='left'
							open={isPhoneMenuOpen}
							onClose={() => setPhoneMenu(false)}
						>
							{mobileMenu}
						</Drawer>
					</button>
				</div>
				<div className="nav-brand-container">
					<Link to="/candidate/virtual-experiences">
						<img src={navApliBrand} alt="apli-brand" className='h-100'/>
					</Link>
				</div>
				{/* <div className="nav-search-container">
					<Autocomplete
						size='small'
						freeSolo
						id="free-solo-2-demo"
						disableClearable
						options={searchData.map((option) => option.title)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Search"
								InputProps={{
									...params.InputProps,
									type: 'search',
								}}
							/>
						)}
					/>
				</div> */}
				<div className="nav-content-container">
					<div className="nav-content">
						<Link className="nav-text-container" to="/candidate/virtual-experiences">
							{activeLinkHandler.activeLink.virtualExperience ?
								<>
									<img src={virtual_experience_active} alt="discover" />
									<h6 style={{ color: "#2F44FF" }} className="nav-text">Virtual Experiences</h6>
								</>
								:
								<>
									<img src={virtual_experience} alt="virtual_experience" />
									<h6 className="nav-text">Virtual Experiences</h6>
								</>
							}

						</Link>
					</div>
					<div className="nav-content">
						<Link className="nav-text-container" to="/candidate/opportunities">
							{activeLinkHandler.activeLink.opportunities ?
								<>
									<img src={opportunities_active} alt="opportunities" />
									<h6 style={{ color: "#2F44FF" }} className="nav-text">Opportunities</h6>
								</>
								:
								<>
									<img src={opportunities} alt="opportunities" />
									<h6 className="nav-text">Opportunities</h6>
								</>
							}
						</Link>
					</div>
					<div className="nav-content">
						<Link className="nav-text-container" to="/candidate/community">
							{activeLinkHandler.activeLink.community ?
								<>
									<img src={navCommunity_active} alt="community" />
									<h6 style={{ color: "#2F44FF" }} className="nav-text">Community</h6>
								</>
								:
								<>
									<img src={navCommunity} alt="community" />
									<h6 className="nav-text">Community</h6>
								</>
							}
						</Link>
					</div>
				</div>
				<div className="nav-notification-container">
					<Dropdown>
						<Dropdown.Toggle bsPrefix="dropdown-toggle-icon" childBsPrefix="dropdown-toggle-icon">
							<img src={notification} alt="notification" />
							<h6 className="nav-text">Notifications</h6>
						</Dropdown.Toggle>
						<Dropdown.Menu bsPrefix="dropdown-menu dropdown-menu-custom">
							<NotificationDropdown data={notificationsData} />
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="nav-profile-container mt-2">
					<Dropdown>
						<Dropdown.Toggle bsPrefix="dropdown-toggle-icon border-0" childBsPrefix="dropdown-toggle-icon" id="dropdown-basic">
							<>
								<div className={` ${activeLinkHandler.activeLink.profile ? 'user-img-border' : ''} text-center`}> <img src={userDetails.profile_picture} alt="profile" className="nav-profile-img" /></div>
								<h6 className={` ${activeLinkHandler.activeLink.profile ? 'text-purple' : ''} nav-text`}>Profile</h6>
							</>
						</Dropdown.Toggle>

						<Dropdown.Menu bsPrefix="dropdown-menu ">
							<div className="profile-dropdown-menu text-navy-blue">
								<div className="row">
									<div className="col-4">
										<img src={userDetails.profile_picture} alt="profile" style={{ width: "70px", height: "70px" }} className="nav-profile-img" />
									</div>
									<div className="col-8 ps-3 pe-3">
										<h6 className="text-navy-blue fw-600">{userDetails.user_name}</h6>
										<Link className="nav-text" to="/candidate/profile">
											<button className="apli-btn bg-navy-blue">View Profile</button>
										</Link>
										<ProgressBar className="mt-4" style={{ height: "5px" }} now={userDetails.percent_profile_completed} />
										<p className="fs-13 mt-1">{userDetails.percent_profile_completed}% complete profile</p>
									</div>
								</div>
								<div className="row align-items-center mt-3" onClick={copyToClipboard} role="button">
									<div className="col-3 text-center fs-22">
										<i className="fas fa-share-alt"></i>
									</div>
									<div className="col-9 fw-500 fs-14">
										Share Profile
									</div>
								</div>
								<div className="row align-items-center mt-3" onClick={logout} role="button">
									<div className="col-3 text-center fs-22">
										<i className="fas fa-sign-out-alt"></i>
									</div>
									<div className="col-9 fw-500 fs-14">
										Logout
									</div>
								</div>
							</div>
						</Dropdown.Menu>
					</Dropdown>

				</div>
			</div>
		}
		</>
	)
						
}

export default Nav;
