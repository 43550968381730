import React from 'react'
import VirtualExperience from '../VirtualExperience/VirtualExperience';
import './EnrolledVirtualExperiences.css'
import {Link} from 'react-router-dom'

function EnrolledVirtualExperiences(props) {

	const handleScroll = (direction) => {
		let scrollContainer = document.getElementById("enrolled-ve-main")
		let scrollAmount = 0;
		let slideTimer = setInterval(function () {
			if (direction === 'left') {
				scrollContainer.scrollLeft -= 20;
			} else {
				scrollContainer.scrollLeft += 20;
			}
			scrollAmount += 20;
			if (scrollAmount >= 365) {
				window.clearInterval(slideTimer);
			}
		}, 10);
	}

	return (
		<>
			{
				Object.keys(props.virtualExps).length > 0 &&
				<>
					<div className="enrolled-ve-heading-container">
						<h6 className="text-navy-blue fw-600">Enrolled Virtual Work Experience</h6>
						<div>
							<button onClick={() => handleScroll("left")} className="rounded-circle bg-white text-navy-blue border-0" style={{ width: "40px", height: "40px" }}><i className="fas fa-arrow-left"></i></button>
							<button onClick={() => handleScroll("right")} className="bg-navy-blue ms-2 rounded-circle text-white border-0" style={{ width: "40px", height: "40px" }}><i className="fas fa-arrow-right"></i></button>
						</div>
					</div>
					<div className="enrolled-ve-main" id="enrolled-ve-main">

						{
							Object.keys(props.virtualExps).map(virtualExpId => {
								return (
									<Link key={virtualExpId} style={{textDecoration: "none"}} to={`/candidate/enrolled-virtual-experiences/${ virtualExpId }`}>
										<VirtualExperience id={virtualExpId} virtualExpDetails={props.virtualExps[virtualExpId]} />
									</Link>
								)
							})
						}
					</div>

				</>
			}
		</>
	)
}

export default EnrolledVirtualExperiences