import { React, useState } from 'react';
import { DropdownButton, Form } from 'react-bootstrap';
import JobsList from '../JobsList/JobsList';
import './TabsAndFilter.css';
import FilterModal from '../Modal/FilterModal';
import filterIcon from '../Media/filter.png'

function TabsAndFilter(props) {
    //below two states are for handling tabs styles
    const [activeTab, setActiveTab] = useState('allJobs')
    const [tabStyles, setTabStyles] = useState({
        allJobs: 'tab active-tab',
        savedJobs: 'tab',
        appliedJobs: 'tab',
        prepare: 'tab',
        skillTest: 'tab'
    })

    //whatever filters are applied, those filters will be maintained in this state as an object
    const [appliedFilters, setAppliedFilters] = useState(
        {
            'Job Type': [],
            'Field': [],
            'Location': [],
            'Company': [],
            'easyApply': false,
            'recentlyPosted': false
        }
    )

    //this function handles the styles of active tabs
    const handleActiveTab = (e) => {
        const currentTabName = e.target.getAttribute('data-type')
        const tempTabStyles = tabStyles
        tempTabStyles[activeTab] = 'tab'
        tempTabStyles[currentTabName] = 'tab active-tab'
        setTabStyles(tempTabStyles)
        setActiveTab(currentTabName)
    }

    const handleFilters = (e) => {
        //This function updates the applied filter state
        let isAddedToFilter = e.target.checked
        let currentFilterValue = e.target.id
        let currentFilterType = e.target.getAttribute('data-filter-type')
        let currentFilter = appliedFilters[currentFilterType]
        if (isAddedToFilter) {
            currentFilter.push(currentFilterValue)
        } else {
            let index = currentFilter.indexOf(currentFilterValue)
            currentFilter.splice(index, 1)
        }
        setAppliedFilters({ ...appliedFilters })
    }

    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    return (
        <>
            <div className="ms-4 me-4">
                <div className="d-flex">
                    <div role="button" onClick={() => setIsFilterModalOpen(true)} className="filter-icon-container" >
                        <img src={filterIcon} alt="filter-icon" />
                    </div>
                    <div className="tabs-container">
                        <div data-type="allJobs" className={tabStyles.allJobs} onClick={handleActiveTab}>
                            All Jobs
                        </div>
                        <div data-type="savedJobs" className={tabStyles.savedJobs} onClick={handleActiveTab}>
                            Saved Jobs
                        </div>
                        <div data-type="appliedJobs" className={tabStyles.appliedJobs} onClick={handleActiveTab}>
                            Applied Jobs
                        </div>
{/*                         <div data-type="prepare" className={tabStyles.prepare} onClick={handleActiveTab}> */}
{/*                             Prepare */}
{/*                         </div> */}
{/*                         <div data-type="skillTest" className={tabStyles.skillTest} onClick={handleActiveTab}> */}
{/*                             Skill Test */}
{/*                         </div> */}
                    </div>
                </div>

                <div className="filter-tabs-container">
                    <div className="border-end pe-4" style={{ marginRight: "-10px" }}>
                        <button onClick={() => setIsFilterModalOpen(true)} className="filter-tab"> All filters</button>
                    </div>
                    {
                        Object.keys(props.filterData).map((filter) => (
                            <DropdownButton
                                key={filter}
                                title={<span>{filter}  <i className='fas fa-chevron-down custom-dropdown-icon'></i></span>}
                                variant=''
                                bsPrefix={`filter-tab ${appliedFilters[filter].length > 0 ? 'bg-navy-blue text-white' : 'light-bg'}`}
                            >
                                {
                                    props.filterData[filter].map((filterValue) => {
                                        return (
                                            <Form.Group className="mb-3" controlId={filterValue} key={filterValue}>
                                                <Form.Check type="checkbox" label={filterValue} data-filter-type={filter} onChange={handleFilters} checked={appliedFilters[filter].includes(filterValue)} />
                                            </Form.Group>

                                        )
                                    })
                                }
                            </DropdownButton>
                        ))
                    }
                    <button className={`filter-tab ${appliedFilters['easyApply'] ? 'bg-navy-blue text-white' : 'light-bg'}`} onClick={() => setAppliedFilters({ ...appliedFilters, 'easyApply': !appliedFilters['easyApply'] })}> Easy Apply</button>
                    <button className={`filter-tab ${appliedFilters['recentlyPosted'] ? 'bg-navy-blue text-white' : 'light-bg'}`} onClick={() => setAppliedFilters({ ...appliedFilters, 'recentlyPosted': !appliedFilters['recentlyPosted'] })}> Date Posted</button>
                </div>
            </div>
            <div>
                {
                    activeTab === 'allJobs' && <JobsList jobs={props.allJobs.openJobs} appliedFilters={appliedFilters} type="openJobs" />
                }
                {
                    activeTab === 'savedJobs' && <JobsList jobs={props.allJobs.savedJobs} appliedFilters={appliedFilters} type="savedJobs" />
                }
                {
                    activeTab === 'appliedJobs' && <JobsList jobs={props.allJobs.appliedJobs} appliedFilters={appliedFilters} type="appliedJobs" />
                }
                {
                    activeTab === 'prepare' && <div className="prepare-container">Coming Soon</div>
                }
                {
                    activeTab === 'skillTest' && <div className="prepare-container">Coming Soon</div>
                }

            </div>
            <FilterModal filterData={props.filterData} appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} open={isFilterModalOpen} setOpen={setIsFilterModalOpen} />
        </>
    );
}

export default TabsAndFilter;
