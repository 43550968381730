import React, { useState } from 'react'
import EditProject from './EditProject'

function Project({ project, isPublicProfile, projects, setProjects }) {
    const [editProject, setEditProject] = useState(false)
    return (
        <>
            <div className="row m-1 mb-4 p-4 border rounded-3 position-relative">
                {
                    !isPublicProfile &&
                    <span role="button" onClick={() => setEditProject(true)}>
                        <i className="fas fa-pen pen-edit-icon"></i>
                    </span>
                }
                <div className="col-3">
                    <i className="fas fa-laptop-code fa-4x"></i>
                </div>
                <div className="fs-14 col-9">
                    <p className="text-navy-blue fw-500 mb-2">{project.Name}</p>
                    <p className="text-navy-blue mb-2">{project.University_Company}</p>
                    <span className="light-text fs-13">{project.from} - { (project.to === null || project.to.length === 0) ? "Present" : String(project.to) }</span>
                </div>
            </div>
            <EditProject open={editProject} setOpen={setEditProject} project={project} projects={projects} setProjects={setProjects} />
        </>
    )
}

export default Project