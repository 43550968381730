import { useState } from 'react'
import EditEducation from './EditEducation'

function Education({ education, isPublicProfile, educations, setEducations }) {
	const [editEducation, setEditEducation] = useState(false)
	return (
		<>
			<div className="row m-1 mb-4 p-4 border rounded-3 position-relative">
{/* 				{ */}
{/* 					(!isPublicProfile && education.end!==null) && */}
{/* 					<span role="button" onClick={() => setEditEducation(true)}> */}
{/* 						<i className="fas fa-pen pen-edit-icon"></i> */}
{/* 					</span> */}
{/* 				} */}
				<div className="col-3">
					<i className="fas fa-university fa-4x"></i>
				</div>
				<div className="fs-14 col-9">
					<p className="text-navy-blue fw-500 mb-2">{education.institute}</p>
					<p className="text-navy-blue mb-2">{education.specialization}</p>
					<span className="light-text fs-13">{String(education.start).substring(0, 7)} - {education.end !== null ? String(education.end).substring(0, 7) : "Present"}</span>
				</div>
			</div>
			{
				!isPublicProfile &&
				<EditEducation open={editEducation} setOpen={setEditEducation} education={education} educations={educations} setEducations={setEducations} />
			}
		</>
	)
}

export default Education