import React from 'react'
import { useState, useContext } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import Cookies from 'js-cookie'
import { loadingContext, messageContext } from '../../../App'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import Upload_Resume from "../../../opportunities/Media/Upload_Resume.png";
import file_img from "../../Media/file-img-blue.png";
import taskSuccessImg from "../../../opportunities/Media/success_img.png";


let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
	baseUrl = 'http://localhost:8000'

function ChatBox(props) {
	const params = useParams()
	const loadingHandler = useContext(loadingContext)
	const messageHandler = useContext(messageContext)
	const [viewVideo, setViewVideo] = useState(false)
	let navigate = useNavigate()

	if (props.showLoader) {
		let textLoader = document.getElementsByClassName("loader")
		textLoader = textLoader[textLoader.length - 1]
		let chatTextContainer = document.getElementsByClassName('chatbox-text-container')
		chatTextContainer = chatTextContainer[chatTextContainer.length - 1]
		setTimeout(() => {
			textLoader.classList.add('d-none')
			chatTextContainer.classList.remove('d-none')

		}, 400);
	}

	const updateUserResponse = (e) => {
		let candidateReplyForm = new FormData()
		let candidateReplyData = {
			virtualExpId: params.virtualExpId,
			taskId: params.taskId,
			windowNumber: props.currentMessageIndex.pageNumber,
			chatNumber: e.target.getAttribute('data-order') - 1,
			replyText: e.target.getAttribute('data-message')
		}
		candidateReplyForm.append('candidateReplyData', JSON.stringify(candidateReplyData))
		axios.post(`${baseUrl}/candidate/save_candidate_reply/`,
			candidateReplyForm,
			{
				headers: {
					'X-CSRFToken': Cookies.get('csrftoken'),
				},
				withCredentials: true
			}
		)
			.then(response => {
				let tempLiveChat = props.liveChat
				let questionChatObject = tempLiveChat.pop()
				questionChatObject['status'] = "REPLIED"
				props.setLiveChat([...tempLiveChat, questionChatObject, { is_reply: true, system_message: e.target.getAttribute('data-message'), message_type: "TEXT" }])
				props.setWaitForReply(false)
			})
			.catch(e => {

			})
	}

	const uploadTask = (event) => {
		event.preventDefault()
		loadingHandler.setIsLoading(true)
		let newFileName = params.virtualExpId + params.taskId + (new Date().getTime()).toString()
		let remotePath = 'virtual_experience/task_docs/' + newFileName
		let fileExtension = event.target.files[0].name.split('.').pop()
		let assessmentForm = new FormData()

		let assessmentData = {
			'assessment_type': 'DOC_SUBMISSION',
			'assessment_id': props.currentWindowObject.assessment_id,
			'file_name': newFileName,
			'remote_path': remotePath,
			'file_extension': fileExtension,
			'taskId': params.taskId,
			'windowNumber': props.currentMessageIndex.pageNumber
		}
		assessmentForm.append('file', event.target.files[0])
		assessmentForm.append('assessment_data', JSON.stringify(assessmentData))

		axios.post(`${baseUrl}/candidate/update_candidate_assessment/`,
			assessmentForm,
			{
				headers: {
					'X-CSRFToken': Cookies.get('csrftoken'),
				},
				withCredentials: true
			}
		)
			.then(r => {
				let tempCurrentChatObject = props.currentWindowObject
				tempCurrentChatObject['status'] = "COMPLETED"
				loadingHandler.setIsLoading(false)
				messageHandler.setMessage({
					showMessage: true,
					type: "success",
					displayText: "Successfully Uploaded Your Task"
				})				
				props.setCurrentWindowStatus("COMPLETED")
				if (r.data.completed_virtual_experience)
					navigate(`/candidate/enrolled-virtual-experiences/${params.virtualExpId}`)
			})
			.catch(e => {
				loadingHandler.setIsLoading(false)
				messageHandler.setMessage({
					showMessage: true,
					type: "warning",
					displayText: "Internal Server Error"
				})
			})
	}

	const updateCandidateAssessment = (e, selectedChoiceObj) => {
		if (props.chatObject['candidate_answer'] === null) {
			if (selectedChoiceObj['is_correct']) {
				messageHandler.setMessage({
					showMessage: true,
					type: "success",
					displayText: `Correct Choice! +${selectedChoiceObj['partial_score']}`
				})
				e.target.innerHtml = e.target.innerHtml + `<i className="far fa-check-circle text-success"></i>`
			} else {
				messageHandler.setMessage({
					showMessage: true,
					type: "warning",
					displayText: `Incorrect Choice! +${selectedChoiceObj['partial_score']}`
				})
				e.target.innerHtml = e.target.innerHtml + `<i className="far fa-times-circle text-danger"></i>`
			}
			props.chatObject['candidate_answer'] = selectedChoiceObj
			let assessment_id = props.currentWindowObject.assessment_id
			let questionNumber = e.target.getAttribute('data-question-no')
			let assesment_update_data = {
				'assessment_type': 'MCQ',
				'assessment_id': assessment_id,
				'question_number': questionNumber,
				'candidate_choice': selectedChoiceObj
			}
			let assessmentForm = new FormData()
			assessmentForm.append('assessment_data', JSON.stringify(assesment_update_data))
			axios.post(`${baseUrl}/candidate/update_candidate_assessment/`,
				assessmentForm,
				{
					headers: {
						'X-CSRFToken': Cookies.get('csrftoken'),
					},
					withCredentials: true
				}
			)
				.then(response => {
					props.setWaitForReply(false)
				})
				.catch(e => {
					messageHandler.setMessage({
						showMessage: true,
						type: "warning",
						displayText: "Internal Server Error!"
					})
				})
		}

	}

	return (
		<>
			<Modal show={viewVideo} centered fullscreen="md-down" size="lg" contentClassName="p-1">
				<Modal.Body bsPrefix="modal-body ">
					<button onClick={() => setViewVideo(false)} className="edit-modal-close"><i className="fas fa-times"></i></button>
					<video controls title="Instruction-Video" className="video-resume-player" src={props.chatObject.system_message}></video>
				</Modal.Body>
			</Modal>

			<div className={props.chatObject.is_reply ? "chatbox-main-right-container" : props.chatObject.problem_statement === "SUBMIT" ? "chatbox-main-center-container" : "chatbox-main-left-container"}>
				<div className={`chatbox-avatar-container ${props.chatObject.problem_statement === "SUBMIT" ? 'd-none' : ''}`}>
					<img src={props.chatObject.is_reply ? "https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png" : props.currentWindowObject['avatar_image']} className={`w-100`} />
				</div>
				<div className={`chatbox-text-container ${props.showLoader ? "d-none" : ""} ${props.chatObject.is_reply ? "chatbox-text-right" : props.chatObject.problem_statement === "SUBMIT" ? "border-2" : "chatbox-text-left"}`} >

					{
						props.currentWindowObject.window_type === "MCQ" || props.currentWindowObject.window_type === "DOC_SUBMISSION" ?
							<>
								{
									props.chatObject.problem_statement !== "SUBMIT" && <span dangerouslySetInnerHTML={{ __html: props.chatObject.problem_statement }} />
								}
								<div className="answer-choices-container flex-column">
									{
										props.chatObject.choices &&
										props.chatObject.choices.map((choiceObj, index) => {
											return (<span className={`answer-choice ${(props.chatObject['candidate_answer'] && props.chatObject['candidate_answer']['choice_id'] == choiceObj['choice_id']) ? (props.chatObject['candidate_answer']['is_correct']) ? 'bg-success text-white' : (props.chatObject['candidate_answer']['partial_score'] > 0 ? 'bg-warning text-white' : 'bg-danger text-white') : ''}`} data-choice-id={choiceObj.choice_id} data-question-no={props.chatObject.order} onClick={(e) => updateCandidateAssessment(e, choiceObj)}>{index + 1}. {choiceObj.choice_text} &nbsp;
												{
													props.chatObject['candidate_answer'] && props.chatObject['candidate_answer']['choice_id'] == choiceObj['choice_id'] ? (props.chatObject['candidate_answer']['is_correct']) ? <i className="far fa-check-circle"></i> : <i className="far fa-times-circle"></i> : ''
												}
											</span>)
										})
									}
								</div>
								{
									props.chatObject.problem_statement === "SUBMIT" ?
										(props.currentWindowObject.status === "IN_PROGRESS" ?
											<div>
												<label for="taskSubmission" className="task-upload-container">
													<div className="row m-3 p-4 justify-content-between">
														<div className="col-md-4 pt-3 text-center">
															<img src={Upload_Resume} alt="upload-icon" className="w-100" />
														</div>
														<div className="col-md-8 text-center py-3">
															<h6>Upload your work files</h6>
															<p className="fs-14">max file size: 10 mb</p>
														</div>
													</div>
													<input type="file" className="d-none" id="taskSubmission" accept="application/pdf" onChange={uploadTask} />
												</label>
											</div> :
											<div className="task-success-container">
												<img src={taskSuccessImg} alt="success" className="w-100"/>
												<h4 className="mt-5">Work Submitted Successfully</h4>
												<div className="task-success-btn-container">
													
												</div>

											</div>) : undefined

								}
							</>
							: <>
								{
									props.chatObject.response_required && props.chatObject.status === 'NOT_REPLIED' ?
										<>
											{
												<span dangerouslySetInnerHTML={{ __html: props.chatObject.system_message }} />
											}
											<div className="answer-choices-container">
												{
													props.chatObject.options.map((optionText, index) => {
														return (<span className="answer-choice" data-order={props.chatObject.order} data-message={optionText} onClick={updateUserResponse}>{optionText}</span>)
													})
												}
											</div>
										</>
										: (props.chatObject.message_type === "TEXT" ? <span dangerouslySetInnerHTML={{ __html: props.chatObject.system_message }} /> :
											(props.chatObject.message_type === "VIDEO" ?
												<div className="chat-img-container" onClick={() => setViewVideo(!viewVideo)}>
													<img src={props.chatObject.thumbnail_image} />
													<i className="far fa-play-circle thumbnail-play-btn"></i>
												</div> :
												(props.chatObject.message_type === "DOCUMENT" ? <>
													<div className="chat-doc-container">
														<a href={props.chatObject.system_message} target="_blank" >
															<div className="text-center">
																<img src={file_img} alt="doc" />
															</div>
														</a>
														<p className="m-0 mt-2 fs-13">{props.chatObject.file_text}</p>
													</div></> : undefined
												)
											)
										)
								}
							</>
					}

				</div>
				<div className={`loader ${!props.showLoader ? "d-none" : ""}`} id="text-loader">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>

		</>
	)
}

export default ChatBox