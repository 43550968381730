import React from 'react'

function SocialLinks({ socialLinks }) {
    return (
        <>
            <div className="d-flex border p-5 ps-2 pe-2 justify-content-around rounded" style={{ fontSize: "24px" }}>
                {
                    socialLinks.hasOwnProperty('portfolio') && socialLinks['portfolio'].length!==0 && <a href={socialLinks['portfolio']} target="_blank" rel="noreferrer">
                        <i className="fas fa-globe text-navy-blue"></i>
                    </a>
                }
                {
                    socialLinks.hasOwnProperty('behance_dribble') && socialLinks['behance_dribble'].length!==0 && <a href={socialLinks['behance_dribble']} target="_blank" rel="noreferrer">
                        <i className="fab fa-behance text-navy-blue"></i>
                    </a>
                }
                {
                    socialLinks.hasOwnProperty('linkedin') && socialLinks['linkedin'].length!==0 && <a href={socialLinks['linkedin']} target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in text-navy-blue"></i>
                    </a>
                }
                {
                    socialLinks.hasOwnProperty('github') && socialLinks['github'].length!==0 && <a href={socialLinks['github']} target="_blank" rel="noreferrer">
                        <i className="fab fa-github text-navy-blue"></i>
                    </a>
                }
            </div>
        </>
    )
}

export default SocialLinks