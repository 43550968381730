import {useState} from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import "./ApplicationModal.css";
import "../../App.css";
import Apple from "../Media/Social-Icons/Apple.png"
import Facebook from "../Media/Social-Icons/Facebook.png"
import Gmail from "../Media/Social-Icons/Gmail.png"
import Linkedin from "../Media/Social-Icons/Linkedin.png"
import Messenger from "../Media/Social-Icons/Messenger.png"
import Whatsapp from "../Media/Social-Icons/Whatsapp.png"

function ShareModal(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "#ffffff",
        boxShadow: 24,
        borderRadius: "10px",
        p: 4,
    };

    const [urlToCopy, setUrlToCopy] = useState('Dummy URL');

    return (
        <>
            <Modal open={props.open}>
                <Box sx={style} className="modal-container">

                    <button className="bg-white border-0 position-absolute modal-close-button" onClick={() => props.setOpen(false)}>
                        <i className="fas fa-times fa-lg"></i>
                    </button>
                    <h4 className="text-navy-blue text-center fw-600 mb-5">Share With</h4>

                    <p className="text-center">Copy the link</p>
                    <div className="d-flex justify-content-center align-items-center mb-5">
                        <input className="form-control w-50" type="text" placeholder="Dummy URL" readonly></input>
                        <Button variant="contained" className="copy-button" onClick={() => { navigator.clipboard.writeText(urlToCopy) }}>
                            <i className="far fa-clone"></i>
                        </Button>
                    </div>

                    <div className="row mt-3">
                        <div className="col-4 col-md-2 d-flex flex-column justify-content-evenly align-items-center"><img src={Messenger} className=" social-media-icons" alt="icon"></img><p className="text-center fs-12">Message</p></div>
                        <div className="col-4 col-md-2 d-flex flex-column justify-content-evenly align-items-center"><img src={Whatsapp} className=" social-media-icons" alt="icon"></img><p className="text-center fs-12">Whatsapp</p></div>
                        <div className="col-4 col-md-2 d-flex flex-column justify-content-evenly align-items-center"><img src={Gmail} className="social-media-icons" alt="icon"></img><p className="text-center fs-12">Gmail</p></div>
                        <div className="col-4 col-md-2 d-flex flex-column justify-content-evenly align-items-center"><img src={Facebook} className=" social-media-icons" alt="icon"></img><p className="text-center fs-12">Facebook</p></div>
                        <div className="col-4 col-md-2 d-flex flex-column justify-content-evenly align-items-center"><img src={Apple} className=" social-media-icons" alt="icon"></img><p className="text-center fs-12">Apple ID</p></div>
                        <div className="col-4 col-md-2 d-flex flex-column justify-content-evenly align-items-center"><img src={Linkedin} className="social-media-icons" alt="icon"></img><p className="text-center fs-12">LinkedIn</p></div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ShareModal;
