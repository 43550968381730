import { initializeApp } from 'firebase/app';
import {getAuth} from 'firebase/auth';

let firebaseConfig = {
  apiKey: "AIzaSyDPgOe1e1pSzaPUZIfBXbqBf0tc1N6I2JE",
  authDomain: "aplidotai.firebaseapp.com",
  databaseURL: "https://aplidotai.firebaseio.com",
  projectId: "aplidotai",
  storageBucket: "aplidotai.appspot.com",
  messagingSenderId: "491401404214",
  appId: "1:491401404214:web:3d444500663dfc516b9d97",
  measurementId: "G-NS39614Z6G"
};

if (window.location.origin === 'https://apli.ai' || window.location.origin === 'https://staging.apli.ai') {
  firebaseConfig = {
    apiKey: "AIzaSyAItowFakv9YbQXCr44WQ3tDI5nlEjLaRo",
    authDomain: "aplidotai-be4e6.firebaseapp.com",
    databaseURL: "https://aplidotai-be4e6.firebaseio.com",
    projectId: "aplidotai-be4e6",
    storageBucket: "aplidotai-be4e6.appspot.com",
    messagingSenderId: "895883268556",
    appId: "1:895883268556:web:c9972a96c4ea427a048d69",
    measurementId: "G-QVFCL1GXFR"
  };

}

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app)