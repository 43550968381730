import { useState, useContext, useEffect } from 'react'
import axios from 'axios';
import './Signup.css'
import Signup_icon from './Media/Signup_icon.png'
import Facebook_icon from './Media/Facebook_icon.png'
import Google_icon from './Media/Google_icon.png'
import Linkedin_icon from './Media/Linkedin_icon.png'
import Logo_white from './Media/logo-white.png'
import Form from 'react-bootstrap/Form'
import { loadingContext, messageContext } from '../App'

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'


function Login() {
    const [passwordShown, setPasswordShown] = useState(false);
    const [csrfToken, setCsrfToken] = useState('')
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)

    const [userLoginData, setUserLoginData] = useState({
        'inputEmail': '',
        'inputPassword': '',
        'rememberMe': false
    })

    useEffect(() => {
        fetch(`${baseUrl}/csrf/`, {
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setCsrfToken(data.csrfToken)
            })
            .catch(e => {
                messageHandler.setMessage({
                    showMessage: true,
                    type: "warning",
                    displayText: "Internal Server Error!"
                })

            })

        axios.get(`${baseUrl}/accounts/login_api/`, {
            withCredentials: true
        })
            .then(response => {
                loadingHandler.setIsLoading(false)
                let responseData = response.data
                if (responseData.message !== null) {
                    messageHandler.setMessage({
                        showMessage: true,
                        type: responseData.message_type,
                        displayText: responseData.message
                    })
                }
                if (responseData.redirect_to !== null)
                    window.location.replace(`${baseUrl}${responseData.redirect_to}`)
            })
            .catch((e) => {
                let responseData = e.response.data
                if (responseData.message !== null) {
                    messageHandler.setMessage({
                        showMessage: true,
                        type: responseData.message_type,
                        displayText: responseData.message
                    })
                }
                if (responseData.redirect_to !== null)
                    window.location.replace(`${baseUrl}${responseData.redirect_to}`)
                loadingHandler.setIsLoading(false)
            })

    }, [])


    const loginUser = () => {
        if (userLoginData.inputEmail.length > 0 && userLoginData.inputPassword.length >= 8) {
            loadingHandler.setIsLoading(true)
            let userData = new FormData()
            userData.append('userData', JSON.stringify(userLoginData))
            axios.post(`${baseUrl}/accounts/login_api/`,
                userData,
                {
                    headers: {
                        'X-CSRFToken': csrfToken
                    },
                    withCredentials: true
                })
                .then(response => {
                    loadingHandler.setIsLoading(false)
                    let responseData = response.data
                    if (responseData.message !== null) {
                        messageHandler.setMessage({
                            showMessage: true,
                            type: responseData.message_type,
                            displayText: responseData.message
                        })
                    }
                    if (responseData.redirect_to !== null)
                        window.location.replace(`${baseUrl}${responseData.redirect_to}`)
                })
                .catch((e) => {
                    let responseData = e.response.data
                    if (responseData.message !== null) {
                        messageHandler.setMessage({
                            showMessage: true,
                            type: responseData.message_type,
                            displayText: responseData.message
                        })
                    }
                    if (responseData.redirect_to !== null)
                        window.location.replace(`${baseUrl}${responseData.redirect_to}`)
                    loadingHandler.setIsLoading(false)
                })
        } else
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Invalid Credentials!"
            })

    }

    return (

        <div className="text-navy-blue signup-container-parent position-relative">
            <div className="row signup-container">
                <div className="col-md-5 signup-container-left d-flex align-items-center justify-content-center">
                    <div className="logo-box">
                        <img className="logo-img" src={Logo_white} alt="Logo" />
                    </div>
                    <div>
                        <img className="signup-icon-img" src={Signup_icon} alt="Signup" />
                    </div>
                </div>
                <div className="col-md-7 signup-container-right d-flex justify-content-center">
                    <div className="signup-form-container px-4">
                        <h3 className="signup-form-heading-custom mt-2">Login</h3>
                        <div className="d-flex flex-column mb-3">
                            <label className="fw-500 fs-14 mb-2" htmlFor="signin-email">Email ID</label>
                            <input value={userLoginData.inputEmail} onChange={(e) => setUserLoginData({ ...userLoginData, inputEmail: e.target.value })} className="edit-modal-input-box mb-0" id="signin-email" type="email" />
                        </div>
                        <div className="d-flex flex-column mb-3">
                            <label className="fw-500 fs-14 mb-2" htmlFor="signin-password">Password</label>
                            <div className="d-flex position-relative">
                                <input style={{ width: "100%" }} value={userLoginData.inputPassword} onChange={(e) => setUserLoginData({ ...userLoginData, inputPassword: e.target.value })} className="edit-modal-input-box mb-0" id="signin-password" type={passwordShown ? "text" : "password"} ></input>
                                <button className="password-show-toggle-button" onClick={() => setPasswordShown(!passwordShown)}>
                                    {passwordShown ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                </button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>
                                <Form.Check bsPrefix="profile-add-modal-check"
                                    type="switch"
                                    id="custom-switch"
                                    label="Remember me"
                                    checked={userLoginData.rememberMe}
                                    onChange={(e) => setUserLoginData({ ...userLoginData, rememberMe: e.target.checked })}
                                />
                            </div>
                            <div>
                                <a className="fs-14 fw-500" href="#" style={{ textDecoration: "none" }}>Forget Password?</a>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                            <button className="apli-btn w-100 mt-4 submit-button-custom" onClick={loginUser}>Login</button>
                            {/* <div className="d-flex or-continue-div">
                                <p className="fs-12 fw-500 or-continue-text text-center" style={{ color: "#979797" }}>or continue with</p>
                            </div>
                            <div className="w-100 mt-1">
                                <div className="d-flex justify-content-around">
                                    <div role="button" className="social-icon-container">
                                        <img className="social-icon-img" src={Google_icon} alt="Google"></img>
                                    </div>
                                    <div role="button" className="social-icon-container">
                                        <img className="social-icon-img" src={Linkedin_icon} alt="Google"></img>
                                    </div>
                                    <div role="button" className="social-icon-container">
                                        <img className="social-icon-img" src={Facebook_icon} alt="Google"></img>
                                    </div>
                                </div>
                            </div> */}
                            <p className="fs-16 fw-600 mt-5">Don't Have Account? <a href={baseUrl + `/accounts/signup/candidate/email/verify`}  style={{ textDecoration: "none" }}>Signup</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login