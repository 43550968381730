import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { loadingContext, messageContext } from '../App';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { useParams } from "react-router-dom";
import Experience from './Experience/Experience';
import Project from './Projects/Project';
import ExtraCurricular from './ExtraCurricular/ExtraCurricular';
import SocialLinks from './SocialLinks/SocialLinks';
import Skills from './Skills/Skills';
import Education from './Education/Education';
import Award from './Award/Award';
import Footer from '../footer/Footer'
import './Profile.css'
import blue_file_img from "../virtualExperiences/Media/file-img-blue.png"

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function PublicProfile() {
    let params = useParams()
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const [videoModalOpen, setVideoModalOpen] = useState(false)
    const [basicProfileInfo, setBasicProfileInfo] = useState(null)
    const [experiences, setExperiences] = useState([])
    const [projects, setProjects] = useState([])
    const [extraCurriculars, setExtraCurriculars] = useState([])
    const [education, setEducation] = useState([])
    const [awards, setAwards] = useState([])
    const [skills, setSkills] = useState({})
    const [showApliResume, setShowApliResume] = useState(false)

    // This state maintains whether every items is hown or a single one in details (experience, projects, etc)
    const [showAll, setShowAll] = useState({
        experiences: false,
        projects: false,
        extraCurriculars: false,
        education: false,
        awards: false,
        skills: false
    })

    useEffect(() => {
        loadingHandler.setIsLoading(true)
        axios.get(`${baseUrl}/candidate/profile_details/${params.encodedId}`, {
            withCredentials: true
        })
            .then(response => {
                setBasicProfileInfo(response.data.basic_info)
                setExperiences(response.data.profile_details.experience)
                setEducation(response.data.profile_details.education)
                setProjects(response.data.profile_details.projects)
                setExtraCurriculars(response.data.profile_details.extra_curricular)
                setSkills(response.data.profile_details.skills)
                setAwards(response.data.profile_details.award)
                loadingHandler.setIsLoading(false)
            })
            .catch(error => {
                loadingHandler.setIsLoading(false)
                messageHandler.setMessage({
                    showMessage: false,
                    type: "warning",
                    displayText: "Internal Server Error"
                })
            })

    }, [])


    return (
        <>
            {
                basicProfileInfo &&
                <div className="profile-box-container">
                    <Modal
                        show={showApliResume}
                        fullscreen='lg-down'
                        size="xl"
                        onHide={() => setShowApliResume(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Apli Resume
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ height: "500px" }}>
                            <iframe
                                src={`https://docs.google.com/viewer?url=${basicProfileInfo.pdfResume}&embedded=true`}
                                type="application/pdf"
                                frameBorder="0"
                                scrolling="auto"
                                height="100%"
                                width="100%"
                            ></iframe>
                        </Modal.Body>
                    </Modal>
                    <div className="profile-box-top-container">
                        <div className="profile-box-upper">
                            <div>
                                <div className="profile-box-cover-img-container">
                                    <img className="profile-box-cover-img w-100 h-100" src={basicProfileInfo.profile_picture} alt="cover-img" style={{ objectFit: "cover" }} />
                                </div>
                                <button onClick={() => setVideoModalOpen(true)} className="public-profile-play-video-btn"><i className="far fa-play-circle fa-3x"></i></button>
                            </div>
                        </div>
                        <div className="profile-box-lower position-relative">
                            <div className="d-flex justify-content-center position-relative profile-box-lower-left">
                                <div className="profile-box-profile-img-container d-flex align-items-center justify-content-center px-2">
                                    <img src={basicProfileInfo.profile_picture} className="profile-box-profile-img" alt="profile-img" />
                                </div>
                            </div>
                            <div className="profile-box-details">
                                <div className="text-navy-blue profile-box-user-details">
                                    <h3 className="profile-box-user-info-name">{basicProfileInfo.First_name} {basicProfileInfo.Middle_name} {basicProfileInfo.Last_name}</h3>
                                    <p className="profile-box-user-info-job">{basicProfileInfo.heading_title}</p>
                                    <p className="profile-box-user-info-place">{basicProfileInfo.Address.city}</p>
                                </div>

                                <div className="profile-box-lower-right">
                                    <ProgressBar className="profile-box-progress" now={basicProfileInfo.percent_profile_completed} />
                                    <p className="profile-box-progress-para">{basicProfileInfo.percent_profile_completed}% complete profile</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


            <div className="profile-all-component-container">
                {
                    basicProfileInfo && basicProfileInfo.about_me &&
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 custom-col">
                            <p className="fw-600 text-navy-blue mt-50">About me</p>
                            <div className="border m-1 light-text p-4 rounded fs-14">
                                {basicProfileInfo.about_me}
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-6 custom-col">
                            <p className="fw-600 mb-2 text-navy-blue mt-50">Profile Link</p>
                            <div className="d-flex profile-resume-container">
                                <div className="fw-600 text-purple w-75 p-1 public-link-container">
                                    {basicProfileInfo.public_profile_url}
                                </div>
                                <i className="fas fa-share-alt fs-18 ms-3 me-3"></i>
                                <span role="button" onClick={() => window.navigator.clipboard.writeText(basicProfileInfo.public_profile_url)}><i className="far fa-copy fs-18"></i></span>
                            </div>
                            <p className="fw-600 text-navy-blue mt-50">Resume</p>
                            <div className="profile-resume-container-parent">
                                <div onClick={() => setShowApliResume(true)} role="button" className="d-flex profile-resume-container profile-resume-container-second bg-light p-2 fs-14 rounded">
                                    <img src={blue_file_img} alt="file" style={{ height: "25px" }} />
                                    <a target="_blank" className="ms-2 profile-resume-container-link">Apli Resume</a>
                                </div>
                                {basicProfileInfo.uploaded_resume &&
                                    <div className="d-flex profile-resume-container bg-light p-2 fs-14 rounded">
                                        <img src={blue_file_img} alt="file" style={{ height: "25px" }} />
                                        <a href={basicProfileInfo.uploaded_resume} target="_blank" className="ms-2 profile-resume-container-link">Resume.pdf</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 custom-col">
                        {
                            basicProfileInfo && !basicProfileInfo.about_me &&
                            <>
                                <p className="fw-600 mb-2 text-navy-blue mt-50">Profile Link</p>
                                <div className="d-flex profile-resume-container">
                                    <div className="fw-600 text-purple w-75 p-1 public-link-container">
                                        {basicProfileInfo.public_profile_url}
                                    </div>
                                    <i className="fas fa-share-alt fs-18 ms-3 me-3"></i>
                                    <span role="button" onClick={() => window.navigator.clipboard.writeText(basicProfileInfo.public_profile_url)}><i className="far fa-copy fs-18"></i></span>
                                </div>
                                <p className="fw-600 text-navy-blue mt-50">Resume</p>
                                <div className="d-flex profile-resume-container bg-light p-2 fs-14 rounded">
                                    <a href={basicProfileInfo.uploaded_resume} target="_blank" >Resume.pdf</a>
                                </div>
                            </>
                        }
                        {
                            experiences && experiences.length > 0 &&
                            <div className="position-relative">
                                <p className="fw-600 text-navy-blue mt-50">Experience</p>
                                {showAll.experiences ?
                                    <>
                                        {
                                            experiences.map((exp, index) => {
                                                return <Experience key={exp.designation + index + exp.company} experience={exp} isPublicProfile={true} />
                                            })
                                        }
                                        <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'experiences': false }) }}><i className="fas fa-angle-up fa-lg"></i></div>
                                    </>
                                    :
                                    <>
                                        <Experience experience={experiences[0]} isPublicProfile={true} />
                                        <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'experiences': true }) }}><i className="fas fa-angle-down fa-lg"></i></div>
                                    </>
                                }
                            </div>
                        }

                        {
                            projects && projects.length > 0 &&
                            <div className="position-relative">
                                <p className="fw-600 text-navy-blue mt-50">Projects</p>
                                <>
                                    {showAll.projects ?
                                        <>
                                            {
                                                projects.map((project, index) => {
                                                    return <Project key={project.Name + index + project.University_Company} project={project} isPublicProfile={true} />
                                                })
                                            }
                                            <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'projects': false }) }}><i className="fas fa-angle-up fa-lg"></i></div>
                                        </>
                                        :
                                        <>
                                            <Project project={projects[0]} isPublicProfile={true} />
                                            <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'projects': true }) }}><i className="fas fa-angle-down fa-lg"></i></div>
                                        </>
                                    }
                                </>
                            </div>
                        }

                        {
                            extraCurriculars && extraCurriculars.length > 0 &&
                            <div className="position-relative">
                                <p className="fw-600 text-navy-blue mt-50">Extra-Curriculars</p>
                                <>
                                    {showAll.extraCurriculars ?
                                        <>
                                            {
                                                extraCurriculars.map((extraCurr, index) => {
                                                    return <ExtraCurricular key={extraCurr.role + index + extraCurr.organisation} isPublicProfile={true} extraCurricular={extraCurr} />
                                                })
                                            }
                                            <div className="d-flex justify-content-center mt-4" role="button" onClick={() => { setShowAll({ ...showAll, 'extraCurriculars': false }) }}><i className="fas fa-angle-up fa-lg"></i></div>
                                        </>
                                        :
                                        <>
                                            <ExtraCurricular extraCurricular={extraCurriculars[0]} isPublicProfile={true} />
                                            <div className="d-flex justify-content-center mt-4" role="button" onClick={() => { setShowAll({ ...showAll, 'extraCurriculars': true }) }}><i className="fas fa-angle-down fa-lg"></i></div>
                                        </>
                                    }
                                </>
                            </div>
                        }
                        {
                            basicProfileInfo && Object.keys(basicProfileInfo.social_links).length > 0 &&
                            <>
                                <p className="fw-600 text-navy-blue mt-50">Social Links</p>
                                <SocialLinks socialLinks={basicProfileInfo.social_links} isPublicProfile={true} />
                            </>
                        }
                    </div>
                    <div className="col-md-8 col-lg-6 custom-col">
                        {
                            education && education.length > 0 &&
                            <div >
                                <p className="fw-600 text-navy-blue mt-50">Education</p>
                                <>
                                    {showAll.education ?
                                        <>
                                            {
                                                education.map(edu => {
                                                    return <Education key={edu.type} education={edu} isPublicProfile={true} />
                                                })
                                            }
                                            <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'education': false }) }}><i className="fas fa-angle-up fa-lg"></i></div>
                                        </>
                                        :
                                        <>
                                            <Education education={education[0]} isPublicProfile={true} />
                                            <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'education': true }) }}><i className="fas fa-angle-down fa-lg"></i></div>
                                        </>
                                    }
                                </>
                            </div>
                        }

                        {
                            awards && awards.length > 0 &&
                            <div>
                                <p className="fw-600 text-navy-blue mt-50">Awards</p>
                                <>
                                    {showAll.awards ?
                                        <>
                                            {
                                                awards.map((award, index) => {
                                                    return <Award key={award.date + index} award={award} isPublicProfile={true} />
                                                })
                                            }
                                            <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'awards': false }) }}><i className="fas fa-angle-up fa-lg"></i></div>
                                        </>
                                        :
                                        <>
                                            <Award award={awards[0]} isPublicProfile={true} />
                                            <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'awards': true }) }}><i className="fas fa-angle-down fa-lg"></i></div>
                                        </>
                                    }
                                </>
                            </div>
                        }

                        {
                            skills && skills.length > 0 &&
                            <div>
                                <p className="fw-600 text-navy-blue mt-50">Skills</p>
                                <Skills skills={skills} isPublicProfile={true} />
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                basicProfileInfo && basicProfileInfo.video_resume &&
                <Modal show={videoModalOpen} centered fullscreen="md-down" size="lg">
                    <Modal.Body>
                        <div className="d-flex justify-content-center video-resume-player-container px-0">
                            <video autoPlay title="Instruction-Video" className="video-resume-player" src={basicProfileInfo.video_resume}></video>
                        </div>
                    </Modal.Body>
                </Modal>

            }
            {
                basicProfileInfo &&
                <Footer />
            }
        </>
    )
}

export default PublicProfile