import { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import "../Modals/Modals.css"
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import Cookies from 'js-cookie'
import { loadingContext, messageContext } from '../../App';

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function EditExperienceModal(props) {
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const [editedExperience, setEditedExperience] = useState(props.experience)
    const today = new Date();
    const date = String(today.getFullYear()) + "-" + String(today.getMonth() + 1).padStart(2, '0')
    const handleFromOnChange = (event) => {
        setEditedExperience({ ...editedExperience, 'from': event.target.value })
    }
    const handleToOnChange = (event) => {
        editedExperience.is_active ? setEditedExperience({ ...editedExperience, 'to': '' }) : setEditedExperience({ ...editedExperience, 'to': event.target.value })
    }
    const handleActiveOnChange = (event) => {
        if (event.target.checked) {
            setEditedExperience({ ...editedExperience, 'is_active': true, 'to': '' })
        } else {
            setEditedExperience({ ...editedExperience, 'is_active': false })
        }
    }

    const updateExperience = () => {
        loadingHandler.setIsLoading(true)
        if (editedExperience.Type.length > 250 || editedExperience.company.length > 250 || editedExperience.information.length > 500 || editedExperience.Type.length < 1 || editedExperience.company.length < 1 || editedExperience.designation.length < 1 || editedExperience.from.length < 1 || (editedExperience.to !== '' && editedExperience.from > editedExperience.to)) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter valid input"
            })
            return;
        }
        if (editedExperience.information[0].length < 80) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter at least 80 characters of Responsibilities."
            })
            return;
        }
        props.setOpen(false)
        let experienceData = props.experience
        for (let key in editedExperience) {
            experienceData[key] = editedExperience[key]
        }
        let ExperienceData = new FormData()
        let d = JSON.stringify({ 'edit_type': 'add_exp', 'exp_data': props.experiences })
        ExperienceData.append('data', d)
        axios.post(`${ baseUrl }/candidate/update_and_load_resume/`,
            ExperienceData,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                withCredentials: true
            }
        ).then(r => {
            props.setExperiences(props.experiences)
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "success",
                displayText: "Your Experience is Edited"
            })
        }).catch(e => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Internal Server Error"
            })
        })
    }

    return (
        <div>
            {editedExperience &&
                <Modal show={props.open} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                    <Modal.Body bsPrefix="modal-body profile-modal-content">
                        <button onClick={() => props.setOpen(false)} className="edit-modal-close"><i className="fas fa-times"></i></button>
                        <h4 className="text-center fs-18 fw-500 mt-5 mb-5 text-navy-blue">Edit Experience</h4>

                        <div className="row">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="experience-modal-experience-job-title">Job Title</label>
                                    <input value={editedExperience.designation} onChange={(event) => { setEditedExperience({ ...editedExperience, 'designation': event.target.value }) }} className="edit-modal-input-box" id="experience-modal-experience-job-title" type="text" placeholder="Alexa Dawson" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="experience-modal-company">Company Name</label>
                                    <input value={editedExperience.company} onChange={(event) => { setEditedExperience({ ...editedExperience, 'company': event.target.value }) }} className="edit-modal-input-box" id="experience-modal-company" type="text" placeholder="UI/UX Designer at Apli" />
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="experience-modal-experience-job-type">Job Type</label>
                                    <select value={editedExperience.Type} onChange={(event) => { setEditedExperience({ ...editedExperience, 'Type': event.target.value }) }} className="edit-modal-input-box" id="experience-modal-experience-job-type" type="text" placeholder="Alexa Dawson" >
                                        <option value="full-time">Full Time</option>
                                        <option value="part-time">Part Time</option>
                                        <option value="internship">Internship</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="experience-modal-industry">Industry</label>
                                    <input value={editedExperience.industry} onChange={(event) => { setEditedExperience({ ...editedExperience, 'industry': event.target.value }) }} className="edit-modal-input-box mb-0" id="experience-modal-industry" type="text" placeholder="Computer Science"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="experience-modal-start">Start Date</label>
                                    <input value={editedExperience.from} onChange={handleFromOnChange} max={date} className="edit-modal-input-box" id="experience-modal-start" type="month" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="experience-modal-end">End Date</label>
                                    <input disabled={editedExperience.is_active} value={props.experience.to} onChange={handleToOnChange} min={editedExperience.from} max={date} className="edit-modal-input-box mb-3" id="experience-modal-end" type="month" />
                                    <Form.Check checked={editedExperience.is_active} onChange={handleActiveOnChange} bsPrefix="profile-add-modal-check"
                                        type="switch"
                                        id="custom-switch"
                                        label="I Currently Work Here"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="experience-modal-responsibilities">Responsibilities</label>
                                    <textarea value={editedExperience.information} onChange={(event) => { setEditedExperience({ ...editedExperience, 'information': [event.target.value] }) }} className="edit-modal-input-box" id="experience-modal-responsibilities" rows="4" cols="10" placeholder="Hey, i'm a self taught UI/Ux Designer. I'm a creative, productive and hard working person. My skills are Mobile/website designs, logo designs. Tools I use are adobe Xd, Adobe Illustartor, Adobe." />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center p-4">
                            <button onClick={updateExperience} className="apli-btn modal-save-button">Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </div>
    )
}

export default EditExperienceModal