import { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./ApplicationModal.css";
import "../../App.css";
import Select_Resume from "../Media/Select_Resume.png";
import Upload_Resume from "../Media/Upload_Resume.png";
import Resume_file from "../Media/Resume_file.png";
import success_img from "../Media/success_img.png";
import Cookies from 'js-cookie'
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';
import { allJobsContext, percentResumeCompletedContext } from "../Opportunities"
import { loadingContext } from "../../App"

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
	baseUrl = 'http://localhost:8000'

function ApplicationModal(props) {
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "#ffffff",
		boxShadow: 24,
		borderRadius: "10px",
		p: 4,
	};
	const [modalState, setModalState] = useState(1);
	const [resumeUrl, setResumeUrl] = useState("");
	const loadingHandler = useContext(loadingContext)
	const allJobsHandler = useContext(allJobsContext)
	const percentResumeCompletedHandler = useContext(percentResumeCompletedContext)
	
	useEffect(() => {
		if(percentResumeCompletedHandler.isProfileCompleted) 
		setModalState(2)
	}, [])
	

	const resetDefault = () => {
		setModalState(1);
		props.setIsModalOpen(false)
	}

	const handleSaveResume = (event) => {
		event.preventDefault()
		loadingHandler.setIsLoading(true)
		let uploadResumeData = new FormData()

		uploadResumeData.append('file', event.target.files[0])
		uploadResumeData.append('extension', 'pdf')
		uploadResumeData.append('remote_path', 'candidate_resumes/')
		axios.post(`${baseUrl}/candidate/upload/file/`,
			uploadResumeData,
			{
				headers: {
					'X-CSRFToken': Cookies.get('csrftoken'),
				},
				withCredentials: true
			}
		)
			.then(r => {
				loadingHandler.setIsLoading(false)
				setModalState(2);
				setResumeUrl(r.data.url)
			})
			.catch(e => {
				loadingHandler.setIsLoading(false)
				alert(e)
			})

	}
	const submitApplication = (event) => {
		event.preventDefault()
		loadingHandler.setIsLoading(true)
		let finalApplicationData = new FormData()
		let customResume = true
		if(percentResumeCompletedHandler.isProfileCompleted)
			customResume = false
		finalApplicationData.append('job', props.jobId)
		finalApplicationData.append('resume', `{"name": "Resume.pdf" , "url": "${resumeUrl}" , "custom_resume" : ${customResume}}`)
		axios.post(`${baseUrl}/candidate/add_new_application`,
			finalApplicationData,
			{
				headers: {
					'X-CSRFToken': Cookies.get('csrftoken'),
				},
				withCredentials: true
			}
		)
			.then(r => {
				loadingHandler.setIsLoading(false);
				setModalState(3)
			})
			.catch(e => {
				loadingHandler.setIsLoading(false)
				alert(e)
			})
	}

	const FinalSubmit = () => {
		props.handleJobPreview()
		allJobsHandler.updateJobs({ type: 'apply', key: props.jobId })
	}

	return (
		<>
			<Modal open={props.isOpen}>
				{
					(props.isOpen && modalState === 1) ?
						<Box sx={style} className="modal-container">

							<button className="bg-white border-0 position-absolute modal-close-button" onClick={resetDefault}>
								<i className="fas fa-times fa-lg"></i>
							</button>

							<h4 className="text-navy-blue text-center fw-600">Select Resume</h4>
							<p className="light-text text-center fw-500 fs-14">Build a Profile or else upload resume to apply for this job</p>
							<div className="d-flex justify-content-evenly mt-5">
								<div role="button" className="d-flex justify-content-center flex-column align-items-center mb-5 p-3">
									<img className="select-resume-img" src={Select_Resume} alt="icon" />
									<p className="mt-4">Build Resume</p>
								</div>
								<div className="d-flex justify-content-center flex-column align-items-center mb-5">
									<input onChange={handleSaveResume} type="file" id="upload-resume-input" accept="application/pdf" hidden disabled={percentResumeCompletedHandler.percentResumeCompleted < 42} />
									<Tooltip title={percentResumeCompletedHandler.percentResumeCompleted < 42 ? "Please complete your profile" : ""} >
										<label role="button" htmlFor="upload-resume-input" className={"d-flex flex-column justify-content-center align-items-center" + (percentResumeCompletedHandler.percentResumeCompleted < 42 ? ' disable-select-resume' : null)}>
											<div className="d-flex justify-content-center flex-column align-items-center rounded-circle p-3 outer-dashed-border">
												<img className="select-resume-img" src={Upload_Resume} alt="icon" />
											</div>
											<p className="mt-4">Select Resume</p>
										</label>
									</Tooltip>
								</div>
							</div>
							<div className="d-flex justify-content-center align-items-center">
								<button onClick={() => setModalState(2)} className="fw-600 fs-17 border-0 submit-large-purple text-white">Submit</button>
							</div>
						</Box>

						: (props.isOpen && modalState === 2) ?
							<Box sx={style} className="modal-container modal-container-intermediate">

								<button className="bg-white border-0 position-absolute modal-close-button" onClick={resetDefault}>
									<i className="fas fa-times fa-lg"></i>
								</button>

								<h4 className="text-navy-blue text-center fw-600">Select Resume</h4>
								<div className="d-flex justify-content-evenly mt-5 options-resume-select">
									<div role="button" className="p-3 d-flex justify-content-center flex-column align-items-center options-resume-mobile">
										<i className="fas fa-user-circle fa-8x user-icon-resume"></i>
										<p className="mt-3">Apli Profile</p>
									</div>
									<div role="button" className="p-3 d-flex justify-content-center flex-column align-items-center options-resume-mobile">
										<img className="resume-file-img" src={Resume_file} alt="icon" />
										<p className="mt-3">Resume.pdf</p>
									</div>
									<div className="p-3 d-flex justify-content-center flex-column align-items-center options-resume-mobile">
										<input type="file" id="upload-resume-input-second" accept="application/pdf" hidden />
										<label role="button" htmlFor="upload-resume-input-second" className="d-flex flex-column justify-content-center align-items-center">
											<div className="d-flex justify-content-center flex-column align-items-center rounded-circle outer-dashed-border">
												<img src={Upload_Resume} />
											</div>
											<p className="mt-4">Select Resume</p>
										</label>
									</div>
								</div>
								<div className="d-flex justify-content-center align-items-center">
									<button onClick={submitApplication} className="fw-600 fs-17 border-0 submit-large-blue text-white">Submit</button>
								</div>
							</Box>

							: (props.isOpen && modalState === 3) ?
								<Box sx={style} className="modal-container">

									<button className="bg-white border-0 position-absolute modal-close-button" onClick={resetDefault}>
										<i className="fas fa-times fa-lg"></i>
									</button>

									<div className="d-flex flex-column justify-content-center align-items-center">
										<img className="success-img" src={success_img} alt="icon" />
										<h5 className="text-dark mt-5 text-center">Your Job Application Has been submitted</h5>
									</div>
									<div className="d-flex flex-column justify-content-center align-items-center mt-4">
										<button onClick={FinalSubmit} className="fw-600 fs-17 border-0 text-white submit-large-blue mb-3">Got it</button>
										<a className="text-purple fw-600 fs-14 text-decoration-none" href="#">Find More Jobs</a>
									</div>
								</Box>
								: null
				}
			</Modal>
		</>
	);
}

export default ApplicationModal;
