import { React, useState } from 'react';
import './JobsList.css'
import Job from '../Job/Job';
import JobPreview from '../JobPreview/JobPreview';

function JobsList(props) {
    const [allJobsStyles, setAllJobsStyles] = useState({ isPreviewed: false, jobStyles: 'col-sm-12 col-md-10 col-lg-6', jobListStyles: 'row w-100 m-0 justify-content-center', jobPreviewStyles: 'w-50' })
    const [previewedJob, setPreviewedJob] = useState(null)
    let filteredJobs = []
    for (let jobId in props.jobs) {
        let jobDetails = props.jobs[jobId]
        let addThisJob = true
        for (let filterName in props.appliedFilters) {
            let filterDetails = props.appliedFilters[filterName]
            switch (filterName) {
                case 'Job Type':
                    if (filterDetails.length === 0)
                        continue;
                    if (!((jobDetails.job_type === 'internship' && filterDetails.includes('Internship')) ||
                        (jobDetails.job_type === 'job' && filterDetails.includes('Job')))) {
                        addThisJob = addThisJob && false
                    }
                    break;
                case 'Field':
                    if (filterDetails.length === 0)
                        continue;
                    break;
                case 'Location':
                    if (filterDetails.length === 0)
                        continue;
                    let currentJobLocations = jobDetails.place.split(', ')
                    let tempSelectJob = false
                    for (let index = 0; index < filterDetails.length; index++) {
                        if (currentJobLocations.includes(filterDetails[index])) {
                            tempSelectJob = true
                            break;
                        }
                    }
                    addThisJob = addThisJob && tempSelectJob
                    break;
                case 'Company':
                    if (filterDetails.length === 0)
                        continue;
                    if (!filterDetails.includes(jobDetails.company_name))
                        addThisJob = addThisJob && false
                    break;
                case 'easyApply':
                    break;
                default:
                    break;
            }
            if (!addThisJob)
                break;
        }
        if (addThisJob)
            filteredJobs.push(jobDetails)
    }
    if (props.appliedFilters['recentlyPosted']) {
        filteredJobs.sort(function (a, b) {
            return new Date(b.timestamp) - new Date(a.timestamp);
        })
    }

    return (
        <>
            <div className="jobs-main-container">
                <div className={allJobsStyles.jobListStyles}>
                    {
                        filteredJobs.map((jobDetails) => {
                            return (
                                <Job key={jobDetails.job_id} type={props.type} jobId={jobDetails.job_id} jobDetails={jobDetails} allJobsStyles={allJobsStyles} setAllJobsStyles={setAllJobsStyles} previewedJob={previewedJob} setPreviewedJob={setPreviewedJob} />
                            )
                        })
                    }
                </div>
                {
                    allJobsStyles.isPreviewed &&
                    <div className={allJobsStyles.jobPreviewStyles}>
                        <JobPreview type={props.type} jobDetails={props.jobs[previewedJob]} setAllJobsStyles={setAllJobsStyles} setPreviewedJob={setPreviewedJob} />
                    </div>
                }
            </div>
        </>
    );
}

export default JobsList;
