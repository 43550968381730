import { useContext, useEffect, useState } from 'react'
import './TaskDetails.css'
import '../VirtualExperiences.css'
import file_img_blue from '../Media/file-img-blue.png'
import file_img_red from '../Media/file-img-red.png'
import files_upload_img from '../Media/files_upload_img.png'
import check_answers_img from '../Media/check-answers-img.png'
import axios from 'axios'
import { loadingContext, messageContext } from '../../App'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import Footer from '../../footer/Footer'
import MainChat from './chat/MainChat'

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function TaskDetails() {
    let [nextTaskId, setNextTaskId] = useState(null)
    const [taskData, setTaskData] = useState(null)
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const [viewFooter, setViewFooter] = useState(false)
    const params = useParams()
    let navigate = useNavigate()

    useEffect(() => {
        loadingHandler.setIsLoading(true)
        axios.get(`${baseUrl}/candidate/fetch_virtual_exp_task_details`, {
            params: { virtual_experience_id: params.virtualExpId, task_id: params.taskId },
            withCredentials: true
        })
            .then((res) => {
                setTaskData(res.data.task_details)
                setNextTaskId(res.data.next_task_id)
                loadingHandler.setIsLoading(false)
                setViewFooter(true)
            })
            .catch(e => {
                loadingHandler.setIsLoading(false)
                messageHandler.setMessage({
                    showMessage: true,
                    type: "warning",
                    displayText: "Internal Server Error"
                })
            })
    }, [params.taskId])

    return (
        <>
            {taskData &&
                <>
                    <div className="td-main-container">
                        {/* <div className="task-details-bg-container text-light">
                            <div className="task-details-bg-upper-container row">
                                <div className="col-md-1 col-0"></div>
                                <div className="col-md-7 col-10 mt-5">
                                    <h1 className="fw-600 fs-24 lh-60 mt-4 mb-4">Mission {taskData.task_details.task_name}</h1>
                                    <h4 className="fs-18 fw-400 lh-33">{taskData.task_details.motive}</h4>
                                </div>
                            </div>
                            <div className="task-details-bg-lower-container pb-5 row">
                                <div className="col-md-1 col-0 pb-3"></div>
                                <div className="col-md-8 col-7 d-flex align-items-center">
                                    {taskData.hashtags.map((tag, index) => {
                                        return (<div key={index} className="tag-items text-purple">#{Object.values(tag)}</div>)
                                    })}
                                </div>
                            </div>
                        </div> */}
                        <MainChat task={taskData} nextTaskId={nextTaskId} />
                    </div>
                    {viewFooter && <Footer />}
                </>
            }
        </>
    )
}

export default TaskDetails