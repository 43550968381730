import { React, useState, useEffect, useContext } from 'react';
import { allJobsContext } from '../Opportunities'
import { loadingContext } from '../../App';
import './Job.css'
import axios from 'axios'
import Cookies from 'js-cookie'

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function Job(props) {
    const handleLoading = useContext(loadingContext)
    const allJobs = useContext(allJobsContext)
    const [jobStyle, setJobStyle] = useState({ backgroundColor: 'white' })

    useEffect(() => {
        if (props.previewedJob === props.jobId) {
            setJobStyle({ backgroundColor: '#EFF5FF' })
        } else {
            setJobStyle({ backgroundColor: 'white' })
        }
    }, [props.previewedJob, props.jobId]);

    const handleJobPreview = () => {
        props.setPreviewedJob(props.jobId)
        if (window.innerWidth < 992)
            props.setAllJobsStyles({ isPreviewed: true, jobStyles: 'col-md-12', jobListStyles: 'd-none', jobPreviewStyles: 'w-100' })
        else
            props.setAllJobsStyles({ isPreviewed: true, jobStyles: 'col-sm-12 ', jobListStyles: 'row m-0 justify-content-center w-50', jobPreviewStyles: 'w-50' })
    }

    //to add a job  to saved job and remove from saved job
    const handleSaveJob = (e, jobId) => {
        let type = e.target.getAttribute('data-type')
        handleLoading.setIsLoading(true)
        let jobIdData = new FormData()
        jobIdData.append('id', jobId)
        axios.post(`${baseUrl}/candidate/saved_applications`,
            jobIdData,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
                withCredentials: true
            }
        )
            .then(r => {
                allJobs.updateJobs({ type: type, key: jobId })
                handleLoading.setIsLoading(false)
            })
            .catch(e => {
                handleLoading.setIsLoading(false)
            })
    }

    return (
        <>
            
            <div className={props.allJobsStyles.jobStyles}>
                <div className="row m-2 ms-3 me-3 p-3 mt-3 mb-3 border rounded-3" data-job-id={props.jobId} style={jobStyle} >
                    <div className="col-2 p-2 pt-3 text-center disabled" onClick={handleJobPreview} role="button">
                        {
                            props.jobDetails.company_logo ? <img src={props.jobDetails.company_logo} className="w-100" alt="company logo" /> : <i className="far fa-building text-navy-blue" style={{ fontSize: "42px" }}></i>
                        }
                    </div>
                    <div className="col-7 ps-3 pt-1 pb-1"  onClick={handleJobPreview} role="button">
                        <p className="text-navy-blue fs-12 fw-400 mb-1">{props.jobDetails.company_name}</p>
                        <h6 className="text-navy-blue fs-14 fw-600 mb-1">{props.jobDetails.post}</h6>
                        <p className="light-text fs-12 fw-400 mb-3">{props.jobDetails.place}</p>
                        <div className="d-flex w-50">
                            <small className="fs-12 text-purple rounded p-2 shadow-sm">
                                {
                                    props.jobDetails.job_type === "internship" ? "Internship" : "Full Time"
                                }
                            </small>
                        </div>
                    </div>
                    <div className={`col-3 text-center text-navy-blue ${props.type === "appliedJobs" ? 'pt-0' : 'pt-3'}`}>
                        {
                            props.type === "openJobs" ? <i className="far fa-bookmark mb-3" data-type="save" onClick={(e) => handleSaveJob(e, props.jobId)} role="button"></i> :
                                (props.type === "savedJobs" ? <i className="fas fa-bookmark mb-3" data-type="unsave" onClick={(e) => handleSaveJob(e, props.jobId)} role="button"></i> :
                                    <p className="fs-14" style={{ color: "#14C944" }}><i className="fas fa-check-circle"> </i> Applied</p>)
                        }
                        {
                            props.type === "appliedJobs" ? <p><small className="text-navy-blue fs-12 fw-400">{props.jobDetails.deadline}</small></p> :
                                <p><small className="light-text fs-12 fw-400">{props.jobDetails.deadline}</small></p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Job;
