import { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import "./Modals.css"
import axios from 'axios'
import Cookies from 'js-cookie'
import { loadingContext, messageContext } from '../../App';

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function SkillModal(props) {
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const [newSkill, setNewSkill] = useState({
        'skill_group': '',
        'skill': '',
        'proficiency': 'basic'
    })
    let [allSkill, setAllSkill] = useState([])
    const addSkill = () => {
        if (newSkill.skill.length > 250 || newSkill.skill.length < 1) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter valid input"
            })
            return;
        }
        let obj = { [newSkill.skill]: newSkill.proficiency }
        setAllSkill([...allSkill, obj])
        setNewSkill({ ...newSkill, 'skill': '', 'proficiency': 'basic' })
    }
    const addNewSkillGroup = () => {
        loadingHandler.setIsLoading(true)
        if (newSkill.skill_group.length > 250 || newSkill.skill_group.length < 1 || allSkill.length < 1) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter valid input"
            })
            return;
        }
        props.setOpen(false)
        let skillData = new FormData()
        let obj = { [newSkill.skill_group]: allSkill }
        let d = JSON.stringify({ 'edit_type': 'add_skill', 'all_skills': [...props.skills, obj] })
        skillData.append('data', d)
        axios.post(`${ baseUrl }/candidate/update_and_load_resume/`,
            skillData,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                withCredentials: true
            }
        ).then(r => {
            props.setSkills([...props.skills, obj])
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "success",
                displayText: "Your Skill Group is Added"
            })
            setNewSkill({
                'skill_group': '',
                'skill': '',
                'proficiency': ''
            })
            setAllSkill([])
        }).catch(e => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Internal Server Error"
            })
        })
    }

    return (
        <div>
            <Modal show={props.open.skillModalOpen} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                <Modal.Body bsPrefix="modal-body profile-modal-content">
                    <button onClick={() => { props.setOpen({ ...props.open, 'skillModalOpen': false }) }} className="edit-modal-close"><i className="fas fa-times"></i></button>
                    <h4 className="text-center fs-18 fw-500 mt-5 mb-5 text-navy-blue">Add Skills</h4>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="skill-modal-skill-group">Skill Group</label>
                                <input value={newSkill.skill_group} onChange={(event) => { setNewSkill({ ...newSkill, 'skill_group': event.target.value }) }} className="edit-modal-input-box" id="skill-modal-skill-group" type="text" placeholder="Alexa Dawson" />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap px-2">
                        {allSkill.length > 0 &&
                            allSkill.map(skill => {
                                return <div key={Object.keys(skill)} className="single-skill">{Object.keys(skill)}</div>
                            })
                        }
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="skill-modal-skill">Skill</label>
                                <input value={newSkill.skill} onChange={(event) => { setNewSkill({ ...newSkill, 'skill': event.target.value }) }} className="edit-modal-input-box" id="skill-modal-skill" type="text" placeholder="Alexa Dawson" />
                            </div>
                        </div>

                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="skill-modal-proficiency">Proficiency</label>
                                <select value={newSkill.proficiency} onChange={(event) => { setNewSkill({ ...newSkill, 'proficiency': event.target.value }) }} className="edit-modal-input-box" id="skill-modal-proficiency">
                                    <option value="basic">Basic</option>
                                    <option value="intermediate">Intermediate</option>
                                    <option value="advanced">Advanced</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="add-skill-button-modal" role="button" onClick={addSkill}>
                        <i className="fas fa-plus"></i> &nbsp;Add Skill
                    </div>
                    <div className="d-flex justify-content-center align-items-center p-4 mt-5">
                        <button onClick={addNewSkillGroup} className="apli-btn modal-save-button">Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SkillModal