import { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import "../Modals/Modals.css"
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import Cookies from 'js-cookie'
import { loadingContext, messageContext } from '../../App';

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function EditExtraCurricular(props) {

    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const today = new Date();
    const date = String(today.getFullYear()) + "-" + String(today.getMonth() + 1).padStart(2, '0')

    const [editedExtraCurricular, setEditedExtraCurricular] = useState(props.extraCurricular)
    const handleStartOnChange = (event) => {
        setEditedExtraCurricular({ ...editedExtraCurricular, 'start': event.target.value })
    }
    const handleEndOnChange = (event) => {
        editedExtraCurricular.is_active ? setEditedExtraCurricular({ ...editedExtraCurricular, 'end': '' }) : setEditedExtraCurricular({ ...editedExtraCurricular, 'end': event.target.value })
    }
    const handleActiveOnChange = (event) => {
        if (event.target.checked) {
            setEditedExtraCurricular({ ...editedExtraCurricular, 'is_active': true, 'to': '' })
        } else {
            setEditedExtraCurricular({ ...editedExtraCurricular, 'is_active': false })
        }
    }

    const updateExtraCurricular = () => {
        loadingHandler.setIsLoading(true)
        if (editedExtraCurricular.role.length > 250 || editedExtraCurricular.organisation.length > 250 || editedExtraCurricular.role.length < 1 || editedExtraCurricular.organisation.length < 1 || editedExtraCurricular.start.length < 1 || (editedExtraCurricular.to !== '' && editedExtraCurricular.from > editedExtraCurricular.to)) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter valid input"
            })
            return;
        }
        if (editedExtraCurricular.information[0].length < 80) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter at least 80 characters of Responsibilities."
            })
            return;
        }
        props.setOpen(false)
        let extraCurricularData = props.extraCurricular
        for (let key in editedExtraCurricular) {
            extraCurricularData[key] = editedExtraCurricular[key]
        }
        let ExtraCurricularData = new FormData()
        let d = JSON.stringify({ 'edit_type': 'add_extra_curricular', 'extra_curricular_data': props.extraCurriculars })
        ExtraCurricularData.append('data', d)
        axios.post(`${ baseUrl }/candidate/update_and_load_resume/`,
            ExtraCurricularData,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                withCredentials: true
            }
        ).then(r => {
            props.setExtraCurriculars(props.extraCurriculars)
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "success",
                displayText: "Your Extra Curricular Activity is Edited"
            })
        }).catch(e => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Internal Server Error"
            })
        })
    }


    return (
        <div>
            <Modal show={props.open} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                <Modal.Body bsPrefix="modal-body profile-modal-content">
                    <button onClick={() => props.setOpen(false)} className="edit-modal-close"><i className="fas fa-times"></i></button>
                    <h4 className="text-center fs-18 fw-500 mt-5 mb-5 text-navy-blue">Edit Extra Curricular Activity</h4>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="ecurricular-modal-role">Role</label>
                                <input value={editedExtraCurricular.role} onChange={(event) => { setEditedExtraCurricular({ ...editedExtraCurricular, 'role': event.target.value }) }} className="edit-modal-input-box" id="ecurricular-modal-role" type="text" placeholder="Alexa Dawson" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="ecurricular-modal-organisation">Organisation/Commitee</label>
                                <input value={editedExtraCurricular.organisation} onChange={(event) => { setEditedExtraCurricular({ ...editedExtraCurricular, 'organisation': event.target.value }) }} className="edit-modal-input-box" id="ecurricular-modal-organisation" type="text" placeholder="UI/UX Designer at Apli" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="ecurricular-modal-issue-date">Start Date</label>
                                <input value={editedExtraCurricular.start} onChange={handleStartOnChange} className="edit-modal-input-box" id="ecurricular-modal-issue-date" max={date} type="month" placeholder="Bengaluru" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="ecurricular-modal-expire-date">End Date</label>
                                <input disabled={editedExtraCurricular.is_active} value={editedExtraCurricular.end} onChange={handleEndOnChange} min={editedExtraCurricular.start} max={date} className="edit-modal-input-box" id="ecurricular-modal-expire-date" type="month" placeholder="01/01/1997" />
                                <Form.Check checked={editedExtraCurricular.is_active} onChange={handleActiveOnChange} bsPrefix="profile-add-modal-check"
                                    type="switch"
                                    id="custom-switch"
                                    label="This Activity Does not Expire"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="ecurricular-modal-responsibilities">Responsibilities</label>
                                <textarea value={editedExtraCurricular.info} onChange={(event) => { setEditedExtraCurricular({ ...editedExtraCurricular, 'info': [event.target.value] }) }} className="edit-modal-input-box" id="ecurricular-modal-responsibilities" rows="4" cols="10" placeholder="Hey, i'm a self taught UI/Ux Designer. I'm a creative, productive and hard working person. My skills are Mobile/website designs, logo designs. Tools I use are adobe Xd, Adobe Illustartor, Adobe." />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center p-4">
                        <button onClick={updateExtraCurricular} className="apli-btn modal-save-button">Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditExtraCurricular