import React, { useState, useEffect } from 'react'
import apli_logo from './apli-logo-white.png'
import './Footer.css'

function Footer() {

	const [showFooter, setShowFooter] = useState(true)
	useEffect(() => {
		let currentPath = window.location.pathname.split('/')
		if (currentPath.length == 5 && currentPath[2] === 'enrolled-virtual-experiences' && currentPath[4].length > 0)
			setShowFooter(false)
		else
			setShowFooter(true)
	}, [window.location.pathname])

	return (
		<>
		{
			showFooter && 
			<div className="footer-custom">
				<div className="footer-logo-img-container">
					<img className="footer-logo-img" src={apli_logo} alt="Apli" />
				</div>
				<p className="footer-para text-center">Copyrights 2021 . All rights Reserved</p>
			</div>
			}
		</>
	)
}

export default Footer