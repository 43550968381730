import { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import '../Signup.css'
import Signup_icon from '../Media/Signup_icon.png'
import { Box, Stepper, Step, StepLabel, Autocomplete, TextField } from '@mui/material';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Facebook_icon from '../Media/Facebook_icon.png'
import Google_icon from '../Media/Google_icon.png'
import Linkedin_icon from '../Media/Linkedin_icon.png'
import Logo_white from '../Media/logo-white.png'
import Modal from "@mui/material/Modal";
import Student_icon from "../Media/Student_icon.png"
import College_icon from "../Media/College_icon.png"
import Company_icon from "../Media/Company_icon.png"
import { loadingContext, messageContext } from '../../App'
import { authentication } from '../../firebase';

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

let listOfColleges = []

function CandidateSignup() {

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "#ffffff",
        boxShadow: 24,
        borderRadius: "10px",
        p: 5,
    };

    const steps = [
        'Account',
        'Basic Info'
    ];
    const [csrfToken, setCsrfToken] = useState('')
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const [countryCode, setCountryCode] = useState('+91')
    const [initialInput, setInitialInput] = useState(true)
    const [otpHandler, setOtpHandler] = useState({
        otp: '',
        otpSent: false,
        otpVerified: false
    })
    const [passwordShown, setPasswordShown] = useState(false)
    const [optionModalOpen, setOptionModalOpen] = useState(true)
    const [selectedUserType, setSelectedUserType] = useState({
        'student': true,
        'college': false,
        'company': false
    })
    const [isInvitedCandidate, setIsInvitedCandidate] = useState({
        email: null,
        college: null
    })
    const [userData, setUserData] = useState({
        'userType': '',
        'email': '',
        'password': '',
        'fname': '',
        'lname': '',
        'addCollege': '',
        'ph_no': '',
        'tnc': true
    })

    useEffect(() => {
        fetch(`${baseUrl}/csrf/`, {
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setCsrfToken(data.csrfToken)
                selectUserType()
            })
            .catch(e => {
                messageHandler.setMessage({
                    showMessage: true,
                    type: "warning",
                    displayText: "Internal Server Error!"
                })

            })

    }, [])


    const registerUser = () => {
        if (!userData.tnc)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Check the Terms and Conditions!"
            })
        else if (userData.fname.length < 1 || userData.addCollege.length < 1)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Please fill all the required fields!"
            })
        else if (!otpHandler.otpVerified)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Please verify your Phone number!"
            })
        else {
            loadingHandler.setIsLoading(true)
            let college_not_found = false
            if (!listOfColleges.hasOwnProperty(userData.addCollege))
                college_not_found = true
            let candidateDetails = userData
            candidateDetails['ph_no'] = '+91-' + userData.ph_no
            candidateDetails['college_not_found'] = college_not_found
            let candidateData = new FormData()
            candidateData.append('candidateData', JSON.stringify(candidateDetails))
            axios.post(`${baseUrl}/accounts/candidate_signup/`,
                candidateData,
                {
                    headers: {
                        'X-CSRFToken': csrfToken
                    },
                    withCredentials: true
                }
            )
                .then(r => {
                    let responseData = r.data
                    if (responseData.message !== null) {
                        messageHandler.setMessage({
                            showMessage: true,
                            type: responseData.message_type,
                            displayText: responseData.message
                        })
                    }
                    if (responseData.redirect_to !== null)
                        window.location.replace(`${baseUrl}${responseData.redirect_to}`)
                    loadingHandler.setIsLoading(false)
                })
                .catch(e => {
                    let responseData = e.response.data
                    if (responseData.message !== null) {
                        messageHandler.setMessage({
                            showMessage: true,
                            type: responseData.message_type,
                            displayText: responseData.message
                        })
                    }
                    if (responseData.redirect_to !== null)
                        window.location.replace(`${baseUrl}${responseData.redirect_to}`)
                    loadingHandler.setIsLoading(false)
                })
        }
    }

    const setCollege = (e) => {
        let collegeIndex = e.target.getAttribute('data-option-index')
        if (collegeIndex)
            setUserData({ ...userData, addCollege: 'college_' + collegeIndex })
        else
            setUserData({ ...userData, addCollege: e.target.value })
    }

    const selectUserType = () => {
        loadingHandler.setIsLoading(true)
        if (selectedUserType['student']) {
            axios.get(`${baseUrl}/accounts/candidate_signup/`, {
                withCredentials: true
            })
                .then(response => {
                    loadingHandler.setIsLoading(false)
                    let responseData = response.data
                    if (responseData.hasOwnProperty('colleges')) {
                        listOfColleges = responseData['colleges']
                    }
                    if (responseData.hasOwnProperty('college'))
                        setIsInvitedCandidate({ ...isInvitedCandidate, 'college': responseData['college'] })
                    if (responseData.hasOwnProperty('email'))
                        setIsInvitedCandidate({ ...isInvitedCandidate, 'email': responseData['email'] })
                })
                .catch(e => {
                    loadingHandler.setIsLoading(false)
                })
            setUserData({ ...userData, 'userType': 'student' })
        } else if (selectedUserType['college']) {
            setUserData({ ...userData, 'userType': 'college' })
            window.location.href = (`${baseUrl}/accounts/reachus`)
        } else {
            window.location.href = (`${baseUrl}/accounts/company/signup/`)
            setUserData({ ...userData, 'userType': 'company' })
        }
        setOptionModalOpen(false)
    }

    const validateAccount = () => {
        if (userData.email.length < 1 || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(userData.email)) {
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Please Enter Valid Email!"
            })
        }
        else if (userData.password.length < 8 || !((/[a-zA-Z]/).test(userData.password) && (/[0-9]/).test(userData.password) && (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/).test(userData.password)) ){
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Password must be contain 8 characters along with a special character and a number!"
            })
        }
        else {
            setInitialInput(false)
        }
    }


    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.

            }
        }, authentication);
    }

    const sendOtp = () => {
        if (userData.ph_no.length === 10) {
            loadingHandler.setIsLoading(true)
            setOtpHandler({ ...otpHandler, otpSent: true })
            generateRecaptcha()
            let appVerifier = window.recaptchaVerifier
            signInWithPhoneNumber(authentication, countryCode + userData.ph_no, appVerifier)
                .then(confirmationResult => {
                    window.confirmationResult = confirmationResult
                    loadingHandler.setIsLoading(false)
                })
                .catch(error => {
                    loadingHandler.setIsLoading(false)
                })
        } else {
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Please Enter valid Phone Number!"
            })
        }
    }

    const verifyOtp = (e) => {
        let otp = e.target.value
        setOtpHandler({ ...otpHandler, 'otp': otp })
        if (otp.length === 6) {
            loadingHandler.setIsLoading(true)
            let confirmationResult = window.confirmationResult
            confirmationResult.confirm(otp).then((result) => {
                setOtpHandler({ ...otpHandler, otpVerified: true })
                loadingHandler.setIsLoading(false)
            }).catch((error) => {
                loadingHandler.setIsLoading(false)
            });
        }
    }

    return (
        <>
            <div id="recaptcha-container"></div>
            {/* <Modal open={optionModalOpen} className="option-modal">
                <Box sx={style} className="modal-container-forget-password">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <h5 className="text-navy-blue fs-20 fw-600 mt-2 mb-5 text-center">Choose an Option</h5>
                    </div>
                    <div className="d-flex justify-content-around mt-3 mb-5">
                        <div className={(selectedUserType['student']) ? 'user-type-selected' : undefined}>
                            <div className="option-items" role="button" onClick={() => setSelectedUserType({ 'student': true, 'college': false, 'company': false })}>
                                <img className="option-item-img" src={Student_icon} alt="Student" />
                                <p className="fs-14 fw-500 text-center">Student</p>
                            </div>
                        </div>
                        <div className={(selectedUserType['college']) ? 'user-type-selected' : undefined}>
                            <div className="option-items" role="button" onClick={() => setSelectedUserType({ 'student': false, 'college': true, 'company': false })}>
                                <img className="option-item-img" src={College_icon} alt="College" />
                                <p className="fs-14 fw-500 text-center">College Representative</p>
                            </div>
                        </div>
                        <div className={(selectedUserType['company']) ? 'user-type-selected' : undefined}>
                            <div className="option-items" role="button" onClick={() => setSelectedUserType({ 'student': false, 'college': false, 'company': true })}>
                                <img className="option-item-img" src={Company_icon} alt="Company" />
                                <p className="fs-14 fw-500 text-center">Company Representative</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                        <button className="fw-600 fs-17 border-0 text-white submit-large-blue mt-4 mb-2" onClick={selectUserType}>Next</button>
                    </div>
                </Box>
            </Modal> */}

            <div className="text-navy-blue signup-container-parent position-relative">
                {initialInput ?
                    <div className="row signup-container">
                        <div className="col-md-5 signup-container-left d-flex align-items-center justify-content-center">
                            <div className="logo-box">
                                <img className="logo-img" src={Logo_white} alt="Logo" />
                            </div>
                            <div>
                                <img className="signup-icon-img" src={Signup_icon} alt="Signup" />
                            </div>
                        </div>
                        <div className="col-md-7 signup-container-right d-flex justify-content-center">
                            <div className="signup-form-container px-4">
                                <Box sx={{ width: '100%' }}>
                                    <Stepper activeStep={0} alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel className="fs-14 fw-400" style={{ color: "#444444" }}>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>
                                <h3 className="signup-form-heading-custom">Create Account</h3>
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-500 fs-14 mb-2" htmlFor="signup-email">Email ID</label>
                                    <input value={isInvitedCandidate.email ? isInvitedCandidate.email : userData.email} onChange={(e) => setUserData({ ...userData, 'email': e.target.value })} className="edit-modal-input-box mb-0" id="signup-email" type="email" />
                                </div>
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-500 fs-14 mb-2" htmlFor="signup-password">Password</label>
                                    <div className="d-flex position-relative">
                                        <input style={{ width: "100%" }} value={userData.password} onChange={(e) => setUserData({ ...userData, 'password': e.target.value })} className="edit-modal-input-box mb-0" id="signup-password" type={passwordShown ? "text" : "password"} ></input>
                                        <button className="password-show-toggle-button" onClick={() => setPasswordShown(!passwordShown)}>
                                            {passwordShown ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                        </button>
                                    </div>
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                                    <button className="apli-btn w-100 mt-4 submit-button-custom" onClick={() => validateAccount()}>Next</button>
                                    {/* <div className="d-flex or-continue-div">
                                        <p className="fs-12 fw-500 or-continue-text text-center" style={{ color: "#979797" }}>or continue with</p>
                                    </div>
                                    <div className="w-100 mt-1">
                                        <div className="d-flex justify-content-around">
                                            <div role="button" className="social-icon-container">
                                                <img className="social-icon-img" src={Google_icon} alt="Google"></img>
                                            </div>
                                            <div role="button" className="social-icon-container">
                                                <img className="social-icon-img" src={Linkedin_icon} alt="Google"></img>
                                            </div>
                                            <div role="button" className="social-icon-container">
                                                <img className="social-icon-img" src={Facebook_icon} alt="Google"></img>
                                            </div>
                                        </div>
                                    </div> */}
                                    <p className="fs-16 fw-600 mt-5">Already Have Account? <a href={`${baseUrl}/accounts/login/`} style={{ textDecoration: "none" }}>Login</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row signup-container">
                        <div className="col-md-5 signup-container-left d-flex align-items-center justify-content-center signup-container-left-final">
                            <div className="logo-box">
                                <img className="logo-img" src={Logo_white} alt="Logo" />
                            </div>
                            <div>
                                <img className="signup-icon-img signup-container-left-final" src={Signup_icon} alt="Signup" />
                            </div>
                        </div>
                        <span role="button" onClick={() => setInitialInput(true)} className="back-button-container">
                            <i className="fas fa-arrow-left fa-lg"></i>
                        </span>
                        <div className="col-md-7 signup-container-right d-flex justify-content-center">
                            <div className="signup-form-container px-4">
                                <Box sx={{ width: '100%' }}>
                                    <Stepper activeStep={1} alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel className="fs-12 fw-400" style={{ color: "#444444" }}>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>
                                <h3 className="signup-form-heading-custom">Create Account</h3>
                                <div className="row">
                                    <div className="col-6 d-flex flex-column mb-3">
                                        <label className="fw-500 fs-14 mb-2" htmlFor="signup-fname">First Name</label>
                                        <input value={userData.fname} onChange={(e) => setUserData({ ...userData, 'fname': e.target.value })} className="edit-modal-input-box mb-0" id="signup-fname" type="text" />
                                    </div>
                                    <div className="col-6 d-flex flex-column mb-3">
                                        <label className="fw-500 fs-14 mb-2" htmlFor="signup-lname">Last Name</label>
                                        <input value={userData.lname} onChange={(e) => setUserData({ ...userData, 'lname': e.target.value })} className="edit-modal-input-box mb-0" id="signup-lname" type="text" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column mb-3">
                                    <label className="fw-500 fs-16 mb-2" htmlFor="signup-college">College</label>
                                    {
                                        isInvitedCandidate.college ?
                                            <input value={isInvitedCandidate.college} className="edit-modal-input-box mb-0" id="signup-college" type="text" />
                                            :
                                            <Autocomplete
                                                onInputChange={(e) => setCollege(e)}
                                                freeSolo
                                                options={Object.values(listOfColleges).map(option => option)}
                                                renderInput={(params) => <TextField {...params} className='edit-modal-input-box' label='College' />}
                                            />
                                    }
                                </div>

                                <div className="row">
                                    <div className="col-8 d-flex flex-column mb-3">
                                        <label className="fw-500 fs-16 mb-2" htmlFor="signup-mobile">Mobile Number</label>
                                        <input value={userData.ph_no} onChange={!otpHandler.otpVerified ? (e) => setUserData({ ...userData, 'ph_no': e.target.value }) : undefined} className="edit-modal-input-box mb-0" id="signup-mobile" type="number" />
                                    </div>
                                    {
                                        !otpHandler.otpSent ?
                                            <div className="col-4">
                                                <button className="apli-btn get-otp-button" onClick={sendOtp}>Get OTP</button>
                                            </div>
                                            :
                                            <>
                                                <div className="col-md-4 col-8 d-flex flex-column mb-3">
                                                    <label className="fw-500 fs-16 mb-2" htmlFor="otp">Enter OTP &nbsp;
                                                        {
                                                            otpHandler.otpVerified && <i className="fas fa-check-circle text-success"></i>
                                                        }
                                                    </label>
                                                    <input className="edit-modal-input-box mb-0" id="otp" type="number" value={otpHandler.otp} onChange={!otpHandler.otpVerified ? verifyOtp : undefined} />
                                                </div>
                                                {!otpHandler.otpVerified &&
                                                    <div className="col-md-6 col-4 mt-0">
                                                        <button className="apli-btn get-otp-button resend-otp-button" onClick={sendOtp}>Resend OTP</button>
                                                    </div>
                                                }
                                            </>
                                    }

                                </div>

                                <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                    <div className="mt-3">
                                        <input className="terms-checkbox" type="checkbox" id="terms" checked={userData.tnc} onChange={(e) => e.target.checked ? setUserData({ ...userData, tnc: true }) : setUserData({ ...userData, tnc: false })} />
                                        <label className="fs-12 fw-400" htmlFor="terms"> I agree to Apli’s <a href="#" style={{ textDecoration: "none" }}>Privacy Policy</a> & <a href="#" style={{ textDecoration: "none" }}>Terms of Service</a></label>
                                    </div>
                                    <button className="apli-btn w-100 mt-4 submit-button-custom" onClick={registerUser}>Submit</button>
                                    <p className="fs-16 fw-600 mt-5">Already Have Account? <a href="#" style={{ textDecoration: "none" }}>Login</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default CandidateSignup