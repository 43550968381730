import React, { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import "./Modals.css"
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import Cookies from 'js-cookie'
import { loadingContext, messageContext } from '../../App';

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function AddDegree(props) {
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const allEducationData = {
        'degrees': {},
        'XII': props.education[props.education.length - 2],
        'X': props.education[props.education.length - 1],
        'diploma': {}
    }
    const today = new Date();
    const date = String(today.getFullYear()) + "-" + String(today.getMonth() + 1).padStart(2, '0')

    const [newDegreeType, setNewDegreeType] = useState('')
    const [newDegree, setNewDegree] = useState({
        'institute': '',
        'specialization': 'computer_science',
        'board': '',
        'score': '',
        'start': '',
        'end': ''
    })

    for (let i = 0; i < props.education.length - 2; i++) {
        allEducationData.degrees[props.education[i].type] = props.education[i]
    }

    const dataValidation = () => {
        if (newDegree.institute.length > 200 || newDegree.institute.length < 1 || newDegreeType.length > 200 || newDegreeType.length < 1 || newDegree.score < 0 || newDegree.score > 100 || newDegree.start.length < 1 || newDegree.start > newDegree.end) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter valid input"
            })
            return false;
        }
        return true
    }

    const addDegree = () => {
        if (!dataValidation()) {
            return;
        }
        props.setOpen({ ...props.open, 'addDegreeModalOpen': false })
        allEducationData.XII.start = allEducationData.XII.start.split("-", 2).join("-")
        allEducationData.XII.end = allEducationData.XII.end.split("-", 2).join("-")
        allEducationData.X.start = allEducationData.X.start.split("-", 2).join("-")
        allEducationData.X.end = allEducationData.X.end.split("-", 2).join("-")
        for (let degree in allEducationData.degrees) {
            allEducationData.degrees[degree].start = allEducationData.degrees[degree].start.split("-", 2).join("-")
            allEducationData.degrees[degree].end && (allEducationData.degrees[degree].end = allEducationData.degrees[degree].end.split("-", 2).join("-"))
        }
        allEducationData.degrees[newDegreeType] = newDegree
        loadingHandler.setIsLoading(true)
        let EducationData = new FormData()
        let d = JSON.stringify({ 'edit_type': 'add_edu', 'all_edu_details': allEducationData })
        EducationData.append('data', d)
        axios.post(`${ baseUrl }/candidate/update_and_load_resume/`,
            EducationData,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                withCredentials: true
            }
        ).then(r => {
            props.setEducation([...props.education, newDegree])
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "success",
                displayText: "Your Education is Updated!"
            })
        }).catch(e => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Internal Server Error"
            })
        })
    }

    return (
        <div>
            <Modal show={props.open.addDegreeModalOpen} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                <Modal.Body bsPrefix="modal-body profile-modal-content">
                    <button onClick={() => { props.setOpen({ ...props.open, 'addDegreeModalOpen': false }) }} className="edit-modal-close"><i className="fas fa-times"></i></button>
                    <h4 className="text-center fs-18 fw-500 mt-4 mb-5 text-navy-blue">Add New Degree</h4>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-type">Degree Type</label>
                                <input value={newDegreeType} onChange={(event) => setNewDegreeType(event.target.value)} className="edit-modal-input-box mb-0" id="education-modal-degree-type" type="text" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-institute">Institute Name</label>
                                <input value={newDegree.institute} onChange={(event) => setNewDegree({ ...newDegree, 'institute': event.target.value })} className="edit-modal-input-box mb-0" id="education-modal-degree-institute" type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-field">Field of Study</label>
                                <select value={newDegree.specialization} onChange={(event) => setNewDegree({ ...newDegree, 'specialization': event.target.value })} className="edit-modal-input-box mb-0" id="education-modal-degree-field">
                                    <option value="computer_science">Computer Science</option>
                                    <option value="information_technology">Information Technology</option>
                                    <option value="mechanical">Mechanical</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-board">Board</label>
                                <input value={newDegree.board} onChange={(event) => setNewDegree({ ...newDegree, 'board': event.target.value })} className="edit-modal-input-box mb-0" id="education-modal-degree-board" type="text" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-score">Score/CGPA</label>
                                <input value={newDegree.score} onChange={(event) => setNewDegree({ ...newDegree, 'score': event.target.value })} className="edit-modal-input-box mb-0" id="education-modal-degree-score" type="number" placeholder="9.2" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-from">From</label>
                                <input value={newDegree.start} onChange={(event) => setNewDegree({ ...newDegree, 'start': event.target.value })} max={date} className="edit-modal-input-box mb-0" id="education-modal-degree-from" type="month" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="education-modal-degree-to">To</label>
                                <input value={newDegree.end} onChange={(event) => setNewDegree({ ...newDegree, 'end': event.target.value })} min={newDegree.start} max={date} className="edit-modal-input-box mb-3" id="education-modal-degree-to" type="month" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center p-4">
                        <button onClick={addDegree} className="apli-btn edit-modal-save-button">Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddDegree