import { useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import "./Modals.css"
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import Cookies from 'js-cookie'
import { loadingContext, messageContext } from '../../App';

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function ExtraCurricularModal(props) {

    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const today = new Date();
    const date = String(today.getFullYear()) + "-" + String(today.getMonth() + 1).padStart(2, '0')

    const [newExtraCurricular, setNewExtraCurricular] = useState({
        'role': '',
        'organisation': '',
        'start': '',
        'end': '',
        'is_active': false,
        'info': [''],
        'certificate': 'None'
    })
    const handleStartOnChange = (event) => {
        setNewExtraCurricular({ ...newExtraCurricular, 'start': event.target.value })
    }
    const handleEndOnChange = (event) => {
        newExtraCurricular.is_active ? setNewExtraCurricular({ ...newExtraCurricular, 'end': '' }) : setNewExtraCurricular({ ...newExtraCurricular, 'end': event.target.value })
    }
    const handleActiveOnChange = (event) => {
        if (event.target.checked) {
            setNewExtraCurricular({ ...newExtraCurricular, 'is_active': true, 'to': '' })
        } else {
            setNewExtraCurricular({ ...newExtraCurricular, 'is_active': false })
        }
    }

    const addExtraCurricular = () => {
        loadingHandler.setIsLoading(true)
        if (newExtraCurricular.role.length > 250 || newExtraCurricular.organisation.length > 250 || newExtraCurricular.info[0].length > 500 || newExtraCurricular.role.length < 1 || newExtraCurricular.organisation.length < 1 || newExtraCurricular.start.length < 1 || (newExtraCurricular.end !== '' && newExtraCurricular.start > newExtraCurricular.end)) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter valid input"
            })
            return;
        }
        if (newExtraCurricular.info[0].length < 80) {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please enter at least 80 characters of Responsibilities."
            })
            return;
        }
        props.setOpen(false)
        let extraCurricularData = new FormData()
        let d = JSON.stringify({ 'edit_type': 'add_extra_curricular', 'extra_curricular_data': [...props.extraCurriculars, newExtraCurricular] })
        extraCurricularData.append('data', d)
        axios.post(`${ baseUrl }/candidate/update_and_load_resume/`,
            extraCurricularData,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                withCredentials: true
            }
        ).then(r => {
            props.setExtraCurriculars([...props.extraCurriculars, newExtraCurricular])
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "success",
                displayText: "Your Extra Curricular Activity is Added"
            })
            setNewExtraCurricular({
                'role': '',
                'organisation': '',
                'start': '',
                'end': '',
                'is_active': false,
                'info': [''],
                'certificate': 'None'
            })
        }).catch(e => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Internal Server Error"
            })
        })
    }

    return (
        <div>
            <Modal show={props.open.eCurricularModalOpen} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                <Modal.Body bsPrefix="modal-body profile-modal-content">
                    <button onClick={() => { props.setOpen({ ...props.open, 'eCurricularModalOpen': false }) }} className="edit-modal-close"><i className="fas fa-times"></i></button>
                    <h4 className="text-center fs-18 fw-500 mt-5 mb-5 text-navy-blue">Add Extra Curricular Activity</h4>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="ecurricular-modal-role">Role</label>
                                <input value={newExtraCurricular.role} onChange={(event) => { setNewExtraCurricular({ ...newExtraCurricular, 'role': event.target.value }) }} className="edit-modal-input-box" id="ecurricular-modal-role" type="text" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="ecurricular-modal-organisation">Organisation/Commitee</label>
                                <input value={newExtraCurricular.organisation} onChange={(event) => { setNewExtraCurricular({ ...newExtraCurricular, 'organisation': event.target.value }) }} className="edit-modal-input-box" id="ecurricular-modal-organisation" type="text" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="ecurricular-modal-issue-date">Start Date</label>
                                <input value={newExtraCurricular.start} onChange={handleStartOnChange} max={date} className="edit-modal-input-box" id="ecurricular-modal-issue-date" type="month" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="ecurricular-modal-expire-date">End Date</label>
                                <input disabled={newExtraCurricular.is_active} value={newExtraCurricular.end} onChange={handleEndOnChange} min={newExtraCurricular.start} max={date} className="edit-modal-input-box" id="ecurricular-modal-expire-date" type="month" />
                                <Form.Check checked={newExtraCurricular.is_active} onChange={handleActiveOnChange} bsPrefix="profile-add-modal-check"
                                    type="switch"
                                    id="custom-switch"
                                    label="This Activity Does not Expire"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 edit-modal-child-container">
                            <div className="d-flex flex-column mb-3">
                                <label className="fw-600 fs-14 py-1 text-navy-blue" htmlFor="ecurricular-modal-responsibilities">Responsibilities</label>
                                <textarea value={newExtraCurricular.info} onChange={(event) => { setNewExtraCurricular({ ...newExtraCurricular, 'info': [event.target.value] }) }} className="edit-modal-input-box" id="ecurricular-modal-responsibilities" rows="4" cols="10" />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center p-4">
                        <button onClick={addExtraCurricular} className="apli-btn modal-save-button">Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ExtraCurricularModal