import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { loadingContext, messageContext, activeLinkContext, userContext } from '../App';
import upload_file_img from "./Media/upload_file_img.png"
import blue_file_img from "../virtualExperiences/Media/file-img-blue.png"
import './Profile.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
import React from 'react';
import Experience from './Experience/Experience';
import Project from './Projects/Project';
import ExtraCurricular from './ExtraCurricular/ExtraCurricular';
import SocialLinks from './SocialLinks/SocialLinks';
import Skills from './Skills/Skills';
import Education from './Education/Education';
import Award from './Award/Award';
import ProjectModal from './Modals/ProjectModal';
import ExperienceModal from './Modals/ExperienceModal';
import ExtraCurricularModal from './Modals/ExtraCurricularModal';
import SkillModal from './Modals/SkillModal';
import AwardModal from './Modals/AwardModal';
import EducationModal from './Modals/EducationModal';
import AboutMeModal from './Modals/AboutMeModal';
import Modal from 'react-bootstrap/Modal'
import Popover from '@mui/material/Popover';
import Footer from '../footer/Footer'
import Cookies from 'js-cookie'
import AddDegree from './Modals/AddDegree';
import CollegeDetailsModal from './Modals/CollegeDetailsModal'

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function Profile() {

    const [modalOpen, setModalOpen] = useState({
        'projectModalOpen': false,
        'experienceModalOpen': false,
        'eCurricularModalOpen': false,
        'skillModalOpen': false,
        'awardModalOpen': false,
        'educationModalOpen': false,
        'addDegreeModalOpen': false,
        'aboutMeModalOpen': false,
        'collegeDetailsModalOpen': false,
    })

    const activeLinkHandler = useContext(activeLinkContext)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [videoModalOpen, setVideoModalOpen] = useState(false)
    const [anchorElVideoEdit, setAnchorElVideoEdit] = useState(null);
    const openVideoEditPop = Boolean(anchorElVideoEdit);
    const videoEditPopid = openVideoEditPop ? 'simple-popover' : undefined;
    const [showApliResume, setShowApliResume] = useState(false)
    const loadingHandler = useContext(loadingContext)
    const messageHandler = useContext(messageContext)
    const [collegeDetails, setCollegeDetails] = useState()
    const [mockInterviewLink, setMockInterviewLink] = useState('')
    //This state maintains basic profile info
    const [basicProfileInfo, setBasicProfileInfo] = useState(null)

    //This state maintains basic profile info, along with this it is used to update the basic profile
    const [editedBasicInfo, setEditedBasicInfo] = useState(null)
    const [experiences, setExperiences] = useState([])
    const [projects, setProjects] = useState([])
    const [extraCurriculars, setExtraCurriculars] = useState([])
    const [education, setEducation] = useState([])
    const [awards, setAwards] = useState([])
    const [skills, setSkills] = useState({})

    // This state maintains whether every items is hown or a single one in details (experience, projects, etc)
    const [showAll, setShowAll] = useState({
        experiences: false,
        projects: false,
        extraCurriculars: false,
        education: false,
        awards: false,
        skills: false
    })

    const basicInfoValidation = () => {
        if (editedBasicInfo.First_name.length < 1 || editedBasicInfo.about_me.length < 1) {
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please Fill All Required Fields."
            })
            return false
        }
        if (editedBasicInfo.Address.postal_code.toString().length !== 6 || editedBasicInfo.Address.country.length < 1 || editedBasicInfo.Address.city.length < 1 || editedBasicInfo.Address.state.length < 1) {
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Invalid Address."
            })
            return false
        }
        if (editedBasicInfo.First_name.length > 100 || editedBasicInfo.about_me.length > 700 || editedBasicInfo.Address.country.length > 200 || editedBasicInfo.Address.city.length > 200 || editedBasicInfo.Address.state.length > 200) {
            messageHandler.setMessage({
                showMessage: true,
                type: "info",
                displayText: "Please Enter Valid Input."
            })
            return false
        }
        return true
    }

    //This useEffect loads the data
    useEffect(() => {
        activeLinkHandler.updateActiveLink({ type: 'profile' })
        loadingHandler.setIsLoading(true)
        axios.get(`${baseUrl}/candidate/update_and_load_resume`, {
            withCredentials: true
        })
            .then(response => {
                if (response.data.hasOwnProperty('college_details')) {
                    setCollegeDetails(response.data.college_details)
                    setModalOpen({ ...modalOpen, collegeDetailsModalOpen: true })
                } else {
                    setBasicProfileInfo(response.data.basic_info)
                    setExperiences(response.data.profile_details.experience)
                    setEducation(response.data.profile_details.education)
                    setProjects(response.data.profile_details.projects)
                    setExtraCurriculars(response.data.profile_details.extra_curricular)
                    setSkills(response.data.profile_details.skills)
                    setAwards(response.data.profile_details.award)
                    if (response.data.basic_info.gender === null || response.data.basic_info.gender.length < 1) {
                        setEditedBasicInfo({ ...response.data.basic_info, 'gender': 'Male' })
                    } else {
                        setEditedBasicInfo({ ...response.data.basic_info })
                    }
                    setShowApliResume(response.data.basic_info.pdfResume ? false : true)
                }
                loadingHandler.setIsLoading(false)

            })
            .catch(error => {
                loadingHandler.setIsLoading(false)
                messageHandler.setMessage({
                    showMessage: false,
                    type: "warning",
                    displayText: "Internal Server Error"
                })
            })

    }, [])


    // this function is used to update the basic profile details
    const updateBasicInfo = () => {
        if (basicInfoValidation()) {
            loadingHandler.setIsLoading(true)
            let infoData = new FormData()
            let d = JSON.stringify({ 'edit_type': 'basic_info', 'all_basic_info': editedBasicInfo })
            infoData.append('data', d)
            axios.post(`${baseUrl}/candidate/update_and_load_resume/`,
                infoData,
                {
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },
                    withCredentials: true
                }
            ).then(r => {
                setEditModalOpen(false)
                setBasicProfileInfo({ ...basicProfileInfo, ...editedBasicInfo })
                loadingHandler.setIsLoading(false)
                messageHandler.setMessage({
                    showMessage: true,
                    type: "success",
                    displayText: "Profile Updated"
                })
            }).catch(e => {
                loadingHandler.setIsLoading(false)
                messageHandler.setMessage({
                    showMessage: true,
                    type: "warning",
                    displayText: "Internal Server Error"
                })
                alert(e)
            })
        }
    }

    const copyToClipboard = () => {
        window.navigator.clipboard.writeText(basicProfileInfo.public_profile_url)
        messageHandler.setMessage({
            showMessage: true,
            type: "success",
            displayText: "Copied to clipboard"
        })
    }

    const updateProfilePicture = (e) => {
        loadingHandler.setIsLoading(true)
        let profileForm = new FormData()
        let imgFile = e.target.files[0]
        profileForm.append('remote_path', 'resumePictures/');
        profileForm.append('file', imgFile);
        profileForm.append('extension', imgFile.type.split('/')[1]);

        axios.post(`${baseUrl}/candidate/upload/file/`,
            profileForm,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken'),
                },
                withCredentials: true
            }
        ).then(r => {
            setBasicProfileInfo({ ...basicProfileInfo, 'profile_picture': r.data.url })
            setEditedBasicInfo({ ...editedBasicInfo, 'profile_picture': r.data.url })
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "success",
                displayText: "Uploaded Profile Picture"
            })
        }).catch(e => {
            loadingHandler.setIsLoading(false)
            messageHandler.setMessage({
                showMessage: true,
                type: "warning",
                displayText: "Internal Server Error"
            })
        })
    }

    const uploadVideoResume = (e) => {
        loadingHandler.setIsLoading(true)
        let videoFile = e.target.files[0]
        let file_name = (new Date()).getTime() + videoFile.name
        let videoForm = new FormData()
        videoForm.append('file', videoFile)
        videoForm.append('file_name', file_name)
        videoForm.append('type', 'upload')
        videoForm.append('extension', videoFile.type.split('/')[1])

        if (videoFile) {
            axios.post(`${baseUrl}/candidate/video_resume`,
                videoForm,
                {
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                    },
                    withCredentials: true
                }
            ).then(r => {
                let responseData = r.data
                setBasicProfileInfo({ ...basicProfileInfo, video_resume: responseData.video_resume })
                setAnchorElVideoEdit(null)
                loadingHandler.setIsLoading(false)
                messageHandler.setMessage({
                    showMessage: true,
                    type: responseData.type,
                    displayText: responseData.message
                })
            }).catch(e => {
                setAnchorElVideoEdit(null)
                loadingHandler.setIsLoading(false)
                messageHandler.setMessage({
                    showMessage: true,
                    type: "warning",
                    displayText: "Internal Server Error"
                })
            })
        }
    }

    return (
        <>
            {
                basicProfileInfo &&
                <div className="profile-box-container">
                    <Modal
                        show={showApliResume}
                        fullscreen={basicProfileInfo.pdfResume ? "lg-down" : "md-down"}
                        size={basicProfileInfo.pdfResume ? "xl" : "md"}
                        onHide={() => setShowApliResume(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Apli Resume
                            </Modal.Title>
                        </Modal.Header>

                        {basicProfileInfo.pdfResume ?
                            <Modal.Body style={{ height: "500px" }}>
                                <iframe
                                    src={`https://docs.google.com/viewer?url=${basicProfileInfo.pdfResume}&embedded=true`}
                                    type="application/pdf"
                                    frameBorder="0"
                                    scrolling="auto"
                                    height="100%"
                                    width="100%"
                                ></iframe>
                            </Modal.Body>
                            :
                            <Modal.Body>
                                <h6 className="mt-3 px-3 text-navy-blue">To generate <b>Apli Resume</b> fill the following sections:-</h6>
                                <ul>
                                    <li>Basic Info (along with your profile picture)</li>
                                    <li>Skills</li>
                                    <li>Education</li>
                                </ul>
                                <center>
                                    <button className="apli-btn mt-4" onClick={() => setShowApliResume(false)}>Okay</button>
                                </center>
                            </Modal.Body>
                        }

                    </Modal>
                    <div className="profile-box-top-container">
                        <div className="profile-box-upper">
                            {basicProfileInfo && basicProfileInfo.video_resume ?
                                <div>
                                    <input id="resumeVideoInput" onChange={(e) => uploadVideoResume(e)} type="file" accept="video/mp4,video/x-m4v,video/*" hidden />
                                    <div className="profile-box-cover-img-container">
                                        <img className="profile-box-cover-img w-100 h-100" src={basicProfileInfo.profile_picture} alt="cover-img" style={{ objectFit: "cover" }} />
                                    </div>
                                    <button onClick={() => setVideoModalOpen(true)} className="profile-box-upper-play-button"><i className="far fa-play-circle fa-3x"></i></button>
                                    <button onClick={(event) => setAnchorElVideoEdit(event.currentTarget)} className="profile-box-upper-edit-button text-dark"><i className="fas fa-pen fa-lg"></i></button>
                                </div>
                                :
                                <div>
                                    <input id="resumeVideoInput" onChange={(e) => uploadVideoResume(e)} type="file" accept="video/mp4,video/x-m4v,video/*" hidden />
                                    <div style={{ backgroundColor: "#f2f2f2" }} className="profile-box-cover-img-container d-flex justify-content-center align-items-center">
                                        <label role="button" htmlFor="resumeVideoInput">
                                            <img className="profile-box-cover-img-label" src={upload_file_img} alt="cover-img" />
                                        </label>
                                    </div>
                                    <button onClick={(event) => setAnchorElVideoEdit(event.currentTarget)} className="profile-box-upper-edit-button"><i style={{ color: "black" }} className="fas fa-pen fa-lg"></i></button>
                                </div>
                            }
                        </div>
                        <div className="profile-box-lower position-relative">
                            <div className="d-flex justify-content-center position-relative profile-box-lower-left">
                                {basicProfileInfo.profile_picture ?
                                    <div className="profile-box-profile-img-container d-flex align-items-center justify-content-center px-2">
                                        <img src={basicProfileInfo.profile_picture} className="profile-box-profile-img" alt="profile-img" />
                                    </div>
                                    :
                                    <div style={{ backgroundColor: "#F2F2F4" }} className="profile-box-profile-img-container d-flex align-items-center justify-content-center px-2">
                                        <input type="file" id="user-profile-photo" accept="image/png, image/gif, image/jpeg" hidden />
                                        <label role="button" htmlFor="user-profile-photo">
                                            <div className="profile-box-empty-profile-photo"><i style={{ color: "#C4C4C4" }} className="fas fa-user-plus fa-lg"></i></div>
                                        </label>
                                    </div>
                                }
                            </div>
                            <div className="profile-box-details">
                                <div className="text-navy-blue profile-box-user-details">
                                    <h3 className="profile-box-user-info-name">{basicProfileInfo.First_name} {basicProfileInfo.Middle_name} {basicProfileInfo.Last_name}</h3>
                                    <p className="profile-box-user-info-job">{basicProfileInfo.heading_title}</p>
                                    <p className="profile-box-user-info-place">{basicProfileInfo.Address.city}</p>
                                </div>

                                <div className="profile-box-lower-right">
                                    <div>
                                        <ProgressBar className="profile-box-progress" now={basicProfileInfo.percent_profile_completed} />
                                        <p className="profile-box-progress-para">{basicProfileInfo.percent_profile_completed}% complete profile</p>
                                    </div>
                                    <div>
                                        {
                                            basicProfileInfo.mock_interview_link &&
                                            <a className="apli-btn text-decoration-none p-1" href={basicProfileInfo.mock_interview_link} target="_blank">Mock Interview</a>
                                        }
                                    </div>
                                </div>
                            </div>
                            <span role="button" onClick={() => setEditModalOpen(true)} className="profile-box-lower-edit-button"><i className="fas fa-pen fa-lg"></i></span>
                        </div>
                    </div>
                </div>
            }


            <div className="profile-all-component-container">
                {
                    basicProfileInfo &&
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 custom-col">
                            <p className="fw-600 text-navy-blue mt-50">About me</p>
                            {
                                basicProfileInfo.about_me ?
                                    <div className="border m-1 light-text p-4 rounded fs-14">
                                        {basicProfileInfo.about_me}
                                    </div>
                                    :
                                    <div className="border rounded-3 text-center">
                                        <button onClick={() => { setModalOpen({ ...modalOpen, 'aboutMeModalOpen': true }) }} className="profile-outlined-add-btn">+ About me</button>
                                    </div>
                            }
                        </div>
                        <div className="col-md-8 col-lg-6 custom-col">
                            <p className="fw-600 mb-2 text-navy-blue mt-50">Profile Link</p>
                            <div className="d-flex profile-resume-container">
                                <div className="fw-600 text-purple w-75 p-1 public-link-container">
                                    {basicProfileInfo.public_profile_url}
                                </div>
                                <i className="fas fa-share-alt fs-18 ms-3 me-3"></i>
                                <span role="button" onClick={copyToClipboard}><i className="far fa-copy fs-18"></i></span>
                            </div>
                            <p className="fw-600 text-navy-blue mt-50">Resume</p>
                            <div className="profile-resume-container-parent">
                                <div onClick={() => setShowApliResume(true)} role="button" className="d-flex profile-resume-container profile-resume-container-second bg-light p-2 fs-14 rounded">
                                    <img src={blue_file_img} alt="file" style={{ height: "25px" }} />
                                    <a target="_blank" className="ms-2 profile-resume-container-link">Apli Resume</a>
                                </div>
                                {basicProfileInfo.uploaded_resume &&
                                    <div className="d-flex profile-resume-container bg-light p-2 fs-14 rounded">
                                        <img src={blue_file_img} alt="file" style={{ height: "25px" }} />
                                        <a href={basicProfileInfo.uploaded_resume} target="_blank" className="ms-2 profile-resume-container-link">Resume.pdf</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 custom-col">
                        <div className="position-relative">
                            {experiences.length > 0 && <span role="button" onClick={() => { setModalOpen({ ...modalOpen, 'experienceModalOpen': true }) }} className="add-details-button"><i className="fas fa-plus-circle fa-lg"></i></span>}
                            <p className="fw-600 text-navy-blue mt-50">Experience</p>
                            {
                                experiences.length > 0 ? <>

                                    {showAll.experiences ?
                                        <>
                                            {
                                                experiences.map((exp, index) => {
                                                    return <Experience key={exp.designation + index + exp.company} experiences={experiences} setExperiences={setExperiences} experience={exp} />
                                                })
                                            }
                                            <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'experiences': false }) }}><i className="fas fa-angle-up fa-lg"></i></div>
                                        </>
                                        :
                                        <>
                                            <Experience experience={experiences[0]} experiences={experiences} setExperiences={setExperiences} />
                                            <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'experiences': true }) }}><i className="fas fa-angle-down fa-lg"></i></div>
                                        </>
                                    }

                                </> :
                                    <div className="border rounded-3 text-center">
                                        <button onClick={() => { setModalOpen({ ...modalOpen, 'experienceModalOpen': true }) }} className="profile-outlined-add-btn">+ Add Experience</button>
                                    </div>
                            }
                        </div>

                        <div className="position-relative">
                            {projects.length > 0 && <span role="button" onClick={() => { setModalOpen({ ...modalOpen, 'projectModalOpen': true }) }} className="add-details-button"><i className="fas fa-plus-circle fa-lg"></i></span>}
                            <p className="fw-600 text-navy-blue mt-50">Projects</p>
                            {
                                projects.length > 0 ?
                                    <>
                                        {showAll.projects ?
                                            <>
                                                {
                                                    projects.map((project, index) => {
                                                        return <Project key={project.Name + index + project.University_Company} project={project} projects={projects} setProjects={setProjects} />
                                                    })
                                                }
                                                <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'projects': false }) }}><i className="fas fa-angle-up fa-lg"></i></div>
                                            </>
                                            :
                                            <>
                                                <Project project={projects[0]} projects={projects} setProjects={setProjects} />
                                                <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'projects': true }) }}><i className="fas fa-angle-down fa-lg"></i></div>
                                            </>
                                        }
                                    </> :
                                    <div className="border rounded-3 text-center">
                                        <button onClick={() => { setModalOpen({ ...modalOpen, 'projectModalOpen': true }) }} className="profile-outlined-add-btn">+ Add Project</button>
                                    </div>
                            }
                        </div>

                        <div className="position-relative">
                            {extraCurriculars.length > 0 && <span role="button" onClick={() => { setModalOpen({ ...modalOpen, 'eCurricularModalOpen': true }) }} className="add-details-button"><i className="fas fa-plus-circle fa-lg"></i></span>}
                            <p className="fw-600 text-navy-blue mt-50">Extra-Curriculars</p>
                            {
                                extraCurriculars.length > 0 ?
                                    <>
                                        {showAll.extraCurriculars ?
                                            <>
                                                {
                                                    extraCurriculars.map((extraCurr, index) => {
                                                        return <ExtraCurricular key={extraCurr.role + index + extraCurr.organisation} extraCurricular={extraCurr} extraCurriculars={extraCurriculars} setExtraCurriculars={setExtraCurriculars} />
                                                    })
                                                }
                                                <div className="d-flex justify-content-center mt-4" role="button" onClick={() => { setShowAll({ ...showAll, 'extraCurriculars': false }) }}><i className="fas fa-angle-up fa-lg"></i></div>
                                            </>
                                            :
                                            <>
                                                <ExtraCurricular extraCurricular={extraCurriculars[0]} extraCurriculars={extraCurriculars} setExtraCurriculars={setExtraCurriculars} />
                                                <div className="d-flex justify-content-center mt-4" role="button" onClick={() => { setShowAll({ ...showAll, 'extraCurriculars': true }) }}><i className="fas fa-angle-down fa-lg"></i></div>
                                            </>
                                        }
                                    </> :
                                    <div className="border rounded-3 text-center">
                                        <button onClick={() => { setModalOpen({ ...modalOpen, 'eCurricularModalOpen': true }) }} className="profile-outlined-add-btn">+ Add Extra Curricular</button>
                                    </div>
                            }
                        </div>
                        {
                            basicProfileInfo && Object.keys(basicProfileInfo.social_links).length > 0 &&
                            <>
                                <p className="fw-600 text-navy-blue mt-50">Social Links</p>
                                <SocialLinks socialLinks={basicProfileInfo.social_links} />
                            </>

                        }
                    </div>
                    <div className="col-md-8 col-lg-6 custom-col">
                        <div className="position-relative">
                            {education.length <= 1 ? <span role="button" onClick={() => { setModalOpen({ ...modalOpen, 'educationModalOpen': true }) }} className="add-details-button"><i className="fas fa-plus-circle fa-lg"></i></span> : null}
                            <p className="fw-600 text-navy-blue mt-50">Education</p>
                            {
                                education.length > 0 ?
                                    <>
                                        {showAll.education ?
                                            <>
                                                {
                                                    education.map((edu, index) => {
                                                        return <Education key={edu.type + index + edu.institute} education={edu} educations={education} setEducations={setEducation} />
                                                    })
                                                }
                                                <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'education': false }) }}><i className="fas fa-angle-up fa-lg"></i></div>
                                            </>
                                            :
                                            <>
                                                <Education education={education[0]} educations={education} setEducations={setEducation} />
                                                <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'education': true }) }}><i className="fas fa-angle-down fa-lg"></i></div>
                                            </>
                                        }
                                    </> :
                                    <div className="border rounded-3 text-center">
                                        <button onClick={() => { setModalOpen({ ...modalOpen, 'educationModalOpen': true }) }} className="profile-outlined-add-btn">+ Add Education</button>
                                    </div>
                            }
                        </div>

                        <div className="position-relative">
                            {awards.length > 0 && <span role="button" onClick={() => { setModalOpen({ ...modalOpen, 'awardModalOpen': true }) }} className="add-details-button"><i className="fas fa-plus-circle fa-lg"></i></span>}
                            <p className="fw-600 text-navy-blue mt-50">Awards</p>
                            {
                                awards.length > 0 ?
                                    <>
                                        {showAll.awards ?
                                            <>
                                                {
                                                    awards.map((award, index) => {
                                                        return <Award key={award.date + index} award={award} awards={awards} setAwards={setAwards} />
                                                    })
                                                }
                                                <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'awards': false }) }}><i className="fas fa-angle-up fa-lg"></i></div>
                                            </>
                                            :
                                            <>
                                                <Award award={awards[0]} awards={awards} setAwards={setAwards} />
                                                <div className="d-flex justify-content-center" role="button" onClick={() => { setShowAll({ ...showAll, 'awards': true }) }}><i className="fas fa-angle-down fa-lg"></i></div>
                                            </>
                                        }
                                    </> :
                                    <div className="border rounded-3 text-center">
                                        <button onClick={() => { setModalOpen({ ...modalOpen, 'awardModalOpen': true }) }} className="profile-outlined-add-btn">+ Add Award</button>
                                    </div>
                            }
                        </div>

                        <div className="position-relative">
                            {skills.length > 0 && <span role="button" onClick={() => { setModalOpen({ ...modalOpen, 'skillModalOpen': true }) }} className="add-details-button"><i className="fas fa-plus-circle fa-lg"></i></span>}
                            <p className="fw-600 text-navy-blue mt-50">Skills</p>
                            {
                                skills.length > 0 ?
                                    <Skills skills={skills} setSkills={setSkills} />
                                    :
                                    <div className="border rounded-3 text-center">
                                        <button onClick={() => { setModalOpen({ ...modalOpen, 'skillModalOpen': true }) }} className="profile-outlined-add-btn">+ Add Skills</button>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                editedBasicInfo &&
                <Modal show={editModalOpen} centered fullscreen="md-down" size="lg" contentClassName="p-3">
                    <Modal.Body bsPrefix="modal-body profile-modal-content">
                        <button onClick={() => setEditModalOpen(false)} className="edit-modal-close"><i className="fas fa-times"></i></button>
                        <h4 className="text-center fs-18 fw-500 mt-4 mb-3">Edit Profile</h4>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <img className="edit-modal-profile-img p-2" src={editedBasicInfo.profile_picture} alt="profile" />
                            <input id="edit-modal-user-profile-photo" type="file" hidden onChange={updateProfilePicture} />
                            <label role="button" className="fw-600 fs-14" htmlFor="edit-modal-user-profile-photo" >
                                Change Image
                            </label>
                        </div>
                        <div className="row text-navy-blue mt-3">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column">
                                    <label className="fw-600 fs-14 py-1" htmlFor="edit-modal-user-f-name"> First Name</label>
                                    <input className="edit-modal-input-box" value={editedBasicInfo.First_name} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'First_name': e.target.value })} id="edit-modal-user-name" type="text" placeholder="Alexa" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column">
                                    <label className="fw-600 fs-14 py-1" htmlFor="edit-modal-user-m-name"> Middle Name</label>
                                    <input className="edit-modal-input-box" id="edit-modal-user-name" value={editedBasicInfo.Middle_name} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'Middle_name': e.target.value })} type="text" placeholder="Alexa Dawson" />
                                </div>
                            </div>
                        </div>

                        <div className="row text-navy-blue">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column">
                                    <label className="fw-600 fs-14 py-1" htmlFor="edit-modal-user-l-name"> Last Name</label>
                                    <input className="edit-modal-input-box" value={editedBasicInfo.Last_name} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'Last_name': e.target.value })} id="edit-modal-user-l-name" type="text" placeholder="Alexa" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column">
                                    <label className="fw-600 fs-14 py-1" htmlFor="edit-modal-gender">Gender</label>
                                    <select className="edit-modal-input-box pe-3" value={editedBasicInfo.gender} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'gender': e.target.value })} >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row text-navy-blue">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column">
                                    <label className="fw-600 fs-14 py-1" htmlFor="edit-modal-user-headline">Headline Title</label>
                                    <input className="edit-modal-input-box" value={editedBasicInfo.heading_title} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'heading_title': e.target.value })} id="edit-modal-user-headline" type="text" placeholder="UI/UX Designer at Apli" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column">
                                    <label className="fw-600 fs-14 py-1" htmlFor="edit-modal-user-about-me">About me</label>
                                    <input className="edit-modal-input-box" id="edit-modal-user-about-me" type="text" value={editedBasicInfo.about_me} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'about_me': e.target.value })} rows="6" cols="10" placeholder="Hey, i'm a self taught UI/Ux Designer. I'm a creative, productive and hard working person. My skills are Mobile/website designs, logo designs. Tools I use are adobe Xd, Adobe Illustartor, Adobe." />
                                </div>
                            </div>
                        </div>

                        <div className="row text-navy-blue">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column">
                                    <label className="fw-600 fs-14 py-1" htmlFor="edit-modal-user-phone">Phone Number</label>
                                    <input className="edit-modal-input-box" value={editedBasicInfo.ph_no} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'ph_no': e.target.value })} id="edit-modal-user-phone" type="text" placeholder="+91 8662343375" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column">
                                    <label className="fw-600 fs-14 py-1" htmlFor="edit-modal-user-email">Date of Birth</label>
                                    <input className="edit-modal-input-box" id="edit-modal-user-email" value={editedBasicInfo.dob} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'dob': e.target.value })} type="date" placeholder="01/01/1997" />
                                </div>
                            </div>
                        </div>

                        <div className="row text-navy-blue">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column">
                                    <label className="fw-600 fs-14 py-1" htmlFor="edit-modal-user-phone">Roll No.</label>
                                    <input className="edit-modal-input-box" value={editedBasicInfo.roll_no} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'roll_no': e.target.value })} id="edit-modal-user-phone" type="text" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column">
                                    <label className="fw-600 fs-14 py-1" htmlFor="edit-modal-user-email">E-mail</label>
                                    <input disabled className="edit-modal-input-box" id="edit-modal-user-email" value={editedBasicInfo.email} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'email': e.target.value })} type="text" />
                                </div>
                            </div>
                        </div>

                        <h5 className="edit-modal-social-link-label">Address</h5>
                        <div className="row">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <input className="edit-modal-input-box" type="number" value={editedBasicInfo.Address.postal_code} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'Address': { ...editedBasicInfo.Address, 'postal_code': e.target.value } })} id="edit-modal-user-postal" placeholder="Postal code" required />
                                </div>
                                <div className="d-flex flex-column">
                                    <input className="edit-modal-input-box" type="text" value={editedBasicInfo.Address.state} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'Address': { ...editedBasicInfo.Address, 'state': e.target.value } })} id="edit-modal-user-state" placeholder="State" required />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <input className="edit-modal-input-box" type="text" value={editedBasicInfo.Address.country} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'Address': { ...editedBasicInfo.Address, 'country': e.target.value } })} id="edit-modal-user-country" placeholder="Country" required />
                                </div>
                                <div className="d-flex flex-column">
                                    <input className="edit-modal-input-box" type="text" value={editedBasicInfo.Address.city} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'Address': { ...editedBasicInfo.Address, 'city': e.target.value } })} id="edit-modal-user-city" placeholder="City" required />
                                </div>
                            </div>
                        </div>

                        <h5 className="edit-modal-social-link-label">Social Links</h5>
                        <div className="row">
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <input className="edit-modal-input-box" type="url" value={editedBasicInfo.social_links.linkedin} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'social_links': { ...editedBasicInfo.social_links, 'linkedin': e.target.value } })} id="edit-modal-user-linkedin" placeholder="LinkedIn" />
                                </div>
                                <div className="d-flex flex-column mb-3">
                                    <input className="edit-modal-input-box" type="url" value={editedBasicInfo.social_links.github} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'social_links': { ...editedBasicInfo.social_links, 'github': e.target.value } })} id="edit-modal-user-github" placeholder="Github" />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 edit-modal-child-container">
                                <div className="d-flex flex-column mb-3">
                                    <input className="edit-modal-input-box" type="url" value={editedBasicInfo.social_links.behance_dribble} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'social_links': { ...editedBasicInfo.social_links, 'behance_dribble': e.target.value } })} id="edit-modal-user-behance" placeholder="Behance/Dribble" />
                                </div>
                                <div className="d-flex flex-column mb-3">
                                    <input className="edit-modal-input-box" type="url" value={editedBasicInfo.social_links.portfolio} onChange={(e) => setEditedBasicInfo({ ...editedBasicInfo, 'social_links': { ...editedBasicInfo.social_links, 'portfolio': e.target.value } })} id="edit-modal-user-portfolio" placeholder="Personal Portfolio" />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center p-4">
                            <button onClick={updateBasicInfo} className="apli-btn edit-modal-save-button">Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {basicProfileInfo && basicProfileInfo.video_resume &&
                <Modal show={videoModalOpen} centered fullscreen="md-down" size="lg">
                    <Modal.Body>
                        <button onClick={() => setVideoModalOpen(false)} className="edit-modal-close"><i className="fas fa-times"></i></button>
                        <div className="d-flex justify-content-center video-resume-player-container px-0">
                            <video autoPlay title="Instruction-Video" className="video-resume-player" src={basicProfileInfo.video_resume}></video>
                        </div>
                    </Modal.Body>
                </Modal>
            }

            <Popover
                id={videoEditPopid}
                open={openVideoEditPop}
                anchorEl={anchorElVideoEdit}
                onClose={() => setAnchorElVideoEdit(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div className="d-flex flex-column display-content-center align-items-center">
                    <button onClick={() => document.getElementById('resumeVideoInput').click()} className="video-resume-edit-button">Change Video</button>
                </div>
            </Popover>

            <CollegeDetailsModal collegeDetails={collegeDetails} open={modalOpen} setOpen={setModalOpen} />
            <ProjectModal open={modalOpen} setOpen={setModalOpen} projects={projects} setProjects={setProjects} />
            <ExperienceModal open={modalOpen} setOpen={setModalOpen} experiences={experiences} setExperiences={setExperiences} />
            <ExtraCurricularModal open={modalOpen} setOpen={setModalOpen} extraCurriculars={extraCurriculars} setExtraCurriculars={setExtraCurriculars} />
            <SkillModal open={modalOpen} setOpen={setModalOpen} skills={skills} setSkills={setSkills} />
            <EducationModal open={modalOpen} setOpen={setModalOpen} education={education} />
            <AwardModal open={modalOpen} setOpen={setModalOpen} awards={awards} setAwards={setAwards} />
            <AboutMeModal open={modalOpen} setOpen={setModalOpen} editedBasicInfo={editedBasicInfo} setEditedBasicInfo={setEditedBasicInfo} updateBasicInfo={updateBasicInfo} />
            <AddDegree open={modalOpen} setOpen={setModalOpen} education={education} setEducation={setEducation} />
            <Footer />
        </>
    );
}

export default Profile;
