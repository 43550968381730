import { useState } from 'react'
import EditSkill from './EditSkill'

function SkillGroup({ skillGroup, skills, index, setSkills, isPublicProfile }) {
    const [editSkill, setEditSkill] = useState(false)

    return (
        <div>
            <h1 className="fs-16 fw-500 text-navy-blue p-3 pb-0">{Object.keys(skillGroup)[0]}</h1>
            {
                !isPublicProfile &&
                <span role="button" onClick={() => setEditSkill(true)}>
                    <i className="fas fa-pen pen-edit-icon"></i>
                </span>
            }

            <div className="d-flex flex-wrap justify-content-center py-4 px-3 pt-0 rounded-3">
                {skillGroup[Object.keys(skillGroup)[0]].map(skill => {
                    return <div key={Object.keys(skill)[0]} className="single-skill">{Object.keys(skill)[0]}</div>
                })
                }
            </div>
            <EditSkill open={editSkill} setOpen={setEditSkill} skillGroup={skillGroup} heading={Object.keys(skillGroup)[0]} skills={skills} index={index} setSkills={setSkills} />
        </div>
    )
}

export default SkillGroup