import React, { useContext, useEffect, useState } from 'react'
import VirtualExperience from '../VirtualExperience/VirtualExperience'
import axios from 'axios'
import { loadingContext, messageContext } from '../../App';
import { Link } from "react-router-dom";

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
	baseUrl = 'http://localhost:8000'


function NonEnrolledVirtualExperiences(props) {
	const [nonRegisteredVirtualExps, setNonRegisteredVirtualExps] = useState({})
	const loadingHandler = useContext(loadingContext)
	const messageHandler = useContext(messageContext)

	useEffect(() => {
		// loadingHandler.setIsLoading(true)
		axios.get(`${baseUrl}/candidate/fetch_filtered_virtual_experiences`,
			{
				params: { industry_type: null, role: null },
				withCredentials: true
			})
			.then((response) => {
				setNonRegisteredVirtualExps(response.data.unregistered_virtual_experience)
				// loadingHandler.setIsLoading(false)
			})
			.catch((e) => {
				// loadingHandler.setIsLoading(false)
				messageHandler.setMessage({
					showMessage: false,
					type: "warning",
					displayText: "Internal Server Error"
				})
			})
	}, []);

	useEffect(() => {
		loadingHandler.setIsLoading(true)
		axios.get(`${baseUrl}/candidate/fetch_filtered_virtual_experiences`,
			{
				params: { industry_type: props.industryFilter, role: props.roleFilter },
				withCredentials: true
			})
			.then((response) => {
				setNonRegisteredVirtualExps(response.data.unregistered_virtual_experience)
				loadingHandler.setIsLoading(false)
			})
			.catch((e) => {
				loadingHandler.setIsLoading(false)
				messageHandler.setMessage({
					showMessage: false,
					type: "warning",
					displayText: "Internal Server Error"
				})
			})
	}, [props.roleFilter, props.industryFilter])


	const handlePagination = (type) => {
		if (type === "next") {
			loadingHandler.setIsLoading(true)
			axios.get(`${baseUrl}/candidate/fetch_filtered_virtual_experiences`,
				{
					params: { industry_type: props.industryFilter, role: props.roleFilter },
					withCredentials: true
				})
				.then((response) => {
					setNonRegisteredVirtualExps(response.data.unregistered_virtual_experience)
					loadingHandler.setIsLoading(false)
				})
				.catch((e) => {
					loadingHandler.setIsLoading(false)
					messageHandler.setMessage({
						showMessage: false,
						type: "warning",
						displayText: "Internal Server Error"
					})
				})
		}
	}


	return (
		<>
			{
				Object.keys(nonRegisteredVirtualExps).length > 0 &&
				<>
					<div className="d-flex justify-content-between mt-4">
						<h6 className="text-navy-blue fw-600 w-75">All Virtual Work Experience</h6>
						<div>
							<button onClick={() => handlePagination("prev")} className="rounded-circle bg-white text-navy-blue border-0" style={{ width: "40px", height: "40px" }}><i className="fas fa-arrow-left"></i></button>
							<button onClick={() => handlePagination("next")} className="bg-navy-blue ms-2 rounded-circle text-white border-0" style={{ width: "40px", height: "40px" }}><i className="fas fa-arrow-right"></i></button>
						</div>
					</div>
					<div className="row non-enrolled-virtual-experiences">
						{
							Object.keys(nonRegisteredVirtualExps).map(virtualExpId => {
								return (
									<div className="col-12 col-sm-8 col-lg-6 col-xl-4 mt-4" key={virtualExpId} >
                                        <Link style={{textDecoration: "none"}} to={`/candidate/virtual-experiences/${ virtualExpId }`}>
                                            <VirtualExperience id={virtualExpId} virtualExpDetails={nonRegisteredVirtualExps[virtualExpId]} />
                                        </Link>
									</div>
								)
							})
						}
					</div>
				</>
			}
		</>
	)
}

export default NonEnrolledVirtualExperiences