import { useState, useContext } from 'react';
import './JobPreview.css'
import { Modal } from 'react-bootstrap'
import ApplicationModal from '../Modal/ApplicationModal';
import { allJobsContext, percentResumeCompletedContext } from '../Opportunities'
import { loadingContext } from '../../App';
import Tooltip from '@mui/material/Tooltip';
import ShareModal from '../Modal/ShareModal'
import axios from 'axios'
import Cookies from 'js-cookie'
import incomplete_profile from '../../virtualExperiences/Media/profile_incomplete.png'

let baseUrl = window.location.origin
if (window.location.origin === 'http://localhost:3000')
    baseUrl = 'http://localhost:8000'

function JobPreview(props) {
    const handleLoading = useContext(loadingContext)
    const allJobs = useContext(allJobsContext)
    const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false)
    const [showVEModal, setShowVEModal] = useState(false)
    const percentResumeCompletedHandler = useContext(percentResumeCompletedContext)
    const handleJobPreview = () => {
        props.setAllJobsStyles({ isPreviewed: false, jobStyles: 'col-sm-12 col-md-10 col-lg-6', jobListStyles: 'row m-0 w-100 justify-content-center' })
        props.setPreviewedJob(null)
    }

    //to add a job  to saved job and remove from saved job
    const handleSaveJob = (e, jobId) => {
        let type = e.target.getAttribute('data-type')
        handleLoading.setIsLoading(true)
        let jobIdData = new FormData()
        jobIdData.append('id', jobId)
        axios.post(`${baseUrl}/candidate/saved_applications`,
            jobIdData,
            {
                headers: {
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
                withCredentials: true
            }
        )
            .then(r => {
                handleJobPreview()
                allJobs.updateJobs({ type: type, key: jobId })
                handleLoading.setIsLoading(false)
            })
            .catch(e => {
                handleLoading.setIsLoading(false)
            })
    }

    return (
        <>
            <Modal
                show={showVEModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                contentClassName="p-2"
            >

                <Modal.Body className="p-2">
                    <div className="position-absolute end-0 me-3" role="button" onClick={() => setShowVEModal(false)} ><i className="fas fa-times"></i></div>
                    <div className="text-center p-3">
                        <img style={{ "transform": "scale(0.7" }} src={incomplete_profile} alt="profile-incomplete" />
                        <h6>Just one step away!!</h6>
                        <p className="fs-14">Complete the {props.jobDetails.post} Virtual Experience to apply to this job.</p>
                        <button className="apli-btn mt-4" onClick={() => window.location.href = `/candidate/virtual-experiences/${props.jobDetails.linked_virtual_experience}`}>View Virtual Experience &nbsp;<i class="fas fa-long-arrow-alt-right"></i></button>
                    </div>

                </Modal.Body>
            </Modal>

            <div className="border rounded-3 p-3 mt-3 position-relative">
                <button className="icon-round-btn close-preview-btn" onClick={handleJobPreview}>
                    <i className="fas fa-times"></i>
                </button>
                <div className="d-flex flex-column align-items-center mt-3 mb-4">
                    <div className="job-preview-company-logo">
                        {
                            props.jobDetails.company_logo ? <img src={props.jobDetails.company_logo} className="w-100" alt="company-logo" /> : <i className="far fa-building text-navy-blue" style={{ fontSize: "42px" }}></i>
                        }
                    </div>
                    <h5 className="mt-3 text-navy-blue fw-600">{props.jobDetails.post}</h5>
                    <p className="text-navy-blue">{props.jobDetails.company_name}</p>
                    <div className="d-flex justify-content-evenly w-50 mt-3 mb-3">
                        <small className="text-purple">{props.jobDetails.place}</small>
                        <small className="text-purple">Full-Time</small>
                    </div>
                    <div className="d-flex justify-content-evenly w-75 mt-4">
                        <button onClick={() => setIsShareModalOpen(true)} className="icon-round-btn"><i className="fas fa-share-alt"></i></button>
                        <button className="icon-round-btn" data-type="save" onClick={(e) => handleSaveJob(e, props.jobDetails.job_id)}><i className="far fa-bookmark" data-type="save"></i></button>
                        {
                            props.type !== 'appliedJobs' ?
                                <Tooltip title={percentResumeCompletedHandler.percentResumeCompleted < 42 ? "Please complete your profile" : ""} >
                                    <button onClick={() => props.jobDetails.hasOwnProperty('linked_virtual_experience') ? setShowVEModal(true) : (percentResumeCompletedHandler.percentResumeCompleted >= 42 ? setIsApplicationModalOpen(true) : undefined)} className={`apli-btn btn-xx-large ${percentResumeCompletedHandler.percentResumeCompleted < 42 ? 'bg-light text-dark' : ''} `}>Apply</button>
                                </Tooltip>
                                :
                                <button className="apli-btn btn-xx-large bg-navy-blue"><i className="fas fa-check-circle"> </i> Applied</button>
                        }
                    </div>
                </div>
                <div className="p-3">
                    <div>
                        <p className="text-navy-blue fs-14 fw-600">Job Description</p>
                        <p className="light-text fs-14 fw-400 mt-3" dangerouslySetInnerHTML={{ __html: props.jobDetails.job_description }} />
                    </div>
                    {
                        props.jobDetails.key_responsibility &&
                        <div className="mt-4">
                            <p className="text-navy-blue fs-14 fw-600">Key Responsiblities</p>
                            <p className="light-text fs-14 fw-400 mt-3" dangerouslySetInnerHTML={{ __html: props.jobDetails.key_responsibility }} />
                        </div>
                    }
                    {
                        props.jobDetails.tskill.length > 0 &&
                        <div>
                            <p className="text-navy-blue fs-14 fw-600">Technical Skills</p>
                            <p className="light-text fs-14 fw-400 mt-3">
                                {
                                    props.jobDetails.tskill.map(skill => {
                                        return (<span key={skill}>{skill}, </span>)
                                    })
                                }
                            </p>
                        </div>
                    }
                    {
                        props.jobDetails.sskill.length > 0 &&
                        <div>
                            <p className="text-navy-blue fs-14 fw-600">Soft Skills</p>
                            {props.jobDetails.sskill.map(skill => {
                                return (<span>{skill}, </span>)
                            })}
                        </div>
                    }
                    <div>
                        <p className="text-navy-blue fs-14 fw-600">CTC</p>
                        <p className="light-text fs-14 fw-400 mt-3">{props.jobDetails.salary}</p>
                    </div>
                    <div>
                        <p className="text-navy-blue fs-14 fw-600">Deadline</p>
                        <p className="light-text fs-14 fw-400 mt-3">{props.jobDetails.deadline}</p>
                    </div>

                </div>
            </div>
            <ApplicationModal jobId={props.jobDetails.job_id} isOpen={isApplicationModalOpen} setIsModalOpen={setIsApplicationModalOpen} handleJobPreview={() => handleJobPreview()} />
            <ShareModal open={isShareModalOpen} setOpen={setIsShareModalOpen} />
        </>
    );
}

export default JobPreview;
